import PageHeader from "../../../Components/Dashboard/PageHeader";
import Panel from "../../../Components/Dashboard/Panel";
import useTable from "../../../Components/Dashboard/Table/useTable";
import { BsChevronDown, BsThreeDots } from "react-icons/bs";
import PaidStatus from "../../../Components/Dashboard/PaidStatus";
import {
  Await,
  Link,
  useLoaderData,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import usePagination from "../../../Components/Dashboard/Table/usePagination";
import MoneyFormat from "../../../Components/Utils/MoneyFormat";
import { DatePicker, Dropdown, Space, Typography } from "antd";
import { BiSearch } from "react-icons/bi";
import { Suspense } from "react";
import InvoiceEmpty from "../../../Assets/images/InvoiceEmpty.png";

const CompanyTransaction = () => {
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
  const items = useLoaderData();
  const { Next, Prev, Button, Pagination, setPage, PageSize, Description } =
    usePagination();

  const [, setSearchParams] = useSearchParams({ dateFrom: "", dateTo: "" });
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const handleDateChange = (dateFrom, dateTo) => {
    const s = Object.fromEntries(search);
    setSearchParams({ ...s, dateFrom, dateTo });
  };

  const sortBy = (key, value) => {
    const s = Object.fromEntries(search);
    setSearchParams({ ...s, sort_by: key, sort_type: value });
  };

  const sortByFunStatus = (value) => {
    const s = Object.fromEntries(search);
    setSearchParams({ ...s, fund_status: value });
  };
  const searchAction = (e) => {
    const s = Object.fromEntries(search);
    setSearchParams({ ...s, search: e.target.value });
  };

  const menus = [
    {
      key: 1,
      label: (
        <button
          className="flex items-center space-x-2 py-2"
          onClick={() => {
            sortByFunStatus(0);
          }}
        >
          <span>Fund Status</span>
          <PaidStatus value={0} />
        </button>
      ),
    },
    {
      key: 2,
      label: (
        <button
          className="flex items-center space-x-2 py-2"
          onClick={() => {
            sortByFunStatus(1);
          }}
        >
          <span>Fund Status</span>
          <PaidStatus value={1} />
        </button>
      ),
    },
    {
      key: 3,
      label: (
        <button
          className="flex items-center space-x-2 py-2"
          onClick={() => {
            sortByFunStatus(2);
          }}
        >
          <span>Fund Status</span>
          <PaidStatus value={2} />
        </button>
      ),
    },
    {
      key: 4,
      label: (
        <button
          className="flex items-center space-x-2 py-2"
          onClick={() => {
            sortByFunStatus(3);
          }}
        >
          <span>Fund Status</span>
          <PaidStatus value={3} />
        </button>
      ),
    },
  ];

  const filterMenu = [
    {
      key: 1,
      label: (
        <button
          className="py-2"
          onClick={() => {
            sortBy("created_at", "DESC");
          }}
        >
          Date Created
        </button>
      ),
    },
    {
      key: 2,
      label: (
        <button
          className="py-2"
          onClick={() => {
            sortBy("due_date", "DESC");
          }}
        >
          Due Date
        </button>
      ),
    },
  ];

  return (
    <div className="w-full">
      <PageHeader>Discount Requests</PageHeader>
      <div className="mt-5 mb-5 flex flex-col items-center justify-between space-y-2 md:flex-row md:space-y-0">
        <div className="w-full items-center justify-start lg:flex">
          <div className="flex items-center justify-between">
            <Dropdown
              menu={{
                items: menus,
                selectable: true,
                defaultSelectedKeys: ["3"],
              }}
              className="w-full"
            >
              <div className="rounded-[8px] border border-gray-300/50 bg-white py-2 px-4">
                <Typography.Link>
                  <Space>
                    <div className="whitespace-nowrap text-xs text-gray-500">
                      Paid Status
                    </div>
                    <BsChevronDown className="text-[#323A46]" />
                  </Space>
                </Typography.Link>
              </div>
            </Dropdown>
            <Dropdown
              menu={{
                items: filterMenu,
                selectable: true,
                defaultSelectedKeys: ["3"],
              }}
              className="w-full"
            >
              <div className="rounded-[8px] border border-gray-300/50 bg-white py-2 px-4">
                <Typography.Link>
                  <Space>
                    <div className="text-xs text-gray-500">Filter</div>
                    <BsChevronDown className="text-[#323A46]" />
                  </Space>
                </Typography.Link>
              </div>
            </Dropdown>
          </div>
          <DatePicker.RangePicker
            className="w-full border-gray-300/50 bg-white py-2 px-4 md:max-w-[291.83px]"
            onCalendarChange={(dates, dateString) => {
              const [dateFrom, dateTo] = dateString;
              handleDateChange(dateFrom, dateTo);
            }}
          />
        </div>
        <div className="flex h-[40px] w-full items-center justify-start rounded-[8px] bg-[#e7eaee] md:max-w-[291.83px]">
          <BiSearch className="ml-[15px] mr-[6.5px] h-[15px] w-[15px] flex-none border-none" />
          <input
            type="text"
            placeholder="search"
            className="h-full w-full flex-1 appearance-none border-none bg-transparent text-[14px] font-[500] leading-[21px] placeholder:text-[#64748b] focus:border-transparent focus:outline-none focus:ring-transparent"
            onChange={searchAction}
          />
        </div>
      </div>
      <Panel>
        <Table>
          <RowHeader>
            <ColumnHeader>SME Requested</ColumnHeader>
            <ColumnHeader>Amount</ColumnHeader>
            <ColumnHeader>Email</ColumnHeader>
            <ColumnHeader>Timestamp</ColumnHeader>
            <ColumnHeader>Phone Number</ColumnHeader>
            <ColumnHeader>Action</ColumnHeader>
          </RowHeader>
          <Body data={items}>
            {(data) =>
              data?.data?.data?.map((v, i) => (
                <Row key={i}>
                  <Column>{v?.organization?.name}</Column>
                  <Column>
                    <div className="text-right">₦{MoneyFormat(v.amount)}</div>
                  </Column>
                  <Column>
                    <div className="text-right">{v.email}</div>
                  </Column>
                  <Column>{new Date(v.created_at).toDateString()}</Column>
                  <Column>{new Date(v.phone_number).toDateString()}</Column>
                  <Column>
                    <Dropdown
                      trigger={["click"]}
                      menu={{
                        items: [
                          {
                            key: "1",
                            label: (
                              <Link
                                className="text-sm text-blue-500"
                                to={`/dashboard/transactions/${v.request_token}`}
                              >
                                View Request
                              </Link>
                            ),
                          },
                        ],
                      }}
                      placement="bottomRight"
                      arrow={{ pointAtCenter: true }}
                    >
                      <div className="flex items-center justify-center">
                        <BsThreeDots className="h-4 w-4" />
                      </div>
                    </Dropdown>
                  </Column>
                </Row>
              ))
            }
          </Body>
        </Table>
        <TransactionEmptyState items={items} />
      </Panel>
      <div className="mt-2 flex items-center justify-between">
        <Description data={items} />
        <PageSize numbers={[5, 10, 20, 50]} />
        <Pagination>
          <Suspense fallback={<p>Error Loading</p>}>
            <Await resolve={items.data} errorElement={<span>Loading</span>}>
              {(data) => {
                return (
                  <>
                    <Prev
                      onClick={() => {
                        setPage(1);
                      }}
                    />
                    {Array(data?.data?.last_page)
                      .fill(5)
                      .map((v, i) => {
                        return (
                          <Button
                            key={i}
                            isActive={data?.data?.current_page === i + 1}
                            onClick={() => {
                              setPage(i + 1);
                            }}
                          >
                            {i + 1}
                          </Button>
                        );
                      })}
                    <Next
                      onClick={() => {
                        setPage(data?.data?.last_page);
                      }}
                    />
                  </>
                );
              }}
            </Await>
          </Suspense>
        </Pagination>
      </div>
    </div>
  );
};

const TransactionEmptyState = ({ items }) => (
  <Suspense fallback={<div />}>
    <Await resolve={items?.data}>
      {(data) => {
        if (data?.data?.data.length <= 0) {
          return (
            <div className="flex min-h-[400px] flex-col items-center justify-center rounded-[16px] bg-white text-center md:min-h-[600px]">
              <img src={InvoiceEmpty} alt="Transactions" />
              <p className="mt-5 text-[#64748B]">
                No transaction created <br /> at this time
              </p>
            </div>
          );
        }
      }}
    </Await>
  </Suspense>
);

const OverallStatus = ({ value = 0 }) => {
  value = parseInt(value);
  const status = {
    1: {
      bgColor: "bg-[#EBECFE]",
      label: "Approved",
      color: "text-[#610BEF]",
    },
    2: {
      bgColor: "bg-red-200",
      label: "Declined",
      color: "text-red-500",
    },
    0: {
      bgColor: "bg-[#FFFBEB]",
      label: "Pending",
      color: "text-[#F59E0B]",
    },
  };

  return (
    <span
      className={`flex w-fit items-center space-x-2 rounded-full text-sm ${status[value].bgColor} px-2 py-1 ${status[value].color}`}
    >
      <span>{status[value].label}</span>
    </span>
  );
};

export default CompanyTransaction;
