import Logo from "../../Assets/logo/Logo.png";
import Home from "../../Assets/dashboard/Home.svg";
import Transaction from "../../Assets/dashboard/Transaction.svg";
import Invoice from "../../Assets/dashboard/Invoice.svg";
import Setting from "../../Assets/dashboard/Setting.svg";
import Help from "../../Assets/dashboard/Help.svg";
import Cooperative from "../../Assets/dashboard/Cooperative.svg";
import CooperativeDashboard from "../../Assets/dashboard/CooperativeDashboard.svg";
import CooperativeSettings from "../../Assets/dashboard/CooperativeSettings.svg";
import Loan from "../../Assets/dashboard/Loan.svg";
import TalkToAnExpert from "../../Assets/dashboard/TalktoAnExpert.svg";
import Company from "../../Assets/dashboard/Company.svg";
import Customer from "../../Assets/dashboard/Customer.svg";
import NavLink from "./NavLink";

import { FiX } from "react-icons/fi";
import NavDropdown from "./NavDropdown";

const NavBar = ({ value, click }) => {
  return (
    <aside
      className={`absolute left-0 top-0 z-50 h-full w-[250px] bg-white lg:relative ${
        value ? "" : "hidden lg:block"
      }`}
      style={{ borderRight: "1.5px solid #E7EAEE" }}
    >
      <div className="">
        <button
          className="absolute right-0 top-0 bg-red-50 p-1 lg:hidden"
          onClick={click}
        >
          <FiX className="h-6 w-6" />
        </button>
        <div className="h-full w-[250px]">
          <img
            src={`https://api.fedacash.com/public/Logo.e8beab2fea6fcd94b6a4new.png`}
            alt="Fedacash-logo"
            className="mx-auto mb-[55.55px] mt-[31px] h-[48px] w-[145.82px] object-contain"
          />
          <div className="flex h-full w-full flex-col items-start justify-start space-y-[20px] pl-[14.96px] pr-[20.42px]">
            <NavLink to="/dashboard" onClick={click} icon={Home}>
              Dashboard
            </NavLink>
            <hr className="w-full border-t border-gray-500/50 lg:hidden" />
            <NavDropdown name="Invoice" icon={Invoice}>
              <NavLink
                onClick={click}
                to="/dashboard/transactions"
                icon={Transaction}
              >
                Discount Request
              </NavLink>
              <NavLink onClick={click} to="/dashboard/payments" icon={Invoice}>
                Discount Payment
              </NavLink>
              <NavLink onClick={click} to="/dashboard/invoices" icon={Invoice}>
                Generated Invoices
              </NavLink>
            </NavDropdown>
            <NavDropdown name="Cooperative" icon={Cooperative}>
              {/* <NavLink
                 onClick={click}
                 to="/dashboard/cooperative"
                 icon={CooperativeDashboard}
             >
               Dashboard
             </NavLink> */}
              <NavLink
                onClick={click}
                to="/dashboard/cooperative/saveandpack"
                icon={CooperativeDashboard}
              >
                Save and Pack
              </NavLink>
              <NavLink
                onClick={click}
                to="/dashboard/cooperative/create/payment"
                icon={CooperativeDashboard}
              >
                Create Payment
              </NavLink>
              <NavLink
                onClick={click}
                to="/dashboard/cooperative/saveandgo"
                icon={CooperativeDashboard}
              >
                Save and Go
              </NavLink>
            </NavDropdown>
            <NavDropdown name="Loan" icon={Loan}>
              <NavLink onClick={click} to="/dashboard/loan" icon={Loan}>
                Loans
              </NavLink>
              <NavLink
                onClick={click}
                to="/dashboard/loan/transactions"
                icon={Loan}
              >
                Transactions
              </NavLink>
            </NavDropdown>

            <NavLink
              onClick={click}
              to="/dashboard/customers"
              icon={TalkToAnExpert}
            >
              Talk to an Expert
            </NavLink>
            <NavLink onClick={click} to="/dashboard/company" icon={Company}>
              Company
            </NavLink>
            <NavLink onClick={click} to="/dashboard/customers" icon={Customer}>
              Customers
            </NavLink>
            <NavLink
              onClick={click}
              to="/dashboard/thirdparty/invoice"
              icon={Cooperative}
            >
              Third Party Invoices
            </NavLink>
            <NavDropdown name="Settings" icon={Setting}>
              <NavLink
                onClick={click}
                to="/dashboard/settings/user-management"
                icon={Transaction}
              >
                User Management
              </NavLink>
              <NavLink
                onClick={click}
                to="/dashboard/settings/roles"
                icon={Invoice}
              >
                Role Manager
              </NavLink>
            </NavDropdown>
            <NavLink onClick={click} to="/dashboard/faq" icon={Help}>
              Help & Support
            </NavLink>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default NavBar;
