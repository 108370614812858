import PageHeader from "../../../Components/Dashboard/PageHeader";
import Panel from "../../../Components/Dashboard/Panel";
import { Link, Outlet, useLocation } from "react-router-dom";

const Customers = () => {
  return (
    <div className="flex w-full flex-col justify-start">
      <PageHeader>Customers</PageHeader>
      <div id="portal" className="flex w-full items-center justify-end" />
      <div className="mt-[32px] mb-[16px] flex w-full items-center justify-start space-x-[18.06px] overflow-x-auto rounded-[16px] bg-white px-[18.06px]">
        <Tab to="/dashboard/customers/pending-approval?kyc_status=2">
          Pending Approval
        </Tab>
        <Tab to="/dashboard/customers">All SMEs</Tab>
        <Tab to="/dashboard/customers/compliant?kyc_status=1">
          Compliant SMEs
        </Tab>
        <Tab to="/dashboard/customers/non-compliant?kyc_status=3">
          Non-Compliant SMEs
        </Tab>
      </div>
      <div className="flex max-w-full">
        <Panel>
          <Outlet />
        </Panel>
      </div>
    </div>
  );
};

const Tab = ({ to, children }) => {
  const location = useLocation();
  // const active = matchPath(to, location.pathname) ? true : false;
  const href = new URL(to, "http://api.docs.com");

  return (
    <Link
      to={to}
      className="border-box whitespace-nowrap border-b-[2px] border-[#590DA4] px-[48.5px] py-[20px] text-center text-[16px] font-[700] leading-[24px] focus:outline-none data-[active=false]:border-transparent data-[active=false]:text-[#64748B]"
      data-active={href.pathname === location.pathname}
    >
      {children}
    </Link>
  );
};

export default Customers;
