import { useEffect, useState } from 'react'
import createActivityDetector from 'activity-detector'

const useIdle = (options) => {
  const [isIdle, setIdle] = useState(false)

  useEffect(() => {
    const activityDetector = createActivityDetector(options)
    activityDetector.on('idle', () => {
      setIdle(true)
    })
    activityDetector.on('active', () => (setIdle(false)))
    return () => activityDetector.stop()
  }, [options])

  return isIdle
}

export default useIdle
