import { FiX } from 'react-icons/fi'
import { useState } from 'react'

const Modal = () => {
  const [value, setValue] = useState(false)

  const click = () => {
    setValue(v => !v)
  }
  const Footer = ({ children }) => {
    return (
      <div className='px-6 py-4 w-full'>
        <div className='flex items-center space-x-3 justify-end'>
          {children}
        </div>
      </div>
    )
  }

  const Body = ({ children }) => {
    return (
      <div className='p-6 w-full'>
        {children}
      </div>
    )
  }

  const Wrapper = ({ children, screen, closable, zIndex }) => {
    return (
      <div
        className={`fixed text-black bg-purple-700/50 overflow-y-auto ${zIndex ?? 'z-50'} h-full w-full top-0 left-0 flex items-start justify-center transition-all ease-in-out duration-300 ${closable ? (value ? '' : 'hidden') : 'flex'}`}
      >
        <div
          className={`relative overflow-y-auto bg-white rounded-[24px] mt-20 mx-10 shadow-md divide-y flex flex-col items-center items-start w-full ${screen ?? 'max-w-sm'}`}
        >
          {children}
        </div>
      </div>
    )
  }

  const Header = ({ children }) => {
    return (
      <div className='relative p-6 w-full'>
        <button onClick={click} className='absolute rounded-full text-gray-500 right-6'>
          <FiX className='w-6 h-6 top-6 right-6' />
        </button>
        {children}
      </div>
    )
  }

  const Button = ({ children, className, ...props }) => {
    return (
      <button className={`px-6 py-3 text-white text-sm rounded ${className}`} {...props} onClick={click}>
        {children}
      </button>
    )
  }

  return {
    Wrapper,
    Header,
    Body,
    Footer,
    Button,
    setValue,
    value,
    click
  }
}
export default Modal
