import ResponseObject from "../../DataObjects/ResponseObject";
import http from "../../Store/baseHttp";

export async function SaveandGoLoader({ request }) {
  const url = new URL(request.url);

  try {
    let transactionlist = await http.get(
      "/admin/saveandgo/transactions" + url.search
    );
    let balanceAmount = await http.get("/admin/payment/cooperative/total");
    let subscriptionTable = await http.get("/admin/cooperative/saveandgo/subscriptions");
    transactionlist = ResponseObject.from(transactionlist);
    balanceAmount = ResponseObject.from(balanceAmount);
    subscriptionTable = ResponseObject.from(subscriptionTable);
    return { transactionlist, balanceAmount, subscriptionTable };
  } catch (e) {
    return ResponseObject.from(e?.response);
  }
}
export async function SaveandGoSubscriptionLoader({ request, params }) {
  try {
    let subscription = await http.get(
      "/admin/cooperative/single/saveandgo/subscription/" + params.id
    );
    subscription = ResponseObject.from(subscription);
    return { subscription };
  } catch (e) {
    return ResponseObject.from(e?.response);
  }
}