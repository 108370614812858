//this function is left as lgay part of the code for soome values that didnt formt with the intl funciton
function formatNaira(amount) {
  // Check if the input is a valid number
  if (isNaN(amount)) {
    return "Invalid Amount";
  }
  // Convert the amount to a string
  const amountStr = amount.toString();

  // Split the amount into integer and decimal parts
  const parts = amountStr.split(".");

  // Format the integer part with commas
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Reconstruct the formatted amount with the decimal part (if exists)
  const formattedAmount =
    parts.length === 2 ? integerPart + "." + parts[1] : integerPart;

  // Add the Naira symbol (₦) and return the formatted amount
  return "₦" + formattedAmount;
}
function formatAsNaira(number) {
  if (number === null || number === undefined) {
    return "N/A";
  }

  const num = Number(number);

  if (isNaN(num)) {
    return "N/A";
  }

  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });

  return formatter.format(num);
}
function formatCurrency(amount, currencyCode, locale = "en-NG") {
  // Ensure amount is a number
  const numericAmount = Number(amount);
  // Check if the conversion to number was successful
  if (isNaN(numericAmount)) {
    return 0;
  }
  // Create a currency formatter
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Format and return the amount
  return formatter.format(numericAmount);
}
// Export the function as a named export
export { formatAsNaira, formatNaira, formatCurrency };
