const PaidStatus = ({ value = 0 }) => {
  const type = {
    2: {
      color: "bg-blue-100 text-blue-600",
      value: "advanced",
    },
    1: {
      color: "bg-green-100 text-green-600",
      value: "paid",
    },
    0: {
      color: "bg-yellow-100 text-yellow-600",
      value: "pending",
    },
    3: {
      color: "bg-red-100 text-red-600",
      value: "declined",
    },
  };

  if (value >= 4) return "";
  return (
    <span
      className={`whitespace-nowrap rounded-full px-3 py-1 text-xs capitalize ${type[value].color}`}
    >
      {type[value].value}
    </span>
  );
};

export default PaidStatus;
