const DashboardItem = ({
  icon,
  amount = 0,
  comment,
  iconColor,
  options = {},
  interval = 200,
}) => {
  return (
    <div>
      <div className="flex h-[150px] flex-col items-center justify-start place-self-center rounded-[16px] bg-white py-[17.6px] text-center shadow md:w-[229.94px]">
        <span
          className={`mb-[8px] h-[48px] w-[48px] rounded-full p-[15px] text-white ${iconColor}`}
        >
          {icon({ className: "w-full h-full" })}
        </span>
        <p className="mb-[3px] text-[20px] font-[900] leading-[30px] text-[#0D0F11]">
          {new Intl.NumberFormat("en-NG", options).format(amount)}
        </p>
        <p className="text-[12px] font-[400] leading-[18px] text-[#64748b]">
          {comment}
        </p>
      </div>
    </div>
  );
};

export default DashboardItem;
