import { useRef } from 'react'
import { BsChevronDown } from 'react-icons/bs'
import useClickOutside from '../../Hooks/useClickOutside'

const NavDropdown = ({ children, name, icon, ...props }) => {
  const detailRef = useRef()
  const handleOutsideClick = () => {
    detailRef.current.removeAttribute('open')
  }

  useClickOutside(detailRef, handleOutsideClick)

  return (
    <details ref={detailRef} className='w-full group transition-all open:duration-300'>
      <summary className='list-none w-full h-[40px] capitalize flex items-center justify-between px-[14.96px] font-[500] text-[14px] leading-[21px] hover:text-[#624283] hover:rounded-[8px] hover:bg-[#e5dced] transition duration-300 ease-in-out cursor-pointer'>
        <div className='flex items-center'>
          <img src={icon} className='w-6 h-4' alt='icon' />
          <span className='ml-[16px]'>{name}</span>
        </div>
        <BsChevronDown className='w-4 h-4 group-open:rotate-180 transition-all duration-300' />
      </summary>
      <div className='flex flex-col items-start space-y-1 mt-1 bg-gray-100'>
        {children}
      </div>
    </details>
  )
}

export default NavDropdown
