import { BsEye, BsEyeSlash } from 'react-icons/bs'
import Input from './Input'
import { useState } from 'react'

const Password = ({ ...props }) => {
  const [isReveal, setIsReveal] = useState(false)
  const handleClick = () => {
    setIsReveal(!isReveal)
  }

  const Button = () => (
    <div onClick={handleClick}>
      {isReveal ? (
        <BsEyeSlash className={`w-5 h-5 text-purple-700`} />
      ) : (
        <BsEye className={`w-5 h-5 text-purple-700 `} />
      )}
    </div>
  );

  return (
    <>
      <Input
        type={isReveal ? 'text' : 'password'}
        {...props}
        icon={<Button />}
      />
    </>
  )
}

export default Password
