import {
  Await,
  useFetcher,
  useLoaderData,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import InvoiceEmpty from "../../../Assets/images/InvoiceEmpty.png";
import useTable from "../../../Components/Dashboard/Table/useTable";
import usePagination from "../../../Components/Dashboard/Table/usePagination";
import PageHeader from "../../../Components/Dashboard/PageHeader";
import { BiSearch } from "react-icons/bi";
import Panel from "../../../Components/Dashboard/Panel";
import MoneyFormat from "../../../Components/Utils/MoneyFormat";
import { Suspense, useEffect, useState } from "react";
import useTitle from "../../../Hooks/useTitle";
import Input from "../../../Components/Forms/Input";
import Submit from "../../../Components/Forms/Submit";
import { BsCalendar, BsDownload, BsX } from "react-icons/bs";
import InvoiceService from "../../../Services/Dashboard/InvoiceService";

const Invoice = () => {
  useTitle("Invoice");
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
  const items = useLoaderData();
  const { Next, Prev, Button, Pagination, setPage, PageSize, Description } =
    usePagination();
  const [id, setId] = useState(null);
  const [token, setToken] = useState(null);

  const [, setSearchParams] = useSearchParams({ search: "" });
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const searchAction = (e) => {
    const s = Object.fromEntries(search);
    setSearchParams({ ...s, search: e.target.value });
  };

  return (
    <div className="w-full">
      <PageHeader>Invoice</PageHeader>
      <div className="mt-5 mb-5 flex items-center justify-end space-x-2">
        <div className="flex h-[40px]  w-full items-center justify-start rounded-[8px] bg-[#e7eaee] md:max-w-[291.83px]">
          <BiSearch className="ml-[15px] mr-[6.5px] h-[15px] w-[15px] flex-none border-none" />
          <input
            type="text"
            placeholder="search"
            className="h-full w-full flex-1 appearance-none border-none bg-transparent text-[14px] font-[500] leading-[21px] placeholder:text-[#64748b] focus:border-transparent focus:outline-none focus:ring-transparent"
            onChange={searchAction}
          />
        </div>
      </div>
      <Panel>
        <Table>
          <RowHeader>
            <ColumnHeader>Receiver Name</ColumnHeader>
            <ColumnHeader>Sender's Name</ColumnHeader>
            <ColumnHeader>Issue Date</ColumnHeader>
            <ColumnHeader>Due Date</ColumnHeader>
            <ColumnHeader>Amount</ColumnHeader>
            <ColumnHeader>Action</ColumnHeader>
          </RowHeader>
          <Body data={items}>
            {(data) =>
              data?.data?.data?.map((v, i) => (
                <Row key={i}>
                  <Column>{v.company_name}</Column>
                  <Column>{v?.organization?.name}</Column>
                  <Column>{v.issue_date}</Column>
                  <Column>
                    <span className="whitespace-nowrap">{v.due_date}</span>
                  </Column>
                  <Column>
                    <p className="text-left">₦{MoneyFormat(v.total)}</p>
                  </Column>
                  <Column>
                    <button
                      className="whitespace-nowrap text-sm text-[#88C02C]"
                      onClick={() => setToken(v)}
                    >
                      View Invoice
                    </button>
                  </Column>
                </Row>
              ))
            }
          </Body>
        </Table>
        <InvoiceEmptyState items={items} />
      </Panel>
      <div className="mt-2 flex items-center justify-between">
        <Description data={items} />
        <PageSize numbers={[5, 10, 20, 50]} />
        <Pagination>
          <Suspense fallback={<p />}>
            <Await resolve={items.data} errorElement={<span>Loading</span>}>
              {(data) => {
                return (
                  <>
                    <Prev
                      onClick={() => {
                        setPage(1);
                      }}
                    />
                    {Array(data?.data?.last_page)
                      .fill(5)
                      .map((v, i) => {
                        return (
                          <Button
                            key={i}
                            isActive={data?.data?.current_page === i + 1}
                            onClick={() => {
                              setPage(i + 1);
                            }}
                          >
                            {i + 1}
                          </Button>
                        );
                      })}
                    <Next
                      onClick={() => {
                        setPage(data?.data?.last_page);
                      }}
                    />
                  </>
                );
              }}
            </Await>
          </Suspense>
        </Pagination>
      </div>
      <SendInvoiceViaEmail id={id} setId={setId} />
      {token && <PreviewEmail id={token} setId={setToken} />}
    </div>
  );
};

const PreviewEmail = ({ id = null, setId = null }) => {
  return (
    <div
      className={`fixed top-0 left-0 z-[999] h-full w-full flex-col items-center justify-start overflow-y-scroll bg-purple-700/70 py-10 ${
        id ? "flex" : "hidden"
      }`}
    >
      <div className="relative min-h-[744.16px] w-full max-w-[569.43px] rounded-[8px] bg-white p-10 shadow ">
        <button
          onClick={() => setId(null)}
          className="absolute -top-2 -left-2 rounded-full bg-white p-1"
        >
          <BsX className="h-6 w-6" />
        </button>
        <div className="w-full">
          <div className="mb-16 flex w-full items-start justify-between">
            <div className="flex items-start justify-end space-x-3 text-left">
              <div
                className="h-20 w-20 rounded-md bg-blue-500 bg-cover bg-center"
                style={{ backgroundImage: `url(${id?.logo_path})` }}
              />
              <div className="flex flex-col items-start justify-end">
                <h2 className="text-2xl font-semibold">
                  #{id?.invoice_number}
                </h2>
                <div className="flex items-center justify-start space-x-2 text-sm text-gray-400">
                  <BsCalendar className="h-4 w-4" />
                  <p className="">{id?.issue_date}</p>
                </div>
              </div>
            </div>
            <div className="flex items-start justify-end text-right">
              <div className="flex flex-col items-start justify-start">
                <h2>Due</h2>
                <div className="flex items-center justify-start space-x-2 text-sm text-gray-400">
                  <BsCalendar className="h-4 w-4" />
                  <p>{id?.due_date}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-10 flex w-full items-start justify-between text-base font-semibold">
            <div className="flex items-start justify-start text-left">
              <div>
                <span className="text-gray-700">To</span>
                <h3 className="font-bold tracking-wide">{id?.company_name}</h3>
                <p className="text-xs font-semibold ">{id?.company_email}</p>
                <p className="text-gray-400">{id?.company_address}</p>
                <p className="text-gray-400">{id?.company_phone_number}</p>
              </div>
            </div>
            <div className="flex items-start justify-end text-left">
              <div>
                <span className="text-gray-700">FROM</span>
                <h3 className="font-bold tracking-wide">
                  {id?.user?.company_name}
                </h3>
                <p className="text-xs font-semibold ">{id?.user?.email}</p>
                <p className="text-gray-400">
                  {id?.user?.first_name} {id?.user?.last_name}
                </p>
                <p className="text-gray-400">{id?.user?.address}</p>
              </div>
            </div>
          </div>
          <div className="w-full">
            <table className="w-full text-left">
              <thead>
                <tr className="rounded-[10px] bg-gray-100 text-left capitalize">
                  <th className="px-2 py-3 text-sm font-normal">Item</th>
                  <th className="px-2 py-3 text-sm font-normal">Quantity</th>
                  <th className="px-2 py-3 text-sm font-normal">Price</th>
                  <th className="px-2 py-3 text-sm font-normal">total</th>
                </tr>
              </thead>
              <tbody>
                {id &&
                  id.items.map((item, k) => (
                    <tr
                      key={k}
                      className="border-b text-sm font-semibold tracking-wide last:border-b-transparent"
                    >
                      <td className="px-2 py-3">{item?.item}</td>
                      <td className="px-2 py-3">{item?.quantity}</td>
                      <td className="px-2 py-3">{item?.unit_price}</td>
                      <td className="px-2 py-3">₦{MoneyFormat(item?.total)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex flex-col items-end justify-start ">
          <div
            className="flex items-center justify-end space-x-2 border-b py-2"
            style={{ borderColor: id?.color }}
          >
            <div className="flex flex-col items-end justify-start text-right ">
              <span>Subtotal</span>
              <span>Discount</span>
              <span>Tax</span>
            </div>
            <div className="flex flex-col items-end justify-start text-right">
              <span>₦{MoneyFormat(id?.subTotal)}</span>
              <span>₦{MoneyFormat(id?.discount)}</span>
              <span>₦{MoneyFormat(id?.tax)}</span>
            </div>
          </div>
          <div className="flex items-center justify-end space-x-2 py-2">
            <div className="flex flex-col items-end justify-start text-right ">
              <span>Total</span>
              <span>Balance Due</span>
            </div>
            <div className="flex flex-col items-end justify-start text-right">
              <span>₦{MoneyFormat(id.total)}</span>
              <span>0.00</span>
            </div>
          </div>
        </div>
        <div className="text-[14px] font-[400] leading-[21px]">
          <div className="mb-[10px]">
            <h4 className="mb-[5px] text-[12px] font-[700] leading-[18px] text-[#590DA4]">
              {" "}
              Notes
            </h4>
            <p>{id?.notes}</p>
          </div>
          <div>
            <h4 className="mb-[5px] text-[12px] font-[700] leading-[18px] text-[#590DA4]">
              {" "}
              Terms
            </h4>
            <p>
              Please Pay withing 30days using the link in your invoice email
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 flex w-full max-w-[569.43px] items-center justify-end rounded-[8px] bg-white p-3 shadow">
        <button
          onClick={() => {
            InvoiceService.downlod(id?.token).then((r) => window.open(r?.data));
          }}
          className="flex items-center justify-center space-x-3 rounded-[10px] bg-purple-700 px-6 py-3 text-sm font-semibold text-white"
        >
          <BsDownload className="h-6 w-6" />
          <span>Download as PDF</span>
        </button>
      </div>
    </div>
  );
};

const SendInvoiceViaEmail = ({ id = null, setId }) => {
  const { Form, state, data } = useFetcher();
  // const t

  useEffect(() => {
    if (data?.success) {
      setId(null);
    }
  }, [data, setId]);

  return (
    <Form
      method="post"
      className={`fixed top-0 left-0 z-[999] h-full w-full items-center justify-center bg-purple-700/70 ${
        id ? "flex" : "hidden"
      }`}
    >
      <div className="relative w-full max-w-sm rounded-[8px] bg-white p-10 shadow">
        <button
          onClick={() => setId(null)}
          className="absolute -top-2 -left-2 rounded-full bg-white p-1"
        >
          <BsX className="h-6 w-6" />
        </button>
        <h2 className="mb-[24px] text-[24px] font-[700] leading-[27.67px] tracking-[2%]">
          Send Via Email
        </h2>
        <p className="text-[18px] font-[400] leading-[27px]">
          Send Generated Invoice
        </p>
        <Input
          name="company_email"
          type="email"
          multiple
          normalize
          placeholder="Enter your email(s)"
        />
        <input type="hidden" name="invoice_token" defaultValue={id} />
        <p className="mb-3text-xs -mt-5 text-gray-300">
          (Enter emails comma separated)
        </p>
        <Submit isLoading={state !== "idle"}>Send as Email</Submit>
      </div>
    </Form>
  );
};

const InvoiceEmptyState = ({ items }) => (
  <Suspense fallback={<div />}>
    <Await resolve={items?.data}>
      {(data) => {
        if (data?.data?.data?.length <= 0) {
          return (
            <div className="flex min-h-[400px] flex-col items-center justify-center rounded-[16px] bg-white text-center md:min-h-[600px]">
              <img src={InvoiceEmpty} alt="" />
              <p className="mt-5 text-[#64748B]">
                No invoice created <br /> at this time
              </p>
            </div>
          );
        }
      }}
    </Await>
  </Suspense>
);

export default Invoice;
