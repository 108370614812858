import { useContext, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoginSocialGoogle } from 'reactjs-social-login'
import Google from '../../Assets/images/Google.png'
import UserObject from '../../DataObjects/UserObject'
import ToastContext from '../../Routes/ToastContext'
import AuthContext from '../../Routes/AuthContext'
import GeneralService from '../../Services/Dashboard/GeneralService'

const SocialLogin = ({ children, isRegister = false }) => {
  const navigate = useNavigate()
  const { flashToast } = useContext(ToastContext)
  const { storeUser } = useContext(AuthContext)

  const onLoginStart = useCallback(() => {

  }, [])

  const style = isRegister ? 'bg-gray-900 text-white' : 'text-gray-900'

  return (
    <LoginSocialGoogle
      client_id='866077710587-fs0f1o2nhe7o7817rko8r7v3e4e975dn.apps.googleusercontent.com'
      onLoginStart={onLoginStart}
      onResolve={({ provider, data }) => {
        const form = { ...data, social_type: provider }
        GeneralService.socialLogin(form).then(r => {
          // console.log(r);
          if (r?.success) {
            storeUser(r.data)
            UserObject.from(r.data).storeUser()

            if (UserObject.getUser()?.exist) {
              navigate('/dashboard')
              return
            }
            navigate('/dashboard/complete')
          }
          flashToast(r?.success, r?.message)
        })
      }}
      onReject={(err) => {
        console.log(err)
      }}
      scope='https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
    >
      <span to='#' className={`my-5 space-x-5 rounded-md  px-4 w-full py-3 flex items-center justify-center ${style}`}>
        <img src={Google} alt='' />
        <span className='font-semibold text-lg'>{children}</span>
      </span>
    </LoginSocialGoogle>
  )
}

export default SocialLogin
