import { Link } from 'react-router-dom'
import ResetDone from '../../Assets/images/ResetDone.png'
import Submit from '../../Components/Forms/Submit'
import HeaderLogo from '../../Components/LandingPage/HeaderLogo'

const PasswordReset = () => {
  return (
    <div className='mt-16 h-full w-full'>
      <HeaderLogo />
      <h1
        className='mb-10 mt-24'
        style={{ fontSize: '48px', fontWeight: 800, lineHeight: '55px' }}
      >
        Reset Password
      </h1>
      <div
        className='flex-center my-10 text-center'
        style={{ flexDirection: 'column' }}
      >
        <img src={ResetDone} alt='' />
        <p>
          Your password has been <br /> successfully reset
        </p>
      </div>
      <Link to='/admin/login'>
        <Submit>Go to Login</Submit>
      </Link>
    </div>
  )
}

export default PasswordReset
