import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { useLoaderData } from "react-router-dom";
import moment from "moment";
////file import
import useTable from "../../../Components/Dashboard/Table/useTable";
import PageHeader from "../../../Components/Dashboard/PageHeader";
import usePagination from "../../../Components/Dashboard/Table/usePagination";
import Panel from "../../../Components/Dashboard/Panel";
import { formatCurrency } from "../../../Components/FormatMoney";
import { SubscriptionTable } from "../../../Components/Dashboard/SaveandGo/Subscriptions";
import { SaveandGoContributionTable } from "../../../Components/Dashboard/SaveandGo/Transactions";

const SaveandGo = () => {
  return (
    <section>
      <section>
        <div>
          <PageHeader>Save and Go</PageHeader>
          <p className="font-[400] leading-[24px] text-[#64748B] text-[px]">
            {new Date().toDateString()}
          </p>
        </div>
        <Wallet />
        <Tab.Group>
          <Tab.List
            className={`my-8 flex h-16 w-full justify-evenly border bg-white text-xs font-bold md:text-base`}
          >
            <Tab
              className={`w-1/2 ui-selected:border-b-2 ui-selected:border-fedacash-header`}
            >
              Susbscriptions
            </Tab>
            <Tab
              className={`w-1/2 ui-selected:border-b-2 ui-selected:border-fedacash-header`}
            >
              Transaction History
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className={`overview`}>
              <section className="">
                <SubscriptionTable />
              </section>
            </Tab.Panel>
            <Tab.Panel>
              <section>
                <SaveandGoContributionTable/>
              </section>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </section>
    </section>
  );
};

export default SaveandGo;

const Wallet = () => {
  const { balanceAmount } = useLoaderData();
  const [isVisible, setIsVisible] = useState(true);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div className="w-full lg:w-1/2 xl:w-2/5">
      <div className="plans-center mt-2 flex gap-x-4 rounded-[21.912px] bg-gradient-to-br from-[#FAB2FF] to-[#6704E5] px-6 py-14 text-white shadow-md ">
        <div className="flex flex-col gap-y-3">
          <p>Total Cooperative Wallet</p>
          <h3 className="text-3xl">
            {isVisible
              ? formatCurrency(balanceAmount?.data?.total_sum, "NGN")
              : "*****"}
          </h3>
        </div>
        <div onClick={handleClick}>
          <AiOutlineEyeInvisible size={32} />
        </div>
      </div>
    </div>
  );
};
