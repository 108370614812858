import ResponseObject from "../../DataObjects/ResponseObject";
import http from "../../Store/baseHttp";
import BaseController from "./BaseController";

class CustomerService extends BaseController {
  async index({ request, params }) {
    const url = new URL(request.url);
    url.searchParams.append("name", url.searchParams.get("search") ?? "");
    try {
      let response = await http.get(
        "/admin/customers/list" + url.search + "&sort_type=DESC"
      );
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  static async view({ request, params }) {
    const url = new URL(request.url);
    url.searchParams.append("name", url.searchParams.get("search") ?? "");
    try {
      let response = await http.get("/admin/customers/single/" + params.id);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  static async approval({ request, params }) {
    const url = new URL(request.url);
    url.searchParams.append("name", url.searchParams.get("search") ?? "");
    try {
      let response = await http.get("/admin/customers/single/" + params.id);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  static async template({ request, params }) {
    try {
      let response = await http.get("/invoice/template");
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  static async email(formData) {
    try {
      let response = await http.post(
        "/admin/staff/onboard/email/resend",
        formData
      );
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async post({ request }) {
    const formData = Object.fromEntries(await request.formData());
    console.log(formData);
    try {
      let response = await http.post("/admin/customers/kyc/comment", formData);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async put({ request }) {
    const formData = Object.fromEntries(await request.formData());
    try {
      let response = await http.put("/admin/customers/kyc/status", formData);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async destroy({ request }) {
    const formData = Object.fromEntries(await request.formData());
    try {
      let response = await http.put("/admin/customers/status/change", formData);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async patch({ request }) {
    const formData = Object.fromEntries(await request.formData());
    try {
      let response = await http.delete(`/admin/staff/delete/${formData.id}`);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }
}

export default CustomerService;
