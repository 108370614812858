import NotificationItem from './NotificationItem'
import Chat from './../../../Assets/icons/Chat.svg'
import { FiX } from 'react-icons/fi'
import moment from 'moment'
import EmptyNotification from './../../../Assets/images/EmptyNotification.png'

const Notification = ({ click, isOpen, data, markAllAsRead }) => {
  return (
    <div className={`fixed top-0 z-50 items-center justify-end left-0 w-full h-full bg-[#590DA4]/40 ${isOpen ? 'flex' : 'hidden'}`}>
      <div className='h-screen max-w-[441px] w-full h-full bg-white py-[59.8px] px-[47px]'>
        <button
          className='absolute rounded-full flex items-center justify-center bg-[#e7eaee] top-12 right-2 w-[37.31px] h-[37.31px]'
          onClick={click}
        >
          <FiX className='w-[13.66px] h-[13.66px]' />
        </button>
        <div>
          <h2 className='font-[700] text-[24px] h-full leading-[27.67px] tracking-[2%]'>Notifications</h2>
          <div className='mt-[10px]'>
            {data && data?.data?.length > 0 && (
              <>
                <button onClick={markAllAsRead} className='text-[14px] leading-[21px] font-[400] text-[#590da4] mr-[16px]'>Mark all as read</button>,
                <button onClick={markAllAsRead} className='text-[14px] leading-[21px] font-[400] text-[#ef4444]'>Clear all</button>
              </>
            )}
          </div>
          <div className='flex flex-col items-start h-full justify-start w-full space-y-[36px] mt-[52.79px]'>
            {
                            data && data?.data?.map((v, i) => (
                              <NotificationItem
                                time={moment(v.created_at).fromNow()}
                                image={Chat}
                                children={v.data.message}
                                key={i}
                              />
                            ))
                        }
            {
                            (!data || data?.data?.length <= 0) && (
                              <div className='flex flex-col space-y-2 h-full md:px-20 md:py-20 w-full items-center justify-center'>
                                <div className='w-[100px] h-[100px]' alt='Logo'>
                                  <img src={EmptyNotification} alt='' />
                                </div>
                                <p className='font-[400] text-[18px] leading-[27px] text-center text-[#64748B]'>No Notifications <br /> Check back Later</p>
                              </div>
                            )
                        }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notification
