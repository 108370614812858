import { useState } from "react";
import { BsX } from "react-icons/bs";
import { Outlet, useLocation, Link } from "react-router-dom";
import PageHeader from "../../../Components/Dashboard/PageHeader";
import Support from "./Support";

const SupportWrapper = () => {
  const location = useLocation();

  return (
    <div className="w-full">
      <PageHeader>Help & Support</PageHeader>
      <div className="flex items-center justify-end" id="portal"></div>
      <div className="mb-[16px] mt-[32px] flex items-center justify-start space-x-[18.06px] overflow-x-auto rounded-[16px] bg-white px-[18.06px]">
        <Link
          to="/dashboard/faq"
          className="border-box border-b-[2px] border-[#590DA4] px-[48.5px] py-[20px] text-center text-[16px] font-[700] leading-[24px] focus:outline-none data-[active=false]:border-transparent data-[active=false]:text-[#64748B]"
          data-active={location.pathname === "/dashboard/faq"}
        >
          FAQ Manager
        </Link>
        <Link
          to="/dashboard/help-and-support"
          className="border-box border-b-[2px] border-[#590DA4] px-[48.5px] py-[20px] text-center text-[16px] font-[700] leading-[24px] focus:outline-none data-[active=false]:border-transparent data-[active=false]:text-[#64748B]"
          data-active={location.pathname === "/dashboard/help-and-support"}
        >
          Support Requests
        </Link>
      </div>
      <Outlet />
    </div>
  );
};

const CreateRequestButton = () => {
  const [state, setState] = useState(false);
  return (
    <>
      <button
        onClick={() => setState((v) => !v)}
        className="flex items-center justify-center rounded-[8px] bg-black px-6 py-3 text-white"
      >
        <span>+ Log Support Request</span>
      </button>
      <div
        data-active={state}
        className=" fixed left-0 top-0 z-[999] flex h-screen w-full items-start justify-center overflow-y-auto bg-purple-700/70 pt-10 data-[active=false]:hidden"
      >
        <div className="relative w-full max-w-md rounded-[16px] bg-white py-8 text-center md:max-w-4xl">
          <button
            onClick={() => setState((v) => !v)}
            className="absolute -left-3 -top-3 rounded-full bg-white"
          >
            <BsX className="h-6 w-6" />
          </button>
          <Support setState={setState} />
        </div>
      </div>
    </>
  );
};

export default SupportWrapper;
