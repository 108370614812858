import { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";
import useTable from "../../../Components/Dashboard/Table/useTable";
import moment from "moment";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import http from "../../../Store/baseHttp";
import UserObject from "../../../DataObjects/UserObject";
import { AiFillCheckCircle } from "react-icons/ai";
import { Spinner } from "flowbite-react";
import PageHeader from "../../../Components/Dashboard/PageHeader";

const Payments = () => {
  let [isOpen, setIsOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [paydata, setPaydata] = useState({});
  const { role_token } = UserObject.getUser().data;

  const payments = useLoaderData();
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
  function openModal(item) {
    setIsOpen(true);
    setPaydata(item);
  }
  function closeModal() {
    setIsOpen(false);
    setPaydata(null);
  }
  function closeSucessModal() {
    setSuccessModal(false);
  }
  function formatLabel(label) {
    return label.replace(/_/g, ' ');
  }
  const handleSubmit = async (item) => {
    setLoading(true);
    try {
      let response = await http
        .post("/admin/request/payments/complete", {
          invoice_number: item.invoice_number,
          user_profile_id: item.user_profile_id,
          request_token: item.request_token,
          amount: item.advanced_amount,
          admin_token: role_token,
          fund_status: "partial",
        })
        .then((res) => {
          const responseData = res.data;
          if (responseData.success) {
            setLoading(false);
            closeModal();
            setSuccessModal(true);
          }
        })
        .catch((error) => {
          // Handle errors if needed
          console.error("Error fetching data:", error);
        });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  function formatAmount(amount) {
    if (typeof amount === 'string') {
      amount = parseFloat(amount);
    }  
    return amount?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <main>
      <PageHeader>Approved Discount Requests</PageHeader>
      <Suspense fallback={<p>Loading...</p>}>
        <Await resolve={payments}>
          {(payments) => {
            const paymentData = payments?.data;
            // console.log(payments?.data);
            return (
              <section>
                <section className="bg-white">
                  <Table>
                    <RowHeader>
                      <ColumnHeader>Account Name</ColumnHeader>
                      <ColumnHeader>Account Number</ColumnHeader>
                      <ColumnHeader>Bank Name</ColumnHeader>
                      <ColumnHeader>Created At</ColumnHeader>
                      <ColumnHeader>Invoice Amount</ColumnHeader>
                      <ColumnHeader>Amount Paid</ColumnHeader>
                      <ColumnHeader>Status</ColumnHeader>
                      <ColumnHeader>Action</ColumnHeader>
                    </RowHeader>
                    <Body data={payments}>
                      {(data) =>
                        data?.map((item, index) => {
                          return (
                            <Row key={index}>
                              <Column>
                                <span className="">{item.account_name}</span>
                              </Column>
                              <Column>{item.account_number}</Column>
                              <Column>{item.bank_name}</Column>
                              <Column>
                                <span className="capitalize">
                                  {moment(item.created_at).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                  )}
                                </span>
                              </Column>
                              <Column>
                                <span>&#8358;{formatAmount(item.invoice_amount)}</span>
                              </Column>
                              <Column>
                                <span>&#8358;{formatAmount(item.total_amount_paid)}</span>
                              </Column>
                              <Column>
                                <span className="text-blue-500 bg-gray-200 text-[10px] rounded-full p-1 xl:py-2 xl:px-3">{formatLabel(item.fund_status)}</span>
                              </Column>
                              <Column>
                                <span
                                  className="rounded-md bg-[#590DA4] px-2 py-3 font-semibold text-white"
                                  onClick={() => openModal(item)}
                                >
                                  Proceed
                                </span>
                              </Column>
                            </Row>
                          );
                        })
                      }
                    </Body>
                  </Table>
                </section>
              </section>
            );
          }}
        </Await>
      </Suspense>
      <ConfirmDialog
        closeModal={closeModal}
        openModal={openModal}
        isOpen={isOpen}
        handleSubmit={handleSubmit}
        item={paydata}
        loading={loading}
      />
      <SuccessDialog closeModal={closeSucessModal} isOpen={successModal} />
    </main>
  );
};

export default Payments;
function ConfirmDialog({ handleSubmit, closeModal, isOpen, item, loading }) {
  function formatAmount(amount) {
    if (typeof amount === 'string') {
      amount = parseFloat(amount);
    }  
    return amount?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Are you sure you want to transfer &#8358;{formatAmount(item?.advanced_amount)}?
                  </Dialog.Title>

                  <div className="mt-4 flex gap-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-[#590DA4] focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      disabled={loading}
                      className="flex items-center justify-center rounded-md border border-transparent bg-[#590DA4] px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-[#590DA4] focus-visible:ring-offset-2"
                      onClick={() => handleSubmit(item)}
                    >
                      {loading && (
                        <div className="">
                          <Spinner color="info" aria-label="It is loading" />
                        </div>
                      )}
                      Confirm
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
function SuccessDialog({ closeModal, isOpen }) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-center text-lg font-medium leading-6 text-gray-900"
                  >
                    Payment Confirmed
                  </Dialog.Title>
                  <div className="flex flex-col items-center justify-center space-y-4 text-center">
                    <AiFillCheckCircle fill="#590DA4" color="#fff" size={80} />
                    <p>Check the paystack dashboard to send the money</p>
                  </div>
                  <div className="mt-4 flex gap-4">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-[#590DA4] px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-[#590DA4] focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
