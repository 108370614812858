import ResponseObject from '../../DataObjects/ResponseObject'
import http from '../../Store/baseHttp'
import BaseController from './BaseController'

class UserRolesService extends BaseController {
  async index ({ request, params }) {
    const url = new URL(request.url)
    try {
      let response = await http.get('/roles' + url.search + '&sort_type=DESC')
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }

  static async permissions (search = '') {
    try {
      let response = await http.get('/permission?page_size=100')
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }

  static async assignPermissionToRole (formData) {
    try {
      let response = await http.post('/permission/assign', formData)
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }

  static async permissionById (id) {
    try {
      let response = await http.get('permission/assign/' + id)
      response = ResponseObject.from(response)

      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }

  static async permissionRoleById (id) {
    try {
      let response = await http.get('permission/assign/user/' + id)
      response = ResponseObject.from(response)

      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }

  async put ({ request }) {
    const formData = Object.fromEntries(await request.formData())
    try {
      let response = await http.put('/roles/update', formData)
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }

  async destroy ({ request }) {
    const formData = Object.fromEntries(await request.formData())
    try {
      let response = await http.put('/roles/update', formData)
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }
}

export default UserRolesService
