import Submit from '../../Components/Forms/Submit'
import SingleInput from '../../Components/Forms/SingleInput'
import {
  Link,
  useFetcher,
  useLocation,
  useNavigate,
  useSubmit
} from 'react-router-dom'
import { useEffect, useState } from 'react'
import Input from '../../Components/Forms/Input'
import HeaderLogo from '../../Components/LandingPage/HeaderLogo'

const PasswordResetVerify = () => {
  const { Form, formAction, state, data, formData } = useFetcher()
  const navigate = useNavigate()
  const [count, setCount] = useState(30)

  const { search } = useLocation()
  const search1 = new URLSearchParams(search)
console.log(search1, search)
  const submit = useSubmit()
  const resendCode = () => {
    submit(formData, {
      action: '/admin/resend-password-verify',
      method: 'post'
    })
  }

  useEffect(() => {
    if (!data) return;
    if (data?.response?.success) {
      const { token } = data?.response?.data;
      navigate("/auth/reset-password?token=" + token);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    const interval = count && setInterval(() => setCount((v) => v - 1), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [count])

  return (
    <div className='mt-10 h-full w-full'>
      <HeaderLogo />
      <div className='mb-32 mt-10 md:mb-52'>
        <Link to='/'>
          <span style={{ color: '#590DA4', fontSize: '18px', fontWeight: 800 }}>
            Go Back Home
          </span>
        </Link>
      </div>
      <div className='w-full'>
        <h1 className='mb-2' style={{ fontSize: '44px', fontWeight: 800 }}>
          Verification
        </h1>
        <p className='mt-5 mb-10'>
          A verification Code has been sent to{' '}
          <strong>{search1.get('email')}</strong>. Check your mail
        </p>
        <Form action={formAction} method='post'>
          <SingleInput name='token' />
          <p className='pt-6 pb-10'>
            Didn't receive any code? &nbsp;
            <Link
              to='#'
              onClick={resendCode}
              className={`font-semibold text-purple-800 ${
                count > 0 && 'opacity-50'
              }`}
            >
              Resend Code (in {count}s)
            </Link>
          </p>
          <Input
            error={data?.errors?.token}
            type='hidden'
            name='email'
            value={search1.get('email') ?? ''}
          />
          <Submit isLoading={state !== 'idle'}>Verify Account</Submit>
        </Form>
      </div>
    </div>
  )
}

export default PasswordResetVerify
