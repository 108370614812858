import { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

const usePortal = el => {
  const [portal, setPortal] = useState({
    render: () => null,
    remove: () => null
  })

  const createPortal = useCallback(el => {
    const Portal = ({ children }) => ReactDOM.createPortal(children, el)
    const remove = () => ReactDOM.unmountComponentAtNode(el)
    return { render: Portal, remove }
  }, [])

  useEffect(() => {
    if (el) portal.remove()
    const newPortal = createPortal(el)
    setPortal(newPortal)
    return () => newPortal.remove(el)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [el])

  return portal.render
}

export default usePortal
