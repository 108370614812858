import { Suspense, useContext, useEffect, useState } from "react";
import { Await, useFetcher, useLoaderData } from "react-router-dom";
import useTable from "../../../Components/Dashboard/Table/useTable";
import InvoiceEmpty from "../../../Assets/images/InvoiceEmpty.png";
import { BsThreeDots, BsX } from "react-icons/bs";
import Panel from "../../../Components/Dashboard/Panel";
import Portal from "../../../Components/Dashboard/Portal";
import Input from "../../../Components/Forms/Input";
import TextArea from "../../../Components/Forms/TextArea";
import Submit from "../../../Components/Forms/Submit";
import { Dropdown, Popconfirm } from "antd";
import ToastContext from "../../../Routes/ToastContext";
import PaginateAll from "../../../Components/Utils/PaginateAll";

const FAQIndex = () => {
  const { Table, RowHeader, ColumnHeader, Column, Row, Body } = useTable();
  const items = useLoaderData();
  const { submit } = useFetcher();
  const [edit, setEdit] = useState(null);
  const [view, setView] = useState(null);

  return (
    <Panel>
      <Table>
        <RowHeader>
          <ColumnHeader>Category</ColumnHeader>
          <ColumnHeader>Sub Category</ColumnHeader>
          <ColumnHeader>FAQ Title</ColumnHeader>
          <ColumnHeader>FAQ Answer</ColumnHeader>
          <ColumnHeader>Action</ColumnHeader>
        </RowHeader>
        <Body data={items}>
          {(data) =>
            data?.data?.data?.length &&
            data?.data?.data?.map((v) => (
              <Row key={v.id}>
                <Column>{v.category}</Column>
                <Column>{v.sub_category}</Column>
                <Column>{v.question}</Column>
                <Column>
                  <p className="whitespace-wrap line-clamp-1 w-full overflow-hidden text-ellipsis">
                    {v.answer}
                  </p>
                </Column>
                <Column>
                  <Dropdown
                    trigger={["click"]}
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: (
                            <button
                              onClick={() => {
                                setView(v);
                              }}
                              className="text-sm text-blue-500 focus:outline-none"
                            >
                              View
                            </button>
                          ),
                        },
                        {
                          key: "2",
                          label: (
                            <button
                              onClick={() => {
                                setEdit(v);
                              }}
                              className="text-sm text-blue-500 focus:outline-none"
                            >
                              Edit
                            </button>
                          ),
                        },
                        {
                          key: "3",
                          label: (
                            <Popconfirm
                              title="Delete"
                              onConfirm={() => {
                                submit(
                                  {
                                    id: v.token,
                                  },
                                  {
                                    method: "delete",
                                  }
                                );
                              }}
                              okText={<span className="text-black">Yes</span>}
                              className="text-black"
                            >
                              Delete
                            </Popconfirm>
                          ),
                        },
                      ],
                    }}
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                  >
                    <div className="flex items-center justify-center">
                      <BsThreeDots className="h-4 w-4" />
                    </div>
                  </Dropdown>
                </Column>
              </Row>
            ))
          }
        </Body>
      </Table>
      <PaginateAll items={items} />
      <SupportEmptyState items={items} />
      <EditModal view={edit} setView={setEdit} />
      <ViewModal view={view} setView={setView} />
    </Panel>
  );
};

const SupportEmptyState = ({ items }) => {
  return (
    <Suspense fallback={<div />}>
      <Await resolve={items?.data}>
        {(data) => {
          if (data === undefined || data?.data?.data.length <= 0) {
            return (
              <div className="flex min-h-[400px] flex-col items-center justify-center rounded-[16px] bg-white text-center md:min-h-[600px]">
                <img src={InvoiceEmpty} alt="Transactions" />
                <p className="mb-5 mt-5 text-[#64748B]">
                  You don’t have any incident <br /> at the time
                </p>
                <CreateRequestButton />
              </div>
            );
          }
          return (
            <Portal selector="#portal">
              <CreateRequestButton />
            </Portal>
          );
        }}
      </Await>
    </Suspense>
  );
};

const CreateRequestButton = () => {
  const [state, setState] = useState(false);
  return (
    <>
      <button
        onClick={() => setState((v) => !v)}
        className="flex items-center justify-center rounded-[8px] bg-black px-6 py-3 text-white"
      >
        <span>+ Create FAQ</span>
      </button>
      <div
        data-active={state}
        className=" fixed left-0 top-0 z-[999] flex h-screen w-full items-start justify-center overflow-y-auto bg-purple-700/70 pt-10 data-[active=false]:hidden"
      >
        <div className="relative w-full max-w-md rounded-[16px] bg-white py-8 md:max-w-4xl">
          <button
            onClick={() => setState((v) => !v)}
            className="absolute -left-3 -top-3 rounded-full bg-white"
          >
            <BsX className="h-6 w-6" />
          </button>
          <CreateModal view={state} setView={setState} />
        </div>
      </div>
    </>
  );
};

const CreateModal = ({ view, setView }) => {
  const { flashToast } = useContext(ToastContext);
  const { Form, data, state: isIdle } = useFetcher();

  useEffect(() => {
    flashToast(data?.success, data?.message);
    if (data?.success) {
      setView(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div className="grid grid-cols-2 p-6">
      <div className="hidden flex-col items-center justify-center space-y-4 md:col-span-1 md:flex">
        <svg
          width="231"
          height="231"
          viewBox="0 0 231 231"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M72.7433 191.731H182.952L211.702 220.481V119.856C211.702 109.314 203.077 100.689 192.535 100.689H72.7433C62.2017 100.689 53.5767 109.314 53.5767 119.856V172.564C53.5767 183.106 62.2017 191.731 72.7433 191.731Z"
            fill="#590DA4"
          />
          <path
            d="M138.868 157.231H121.618L118.264 167.294H107.722L125.451 119.377H134.556L152.285 167.294H141.743L138.868 157.231ZM124.014 149.564H135.993L130.243 131.356L124.014 149.564Z"
            fill="#240542"
          />
          <path
            d="M158.993 119.855H48.7847L20.0347 148.605V38.3971C20.0347 27.8555 28.6597 19.2305 39.2013 19.2305H158.993C169.535 19.2305 178.16 27.8555 178.16 38.3971V100.689C178.16 111.23 169.535 119.855 158.993 119.855Z"
            fill="#7A3DB6"
          />
          <path
            d="M122.576 68.1068C122.576 72.8984 121.618 76.7318 120.181 80.0859C118.743 83.4401 116.826 86.3151 113.951 88.2318L122.097 94.4609L115.868 100.211L105.326 92.0651C104.368 92.0651 102.931 92.5443 101.493 92.5443C98.618 92.5443 95.743 92.0651 92.868 91.1068C90.4722 90.1484 88.0763 88.2318 86.1597 86.3151C84.243 84.3984 82.8055 81.5234 81.8472 78.6484C80.8888 75.7734 80.4097 72.4193 80.4097 68.5859V66.6693C80.4097 62.8359 80.8888 59.4818 81.8472 56.6068C82.8055 53.7318 84.243 50.8568 86.1597 48.9401C88.0763 47.0234 89.993 45.1068 92.868 44.1484C95.2638 43.1901 98.1388 42.7109 101.493 42.7109C104.368 42.7109 107.243 43.1901 110.118 44.1484C112.514 45.1068 114.91 47.0234 116.826 48.9401C118.743 50.8568 120.181 53.7318 121.139 56.6068C122.097 59.4818 122.576 62.8359 122.576 66.6693V68.1068ZM112.035 65.7109C112.035 60.4401 111.076 56.6068 109.16 54.2109C107.243 51.3359 104.847 50.3776 101.493 50.3776C98.1388 50.3776 95.2638 51.8151 93.8263 54.2109C91.9097 57.0859 90.9513 60.9193 90.9513 65.7109V68.1068C90.9513 70.5026 91.4305 72.8984 91.9097 74.8151C92.3888 76.7318 92.868 78.6484 93.8263 79.6068C94.7847 81.0443 95.743 82.0026 97.1805 82.4818C98.618 82.9609 100.056 83.4401 101.493 83.4401C104.847 83.4401 107.722 82.0026 109.16 79.6068C111.076 76.7318 112.035 72.8984 112.035 67.6276V65.7109Z"
            fill="white"
          />
        </svg>
        <p>Log FAQ and Answers Here</p>
      </div>
      <div className="col-span-full text-left md:col-span-1">
        <Form method="POST">
          <Input
            name="category"
            label="Category"
            placeholder="Enter Category"
            normalize={true}
            error={data?.errors?.category}
          />
          <Input
            name="sub_category"
            label="Sub Category"
            placeholder="Enter your Sub Category"
            normalize={true}
            error={data?.errors?.sub_category}
          />
          <Input
            name="question"
            label="FAQ Title"
            placeholder="Enter your question"
            normalize={true}
            error={data?.errors?.question}
          />
          <TextArea
            name="answer"
            label="FAQ Answer"
            placeholder="Enter your answer"
            error={data?.errors?.answer}
          />
          <TextArea
            name="additional_answer"
            label="FAQ Additional Answer"
            placeholder="Enter your Additional answer"
            error={data?.errors?.additional_answer}
          />
          <Submit isLoading={isIdle !== "idle"}>Save</Submit>
        </Form>
      </div>
    </div>
  );
};

const EditModal = ({ view, setView }) => {
  const { flashToast } = useContext(ToastContext);
  const { Form, data, state: isIdle } = useFetcher();

  useEffect(() => {
    flashToast(data?.success, data?.message);
    if (data?.success) {
      setView(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div
      data-active={!!view}
      className=" fixed left-0 top-0 z-[999] flex h-screen w-full items-start justify-center overflow-y-auto bg-purple-700/70 pt-10 data-[active=false]:hidden"
    >
      <div className="relative w-full max-w-md rounded-[16px] bg-white py-8 md:max-w-4xl">
        <button
          onClick={() => setView(false)}
          className="absolute -left-3 -top-3 rounded-full bg-white"
        >
          <BsX className="h-6 w-6" />
        </button>
        <div className="grid grid-cols-2 p-6">
          <div className="hidden flex-col items-center justify-center space-y-4 md:col-span-1 md:flex">
            <svg
              width="231"
              height="231"
              viewBox="0 0 231 231"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M72.7433 191.731H182.952L211.702 220.481V119.856C211.702 109.314 203.077 100.689 192.535 100.689H72.7433C62.2017 100.689 53.5767 109.314 53.5767 119.856V172.564C53.5767 183.106 62.2017 191.731 72.7433 191.731Z"
                fill="#590DA4"
              />
              <path
                d="M138.868 157.231H121.618L118.264 167.294H107.722L125.451 119.377H134.556L152.285 167.294H141.743L138.868 157.231ZM124.014 149.564H135.993L130.243 131.356L124.014 149.564Z"
                fill="#240542"
              />
              <path
                d="M158.993 119.855H48.7847L20.0347 148.605V38.3971C20.0347 27.8555 28.6597 19.2305 39.2013 19.2305H158.993C169.535 19.2305 178.16 27.8555 178.16 38.3971V100.689C178.16 111.23 169.535 119.855 158.993 119.855Z"
                fill="#7A3DB6"
              />
              <path
                d="M122.576 68.1068C122.576 72.8984 121.618 76.7318 120.181 80.0859C118.743 83.4401 116.826 86.3151 113.951 88.2318L122.097 94.4609L115.868 100.211L105.326 92.0651C104.368 92.0651 102.931 92.5443 101.493 92.5443C98.618 92.5443 95.743 92.0651 92.868 91.1068C90.4722 90.1484 88.0763 88.2318 86.1597 86.3151C84.243 84.3984 82.8055 81.5234 81.8472 78.6484C80.8888 75.7734 80.4097 72.4193 80.4097 68.5859V66.6693C80.4097 62.8359 80.8888 59.4818 81.8472 56.6068C82.8055 53.7318 84.243 50.8568 86.1597 48.9401C88.0763 47.0234 89.993 45.1068 92.868 44.1484C95.2638 43.1901 98.1388 42.7109 101.493 42.7109C104.368 42.7109 107.243 43.1901 110.118 44.1484C112.514 45.1068 114.91 47.0234 116.826 48.9401C118.743 50.8568 120.181 53.7318 121.139 56.6068C122.097 59.4818 122.576 62.8359 122.576 66.6693V68.1068ZM112.035 65.7109C112.035 60.4401 111.076 56.6068 109.16 54.2109C107.243 51.3359 104.847 50.3776 101.493 50.3776C98.1388 50.3776 95.2638 51.8151 93.8263 54.2109C91.9097 57.0859 90.9513 60.9193 90.9513 65.7109V68.1068C90.9513 70.5026 91.4305 72.8984 91.9097 74.8151C92.3888 76.7318 92.868 78.6484 93.8263 79.6068C94.7847 81.0443 95.743 82.0026 97.1805 82.4818C98.618 82.9609 100.056 83.4401 101.493 83.4401C104.847 83.4401 107.722 82.0026 109.16 79.6068C111.076 76.7318 112.035 72.8984 112.035 67.6276V65.7109Z"
                fill="white"
              />
            </svg>
            <p>Log FAQ and Answers Here</p>
          </div>
          <div className="col-span-full md:col-span-1">
            <Form method="PUT">
              <Input
                name="category"
                label="Category"
                placeholder="Enter Category"
                normalize={true}
                error={data?.errors?.category}
                defaultValue={view?.category}
              />
              <Input
                name="sub_category"
                label="Sub Category"
                placeholder="Enter your Sub Category"
                normalize={true}
                error={data?.errors?.sub_category}
                defaultValue={view?.sub_category}
              />
              <Input
                name="question"
                label="FAQ Title"
                placeholder="Enter your question"
                normalize={true}
                error={data?.errors?.question}
                defaultValue={view?.question}
              />
              <TextArea
                name="answer"
                label="FAQ Answer"
                placeholder="Enter your answer"
                error={data?.errors?.answer}
                defaultValue={view?.answer}
              />
              <TextArea
                name="additional_answer"
                label="FAQ Additional Answer"
                placeholder="Enter your Additional Answer"
                error={data?.errors?.additional_answer}
                defaultValue={view?.additional_answer}
              />
              <input type="hidden" name="id" defaultValue={view?.token} />
              <Submit isLoading={isIdle !== "idle"}>Save</Submit>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

const ViewModal = ({ view, setView }) => {
  const { flashToast } = useContext(ToastContext);
  const { Form, data, state: isIdle } = useFetcher();

  useEffect(() => {
    flashToast(data?.success, data?.message);
    if (data?.success) {
      setView(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div
      data-active={!!view}
      className=" fixed left-0 top-0 z-[999] flex h-screen w-full items-start justify-center overflow-y-auto bg-purple-700/70 pt-10 data-[active=false]:hidden"
    >
      <div className="relative w-full max-w-md rounded-[16px] bg-white py-8 md:max-w-4xl">
        <button
          onClick={() => setView(false)}
          className="absolute -left-3 -top-3 rounded-full bg-white"
        >
          <BsX className="h-6 w-6" />
        </button>
        <div className="grid grid-cols-2 p-6">
          <div className="hidden flex-col items-center justify-center space-y-4 md:col-span-1 md:flex">
            <svg
              width="231"
              height="231"
              viewBox="0 0 231 231"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M72.7433 191.731H182.952L211.702 220.481V119.856C211.702 109.314 203.077 100.689 192.535 100.689H72.7433C62.2017 100.689 53.5767 109.314 53.5767 119.856V172.564C53.5767 183.106 62.2017 191.731 72.7433 191.731Z"
                fill="#590DA4"
              />
              <path
                d="M138.868 157.231H121.618L118.264 167.294H107.722L125.451 119.377H134.556L152.285 167.294H141.743L138.868 157.231ZM124.014 149.564H135.993L130.243 131.356L124.014 149.564Z"
                fill="#240542"
              />
              <path
                d="M158.993 119.855H48.7847L20.0347 148.605V38.3971C20.0347 27.8555 28.6597 19.2305 39.2013 19.2305H158.993C169.535 19.2305 178.16 27.8555 178.16 38.3971V100.689C178.16 111.23 169.535 119.855 158.993 119.855Z"
                fill="#7A3DB6"
              />
              <path
                d="M122.576 68.1068C122.576 72.8984 121.618 76.7318 120.181 80.0859C118.743 83.4401 116.826 86.3151 113.951 88.2318L122.097 94.4609L115.868 100.211L105.326 92.0651C104.368 92.0651 102.931 92.5443 101.493 92.5443C98.618 92.5443 95.743 92.0651 92.868 91.1068C90.4722 90.1484 88.0763 88.2318 86.1597 86.3151C84.243 84.3984 82.8055 81.5234 81.8472 78.6484C80.8888 75.7734 80.4097 72.4193 80.4097 68.5859V66.6693C80.4097 62.8359 80.8888 59.4818 81.8472 56.6068C82.8055 53.7318 84.243 50.8568 86.1597 48.9401C88.0763 47.0234 89.993 45.1068 92.868 44.1484C95.2638 43.1901 98.1388 42.7109 101.493 42.7109C104.368 42.7109 107.243 43.1901 110.118 44.1484C112.514 45.1068 114.91 47.0234 116.826 48.9401C118.743 50.8568 120.181 53.7318 121.139 56.6068C122.097 59.4818 122.576 62.8359 122.576 66.6693V68.1068ZM112.035 65.7109C112.035 60.4401 111.076 56.6068 109.16 54.2109C107.243 51.3359 104.847 50.3776 101.493 50.3776C98.1388 50.3776 95.2638 51.8151 93.8263 54.2109C91.9097 57.0859 90.9513 60.9193 90.9513 65.7109V68.1068C90.9513 70.5026 91.4305 72.8984 91.9097 74.8151C92.3888 76.7318 92.868 78.6484 93.8263 79.6068C94.7847 81.0443 95.743 82.0026 97.1805 82.4818C98.618 82.9609 100.056 83.4401 101.493 83.4401C104.847 83.4401 107.722 82.0026 109.16 79.6068C111.076 76.7318 112.035 72.8984 112.035 67.6276V65.7109Z"
                fill="white"
              />
            </svg>
            <p>Log FAQ and Answers Here</p>
          </div>
          <div className="col-span-full md:col-span-1">
            <h3 className="text-xl font-semibold uppercase">
              {view?.question} ?
            </h3>
            <p className="mt-8 text-lg">
              {" "}
              <span className="font-semibold">Answer:</span> <br />{" "}
              {view?.answer}
            </p>
            <p className="mt-4 text-lg">
              <span className="font-semibold">Additional Answer:</span> <br />
              {view?.additional_answer}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQIndex;
