import { useFetcher, useLoaderData } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";

///
import Panel from "../../../Components/Dashboard/Panel";
import Input from "../../../Components/Forms/Input";
import Submit from "../../../Components/Forms/Submit";
import ToastContext from "../../../Routes/ToastContext";
import { fetchEndpoint } from "../../../Services/Dashboard/CooperativePayment";
import Select from "../../../Components/Forms/Select";
import Banks from "../Dashboard/Banks";

const CreatePayment = () => {
  const { Form, data, state } = useFetcher();
  const { flashToast } = useContext(ToastContext);
  const [bankName, setBankName] = useState("");
  const [packageDetails, setPackageDetails] = useState({
    package_name: "",
    member_token: "",
    member_name: "",
    customer_email: "",
  });
  useEffect(() => {
    flashToast(data?.success, data?.message);
  }, [data?.message]);

  return (
    <Panel>
      <div className="relative p-6">
        <h3 className="text-[18px] font-[700] leading-[28px] text-[#344054]">
          Add Payment for User
        </h3>
        <hr className="my-[22px] w-full border-[#EAECF0]" />
        <Form
          method="post"
          className="plans-start flex w-full flex-col justify-start space-y-5"
          encType="multipart/form-data"
        >
          <div className="w-full">
            <PackageName
              packageDetails={packageDetails}
              setPackageDetails={setPackageDetails}
            />
            <Input
              label="Member Token"
              placeholder="Member Token"
              name="member_token"
              value={packageDetails?.member_token}
              required={true}
              error={data?.errors?.member_token}
            />
            <Input
              label="Customer Fullname"
              placeholder="Customer Fullname"
              name="customer_fullname"
              value={packageDetails?.member_name}
              required={true}
              error={data?.errors?.customer_fullname}
            />
            <Input
              label="Customer Email"
              placeholder="Customer Email"
              name="customer_email"
              value={packageDetails?.customer_email}
              required={true}
              error={data?.errors?.customer_email}
            />
            <Input
              label="Reference"
              placeholder="Reference"
              name="reference"
              normalize={true}
              required={true}
              error={data?.errors?.reference}
            />
            <Input
              label="Transaction Date"
              placeholder="Transaction Date"
              name="transaction_date"
              normalize={true}
              required={true}
              type="datetime-local"
              error={data?.errors?.transaction_date}
            />
            <Input
              label="Recipient Account Name"
              placeholder="Recipient Account Name"
              name="recipient_account_name"
              normalize={true}
              required={true}
              error={data?.errors?.recipient_account_name}
            />
            <Input
              label="Recipient Account Number"
              placeholder="Recipient Account Number"
              name="recipient_account_number"
              normalize={true}
              required={true}
              error={data?.errors?.recipient_account_number}
            />
            <Banks
              label="Bank Name"
              placeholder={""}
              onChange={(event) => setBankName(event.target.value)}
              name="recipient_bank_name"
              error={data?.errors?.recipient_bank_name}
              top={true}
            />
            <Input
              label="Amount Paid"
              placeholder="Amount Paid"
              name="amount_paid"
              normalize={true}
              type="number"
              required={true}
              error={data?.errors?.amount_paid}
            />
            <div className="mb-2">
              <label className="mb-1 flex w-full items-center gap-x-2 text-lg font-bold capitalize text-gray-700">
                Status <span className="text-red-700">*</span>
              </label>
              <Select name="status" error={data?.errors?.status}>
                <option>--Select--</option>
                <option value={`paid`}>Paid</option>
              </Select>
            </div>

            <Input
              label="Transaction ID"
              placeholder="Transaction ID"
              name="transaction_id"
              normalize={true}
              error={data?.errors?.transaction_id}
            />
            <Input
              label="Phone"
              placeholder="Phone"
              name="phone"
              normalize={true}
              error={data?.errors?.phone}
            />
          </div>
          <Submit isLoading={state !== "idle"}>Submit</Submit>
        </Form>
      </div>
    </Panel>
  );
};

export default CreatePayment;

const PackageName = ({ packageDetails, setPackageDetails }) => {
  const data = useLoaderData();
  const [selectedPackage, setSelectedPackage] = useState([]);
  function handlePackageChange(event) {
    setPackageDetails({
      ...packageDetails,
      package_name: event.target.value,
    });
    setSelectedPackage(data.find((obj) => obj.title === event.target.value));
  }
  function handleNameChange(event) {
    const { value } = event.target;
    const selectedMember = selectedPackage.members.find(
      (item) => item.firstname === value
    );

    if (selectedMember) {
      const { firstname, lastname, token, email } = selectedMember;
      setPackageDetails({
        ...packageDetails,
        member_token: token,
        member_name: `${firstname} ${lastname}`,
        customer_email: email,
      });
    } else {
      setPackageDetails({
        ...packageDetails,
        member_token: "",
        member_name: "",
        customer_email: "",
      });
    }
  }
  // console.log(selectedPackage);
  return (
    <>
      <div>
        <label className="mb-1 flex w-full items-center gap-x-2 text-lg font-bold capitalize text-gray-700">
          Package Name <span className="text-red-700">*</span>
        </label>
        <Select name="package_name" onChange={handlePackageChange}>
          <option>--Select--</option>
          {data?.map((item, index) => (
            <option key={index} value={item?.title}>
              {item?.title}
            </option>
          ))}
        </Select>
      </div>
      <div>
        <label className="mb-1 flex w-full items-center gap-x-2 text-lg font-bold capitalize text-gray-700">
          Members Name <span className="text-red-700">*</span>
        </label>
        <Select onChange={handleNameChange}>
          <option>--Select--</option>
          {selectedPackage?.members?.length > 0 ? (
            selectedPackage.members.map((member, index) => (
              <option key={index} value={member.firstname}>
                {member.firstname} {member.lastname}
              </option>
            ))
          ) : (
            <option disabled>No members found</option>
          )}
        </Select>
      </div>
    </>
  );
};
