export const UserCard = ({userdata}) => {
  const {first_name, last_name,company_name}=userdata;

    return (
      <div className="relative inline-flex h-24 w-96 flex-col items-start justify-start rounded-3xl bg-white px-4 py-6">
        <div className="inline-flex items-center justify-start gap-3">
          <div className="flex h-12 w-12 items-center justify-center">
            <img
              className="h-12 w-12 rounded-full"
              src="https://via.placeholder.com/48x48"
            />
          </div>
          <div className="inline-flex flex-col items-start justify-start">
            <div className="text-lg font-bold leading-relaxed">{first_name} {last_name}</div>
            <div className="text-sm font-normal leading-tight">{company_name}</div>
          </div>
        </div>
      </div>
    );
  };