import { Suspense, useContext, useEffect, useState } from "react";
import { BsPlus, BsThreeDots, BsX } from "react-icons/bs";
import {
  Await,
  useFetcher,
  useLoaderData,
  Link,
  useLocation,
} from "react-router-dom";
import useTitle from "../../../Hooks/useTitle";
import InvoiceEmpty from "../../../Assets/images/InvoiceEmpty.png";
import useTable from "../../../Components/Dashboard/Table/useTable";
import PaginateAll from "../../../Components/Utils/PaginateAll";
import ToastContext from "../../../Routes/ToastContext";
import Submit from "../../../Components/Forms/Submit";
import Input from "../../../Components/Forms/Input";
import { Dropdown, Popconfirm } from "antd";
import UserObject from "../../../DataObjects/UserObject";
import States from "../Dashboard/States";
import Countries from "../Dashboard/Countries";
import Categories from "../Dashboard/Categories";

const CompanyIndex = () => {
  useTitle("Companies");
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
  const items = useLoaderData();
  const { submit, data } = useFetcher();
  const { flashToast } = useContext(ToastContext);
  // eslint-disable-next-line no-unused-vars
  const [viewId, setViewId] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    flashToast(data?.success, data?.message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Table>
        <RowHeader>
          <ColumnHeader>Anchor Name</ColumnHeader>
          <ColumnHeader>RC Number</ColumnHeader>
          <ColumnHeader>Category</ColumnHeader>
          {/* <ColumnHeader>Email Address</ColumnHeader> */}
          <ColumnHeader>Phone Number</ColumnHeader>
          <ColumnHeader>Status</ColumnHeader>
          <ColumnHeader>Action</ColumnHeader>
        </RowHeader>
        <Body data={items}>
          {(data) =>
            data?.data?.data?.map((v, i) => (
              <Row key={i}>
                <Column>
                  <span className="">{v?.company_name}</span>
                </Column>
                <Column>{v.registration_number}</Column>
                <Column>{v.industry}</Column>
                {/* <Column>{v.email}</Column> */}
                <Column>
                  <span className="capitalize">
                    {v.contact_person_phone_number}
                  </span>
                </Column>
                <Column>
                  <Status status={v.status} />
                </Column>

                <Column>
                  {!UserObject.getUser().isAdmin() && (
                    <Dropdown
                      trigger={["click"]}
                      menu={{
                        items: [
                          parseInt(v?.approval_status) === 1
                            ? {
                                key: "1",
                                label: (
                                  <Link
                                    to={`/dashboard/company/${v.company_token}`}
                                    className="text-sm text-blue-500 focus:outline-none"
                                  >
                                    View More
                                  </Link>
                                ),
                              }
                            : {
                                key: "1",
                                label: (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setEditId(v);
                                    }}
                                    className="text-sm text-blue-500 focus:outline-none"
                                  >
                                    View More
                                  </button>
                                ),
                              },
                          {
                            key: "2",
                            label: (
                              <Popconfirm
                                title={`${
                                  parseInt(v?.status) === 1
                                    ? "Deactivate"
                                    : "Activate"
                                } ${v.company_name}`}
                                onConfirm={() => {
                                  submit(
                                    {
                                      token: v.company_token,
                                      status:
                                        parseInt(v?.status) === 1
                                          ? "deactivate"
                                          : "activate",
                                    },
                                    {
                                      method: "patch",
                                    }
                                  );
                                }}
                                okText={<span className="text-black">Yes</span>}
                                className="text-black"
                              >
                                {parseInt(v?.status) === 1
                                  ? "Deactivate"
                                  : "Activate"}
                              </Popconfirm>
                            ),
                          },
                          {
                            key: "3",
                            label: (
                              <Popconfirm
                                title={`Delete ${v.company_name}`}
                                onConfirm={() => {
                                  submit(
                                    {
                                      token: v.company_token,
                                    },
                                    {
                                      method: "delete",
                                    }
                                  );
                                }}
                                okText={<span className="text-black">Yes</span>}
                                className="text-black"
                              >
                                Delete
                              </Popconfirm>
                            ),
                          },
                        ],
                      }}
                      placement="bottomRight"
                      arrow={{ pointAtCenter: true }}
                    >
                      <div className="flex items-center justify-center">
                        <BsThreeDots className="w-4 h-4" />
                      </div>
                    </Dropdown>
                  )}
                </Column>
              </Row>
            ))
          }
        </Body>
      </Table>
      <MembersEmptyState items={items} />
      <ViewMemberButton view={viewId} />
      <EditMemberButton view={editId} />
      <PaginateAll items={items} />
    </>
  );
};

const MembersEmptyState = ({ items }) => {
  const location = useLocation();

  return (
    <Suspense fallback={<div />}>
      <Await resolve={items?.data}>
        {(data) => {
          if (data === undefined || data?.data?.data.length <= 0) {
            return (
              <div className="flex min-h-[400px] flex-col items-center justify-center rounded-[16px] bg-white text-center md:min-h-[600px]">
                <img src={InvoiceEmpty} alt="Transactions" />
                <p className="mt-5 text-[#64748B]">
                  You do not have any <strong>company</strong> under <br /> this
                  category
                </p>
                {location.pathname.toLowerCase() !==
                "/dashboard/company/suggested" ? (
                  <CreateMemberButton />
                ) : null}
              </div>
            );
          }

          return null;
        }}
      </Await>
    </Suspense>
  );
};

export const CreateMemberButton = () => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <div>
      <button
        onClick={() => {
          setIsOpened((v) => true);
        }}
        className="flex items-center justify-start space-x-2 whitespace-nowrap rounded-[8px] bg-[#590DA4] px-4 py-2 text-sm font-semibold text-white"
      >
        <BsPlus className="w-6 h-6" />
        <span>Add Company</span>
      </button>
      <div
        data-active={isOpened}
        className=" fixed left-0 top-0 z-[999] flex h-screen w-full items-start justify-center overflow-y-auto bg-purple-700/70 pt-10 data-[active=false]:hidden"
      >
        <div className="relative w-full max-w-2xl rounded-[16px] bg-white p-6">
          <button
            className="absolute w-6 h-6 bg-white rounded-full -left-5 -top-5"
            onClick={() => {
              setIsOpened((v) => !v);
            }}
          >
            <BsX className="w-6 h-6" />
          </button>
          <MemberCreateModal setState={setIsOpened} view={isOpened} />
        </div>
      </div>
    </div>
  );
};

const ViewMemberButton = ({ view }) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    if (view) {
      setState(true);
    }
  }, [view]);

  return (
    <>
      <div
        data-active={state}
        className=" fixed left-0 top-0 z-[999] flex h-screen w-full items-start justify-center overflow-y-auto bg-purple-700/70 pt-10 data-[active=false]:hidden"
      >
        <div className="relative w-full max-w-sm rounded-[16px] bg-white py-8">
          <button
            onClick={() => setState((v) => !v)}
            className="absolute bg-white rounded-full -left-3 -top-3"
          >
            <BsX className="w-6 h-6" />
          </button>
          <MemberViewModal setState={setState} view={view} />
        </div>
      </div>
    </>
  );
};

const MemberViewModal = ({ setState, view }) => {
  const { data } = useFetcher();
  const { flashToast } = useContext(ToastContext);

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message);
    if (data?.success) {
      setState((v) => !v);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <h1 className="px-6 text-xl font-semibold text-left">New Member</h1>
      <div className="mt-5">
        <div className="w-full text-left">
          <div className="flex flex-col items-center justify-start w-full px-6 py-4 space-y-4">
            <div className="h-[72px] w-[72px] rounded-full border border-blue-500 bg-gray-500" />
            <div className="w-full text-left">
              <h3 className="text-[18px] font-[700] leading-[27px] text-[#4B5768]">
                Name
              </h3>
              <p className="text-[18px] font-[700] leading-[27px] text-[#0D0F11]">
                {view?.first_name} {view?.last_name}
              </p>
            </div>
            <div className="w-full text-left">
              <h3 className="text-[18px] font-[700] leading-[27px] text-[#4B5768]">
                Email
              </h3>
              <p className="text-[18px] font-[700] leading-[27px] text-[#0D0F11]">
                {view?.email}
              </p>
            </div>
            <div className="w-full text-left">
              <h3 className="text-[18px] font-[700] leading-[27px] text-[#4B5768]">
                Phone{" "}
              </h3>
              <p className="text-[18px] font-[700] leading-[27px] text-[#0D0F11]">
                {view?.phone_number}
              </p>
            </div>
            <div className="w-full text-left">
              <Status status={view?.active_status} />
            </div>
            <div className="flex items-center justify-end w-full">
              <button
                onClick={() => {
                  setState(false);
                }}
                className="px-6 py-3 text-sm font-semibold text-black border-2 rounded border-gray-400/50"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const EditMemberButton = ({ view }) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    if (view) {
      setState(true);
    }
  }, [view]);

  return (
    <>
      <div
        data-active={state}
        className=" fixed left-0 top-0 z-[999] flex h-screen w-full items-start justify-center overflow-y-auto bg-purple-700/70 pt-10 data-[active=false]:hidden"
      >
        <div className="relative w-full max-w-2xl rounded-[16px] bg-white py-8">
          <button
            onClick={() => setState((v) => !v)}
            className="absolute bg-white rounded-full -left-3 -top-3"
          >
            <BsX className="w-6 h-6" />
          </button>
          <MemberEditModal setState={setState} view={view} />
        </div>
      </div>
    </>
  );
};

const MemberEditModal = ({ setState, view }) => {
  const { Form, data, state } = useFetcher();
  const { flashToast } = useContext(ToastContext);
  const [states, setStates] = useState([]);

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message);
    if (data?.success) {
      setState((v) => !v);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <h1 className="px-6 text-xl font-semibold text-left">Approve Company</h1>
      <div className="mt-5">
        <div className="px-6 py-4 ">
          <Form method="PUT">
            <div className="grid grid-cols-2 gap-5">
              <Input
                normalize
                name="company_name"
                label="Company Name"
                defaultValue={view?.company_name}
                error={data?.errors?.company_name}
              />
              <Input
                normalize
                name="registration_number"
                label="Registration Number"
                defaultValue={view?.registration_number}
                error={data?.errors?.registration_number}
              />
              <Categories
                name="industry"
                label="Category/Product"
                defaultValue={view?.industry}
                error={data?.errors?.industry}
                defaultChecked={view?.industry}
              />
              <Input
                normalize
                name="email"
                label="Email Address"
                defaultValue={view?.email}
                error={data?.errors?.email}
              />
              <div className="col-span-full">
                <Input
                  normalize
                  name="contact_address"
                  label="Address"
                  defaultValue={view?.email}
                  error={data?.errors?.contact_address}
                />
              </div>
              <Input
                normalize
                name="contact_person_name"
                label="Contact Name"
                defaultValue={view?.contact_person_name}
                error={data?.errors?.contact_person_name}
              />
              <Input
                normalize
                name="contact_person_phone_number"
                label="Contact Phone Number"
                defaultValue={view?.contact_person_phone_number}
                error={data?.errors?.contact_person_phone_number}
              />
              <Countries
                setStates={setStates}
                label="Country"
                name="country"
                defaultValue={view?.country}
                placeholder={view?.country}
                error={data?.errors?.country}
                defaultChecked={view?.country}
              />
              <States
                states={states}
                label="State"
                name="state"
                error={data?.errors?.state}
                defaultValue={view?.state}
                placeholder={view?.state}
              />
              <Input
                normalize
                name="city"
                label="City"
                defaultValue={view?.city}
                error={data?.errors?.city}
              />
              <Input
                normalize
                name="website"
                label="Company Website"
                defaultValue={view?.website}
                error={data?.errors?.website}
              />
              <input
                type="hidden"
                name="token"
                onChange={() => {}}
                value={view?.company_token}
              />
              <input
                type="hidden"
                name="approval_status"
                onChange={() => {}}
                value={1}
              />
              <input
                type="hidden"
                name="status"
                value={1}
                onChange={() => {}}
              />
            </div>
            <div className="flex items-center justify-end w-full mt-2 space-x-2">
              <button
                onClick={() => {
                  setState((v) => !v);
                }}
                type="reset"
                className="min-h-[59px] w-full rounded-md border border-[#590DA4] px-4 py-3 text-base font-bold tracking-wider text-[#590DA4] hover:bg-purple-900 hover:text-white disabled:opacity-50"
              >
                Cancel
              </button>
              <Submit type="submit" isLoading={state !== "idle"}>
                Approved
              </Submit>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

const MemberCreateModal = ({ setState, view }) => {
  const { Form, data, state } = useFetcher();
  const { flashToast } = useContext(ToastContext);
  const [states, setStates] = useState([]);
  useEffect(() => {
    flashToast(data?.success ?? false, data?.message);
    if (data?.success) {
      setState((v) => false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <h1 className="px-6 text-xl font-semibold text-left">Add New Company</h1>
      <div className="mt-5">
        <div className="w-full text-left">
          <div className="px-6 py-4">
            <Form method="POST">
              <div className="grid grid-cols-2 gap-5">
                <Input
                  normalize
                  name="company_name"
                  label="Company Name"
                  defaultValue={view?.company_name}
                  error={data?.errors?.company_name}
                />
                <Input
                  normalize
                  name="registration_number"
                  label="Registration Number"
                  defaultValue={view?.registration_number}
                  error={data?.errors?.registration_number}
                />
                <Categories
                  name="industry"
                  label="Category/Product"
                  defaultValue={view?.industry}
                  error={data?.errors?.industry}
                />
                <Input
                  normalize
                  name="email"
                  label="Email Address"
                  defaultValue={view?.email}
                  error={data?.errors?.email}
                />
                <div className="col-span-full">
                  <Input
                    normalize
                    name="contact_address"
                    label="Address"
                    defaultValue={view?.email}
                    error={data?.errors?.contact_address}
                  />
                </div>
                <Input
                  normalize
                  name="contact_person_name"
                  label="Contact Name"
                  defaultValue={view?.contact_person_name}
                  error={data?.errors?.contact_person_name}
                />
                <Input
                  normalize
                  name="contact_person_phone_number"
                  label="Contact Phone Number"
                  defaultValue={view?.contact_person_phone_number}
                  error={data?.errors?.contact_person_phone_number}
                />
                <Countries
                  setStates={setStates}
                  label="Country"
                  name="country"
                  placeholder={view?.country}
                  defaultValue={view?.country}
                  error={data?.errors?.country}
                />
                <States
                  states={states}
                  label="State"
                  name="state"
                  placeholder={view?.state}
                  defaultValue={view?.state}
                  error={data?.errors?.state}
                />
                <Input
                  normalize
                  name="city"
                  label="City"
                  defaultValue={view?.city}
                  error={data?.errors?.city}
                />
                <Input
                  normalize
                  name="website"
                  label="Company Website"
                  defaultValue={view?.website}
                  error={data?.errors?.website}
                />
                <Input
                  normalize
                  name="salary_advance_interest_rate"
                  label="Salary Advance Interest Rate"
                  error={data?.errors?.salary_advance_interest_rate}
                />
              </div>
              <div className="mb-3">
                <h4 className="mb-2 capitalize text-gray-700 text-lg w-full flex items-center gap-x-2 font-semibold">Service Options</h4>
                <div className="space-y-4">
                  <div aria-label="anchor option" className="flex gap-x-3 items-center">
                    <input type="checkbox" name="anchor" value={`anchor`} className="checked:bg-fedacash-header w-4 h-4"/>
                    <p>Anchor</p>
                  </div>
                  <div aria-label="anchor option" className="flex gap-x-3 items-center">
                    <input type="checkbox" name="salary_advance" value={`salary_advance`} className="checked:bg-fedacash-header w-4 h-4"/>
                    <p>Salary Advance</p>
                  </div>                      
                </div>
              </div>
              <div className="flex items-center justify-end w-full mt-2 space-x-2">
                <button
                  onClick={() => {
                    setState((v) => !v);
                  }}
                  type="reset"
                  className="min-h-[59px] w-full rounded-md border border-[#590DA4] px-4 py-3 text-base font-bold tracking-wider text-[#590DA4] hover:bg-purple-900 hover:text-white disabled:opacity-50"
                >
                  Cancel
                </button>
                <Submit type="submit" isLoading={state !== "idle"}>
                  Submit
                </Submit>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export const Status = ({ status }) => {
  status = parseInt(status);
  const colors = [
    { color: "text-red-700", key: "Disabled", background: "bg-red-200/50" },
    { color: "text-green-700", key: "Active", background: "bg-green-200/50" },
  ];

  return (
    <span
      className={`${colors[status]?.color} capitalize ${colors[status]?.background} rounded-full px-3 py-1 text-xs `}
    >
      {colors[status]?.key}
    </span>
  );
};

export default CompanyIndex;
