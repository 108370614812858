import ResponseObject from "../../DataObjects/ResponseObject";
import http from "../../Store/baseHttp";

export const PaymentLoader = async () => {
  try {
    let response = await http.get("admin/request/payments");
    response = ResponseObject.from(response);
    console.log(response);
    return response;
  } catch (e) {
    return ResponseObject.from(e?.response);
  }
};
