import { Suspense } from "react";
import { Await } from "react-router-dom";

export const Table = ({ children }) => {
  return (
    <div>
      <table className="w-full table-auto overflow-scroll">{children}</table>
    </div>
  );
};

export const ColumnHeader = ({ children, className }) => {
  return (
    <th
      className={`h-[56px] whitespace-nowrap px-[25.5px] py-[17.5px] ${className}`}
    >
      {children}
    </th>
  );
};

export const RowHeader = ({ children }) => {
  return (
    <thead>
      <tr className="rounded-[8px] bg-[#f7f8f9] text-left text-[14px] font-[700] leading-[14px] text-[#0d0f11]">
        {children}
      </tr>
    </thead>
  );
};

export const Column = ({ children }) => {
  return (
    <td className="h-[56px] whitespace-normal px-[25.5px] py-[17.5px] font-[500]">
      {children}
    </td>
  );
};

export const Row = ({ children }) => {
  return (
    <tr className="whitespace-nowrap rounded-[8px] text-left text-[14px] font-[700] leading-[21px] text-[#323a46]">
      {children}
    </tr>
  );
};

export const Fallback = () => {
  return (
    <>
      {Array(5)
        .fill(5)
        .map((v, i) => (
          <tr
            key={i}
            className="animate-pulse rounded-[8px] text-left text-[14px] font-[700] leading-[21px] text-[#323a46] blur-lg"
          >
            <th className="h-[56px] appearance-none px-[25.5px] py-[17.5px] font-[500]" />
          </tr>
        ))}
    </>
  );
};

export const Body = ({ data, children }) => {
  return (
    <tbody className="divide-y-[1.5px]">
      <Suspense fallback={<Fallback />}>
        <Await
          resolve={data?.data}
          errorElement={
            <tr>
              <td>Error loading package location</td>
            </tr>
          }
        >
          {(data) => {
            return children(data);
          }}
        </Await>
      </Suspense>
    </tbody>
  );
};

const useTable = () => {
  return { Table, RowHeader, ColumnHeader, Row, Column, Body };
};

export default useTable;
