import DashboardItem from "./DashboardItem";
import { BsClock, BsFile, BsWallet, BsX } from "react-icons/bs";
import { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";

const Dashboard = () => {
  const data = useLoaderData();

  return (
    <Suspense>
      <Await resolve={data?.data}>
        {(data) => {
          return (
            <div className="mb-10 grid w-full grid-cols-2 content-around gap-10 md:grid-cols-4 ">
              <DashboardItem
                icon={BsWallet}
                iconColor="bg-[#590DA4]"
                comment="Total amount received"
                amount={data?.data?.totalAmount}
                isCurrency
                interval={5}
                increment={10000}
                options={{ style: "currency", currency: "NGN" }}
              />
              <DashboardItem
                icon={BsFile}
                iconColor="bg-[#88C02C]"
                comment="Requests"
                amount={data?.data?.totalRequests}
              />
              <DashboardItem
                icon={BsClock}
                iconColor="bg-[#FBBF24]"
                comment="Pending Invoice"
                amount={data?.data?.pendingInvoice}
              />
              <DashboardItem
                icon={BsX}
                iconColor="bg-[#EF4444]"
                comment="Rejected Invoice"
                amount={data?.data?.rejectedInvoice}
              />
            </div>
          );
        }}
      </Await>
    </Suspense>
  );
};

export default Dashboard;
