import { useLoaderData, useLocation, useSearchParams } from "react-router-dom";

import PageHeader from "../../../Components/Dashboard/PageHeader";
import usePagination from "../../../Components/Dashboard/Table/usePagination";
import {
  Body,
  Column,
  ColumnHeader,
  Row,
  RowHeader,
  Table,
} from "../../../Components/Dashboard/Table/useTable";
import { formatCurrency } from "../../../Components/FormatMoney";
import moment from "moment";
import Panel from "../../../Components/Dashboard/Panel";
import { BiSearch } from "react-icons/bi";

const GeneratedInvoiceIndex = () => {
  const [, setSearchParams] = useSearchParams({ dateFrom: "", dateTo: "" });
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const searchAction = (e) => {
    const s = Object.fromEntries(search);
    setSearchParams({ ...s, search: e.target.value });
  };
  return (
    <div>
      <PageHeader>Third Party Invoices</PageHeader>
      <div className="mt-4 flex h-[40px] w-full items-center justify-start rounded-[8px] bg-[#e7eaee] md:max-w-[291.83px]">
        <BiSearch className="ml-[15px] mr-[6.5px] h-[15px] w-[15px] flex-none border-none" />
        <input
          type="text"
          placeholder="search"
          className="h-full w-full flex-1 appearance-none border-none bg-transparent text-[14px] font-[500] leading-[21px] placeholder:text-[#64748b] focus:border-transparent focus:outline-none focus:ring-transparent"
          onChange={searchAction}
        />
      </div>
      <div className="mt-4">
        <InvoiceTable />
      </div>
    </div>
  );
};

export default GeneratedInvoiceIndex;

function InvoiceTable() {
  const data = useLoaderData();
  const InvoiceArray = data?.InvoiceRequests?.data;
  const { Next, Prev, Button, Pagination, setPage, PageSize } = usePagination();
  if (!InvoiceArray?.data) {
    return <div>Error Loading Table</div>;
  }
  if (InvoiceArray?.data?.length < 1) {
    return <div>No data Available</div>;
  }
  //leave this here incase the  date in the function breaks use this
  function parseManually(dateString) {
    const [day, month, year] = dateString.split("-").map(Number);
    const parsed = moment({ year, month: month - 1, day });
    console.log(`Parsed valid: ${parsed.isValid()}`);
    console.log(`Formatted: ${parsed.format("dddd, Do MMMM YYYY: h:mmA")}`);
    return parsed.format("dddd, Do MMMM YYYY: h:mmA");
  }
  return (
    <Panel>
      <Table>
        <RowHeader>
          <ColumnHeader>No.</ColumnHeader>
          <ColumnHeader>Company</ColumnHeader>
          <ColumnHeader>Sender Name</ColumnHeader>
          <ColumnHeader>Date Created</ColumnHeader>
          <ColumnHeader>Issue Date</ColumnHeader>
          <ColumnHeader>Due Date</ColumnHeader>
          <ColumnHeader>Amount</ColumnHeader>
        </RowHeader>
        <Body data={InvoiceArray}>
          {(data) => {
            return (
              <>
                {data?.map((item, index) => (
                  <Row key={item.id}>
                    <Column>{index + 1}</Column>
                    <Column>{item.company_name}</Column>
                    <Column>{item?.organization?.name || ""}</Column>
                    <Column>
                      {moment(item.created_at).format(
                        "dddd, Do MMMM YYYY: h:mmA"
                      )}
                    </Column>
                    <Column>
                      {moment(item.issue_date, "DD-MM-YYYY").format(
                        "dddd, Do MMMM YYYY: h:mmA"
                      )}
                    </Column>
                    <Column>
                      {moment(item.due_date, "DD-MM-YYYY").format(
                        "dddd, Do MMMM YYYY: h:mmA"
                      )}
                    </Column>
                    <Column>
                      {formatCurrency(item?.total, item?.currency)}
                    </Column>
                  </Row>
                ))}
              </>
            );
          }}
        </Body>
      </Table>
      <div className="mt-2 space-y-4">
        <span>
          Showing {InvoiceArray?.from} to {InvoiceArray?.to} of{" "}
          {InvoiceArray?.total} entries
        </span>
        <div className="mt-2 flex items-center justify-between">
          <PageSize numbers={[5, 10, 20, 50]} />
          <Pagination>
            <>
              <Prev
                onClick={() => {
                  setPage(1);
                }}
              />
              {Array(InvoiceArray?.last_page)
                .fill(5)
                .map((v, i) => {
                  return (
                    <Button
                      key={i}
                      isActive={InvoiceArray?.current_page === i + 1}
                      onClick={() => {
                        setPage(i + 1);
                      }}
                    >
                      {i + 1}
                    </Button>
                  );
                })}
              <Next
                onClick={() => {
                  setPage(InvoiceArray?.last_page);
                }}
              />
            </>
          </Pagination>
        </div>
      </div>
    </Panel>
  );
}
