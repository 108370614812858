import { useLoaderData, useParams } from "react-router-dom";
import moment from "moment";
import Panel from "../../../Components/Dashboard/Panel";
import useTitle from "../../../Hooks/useTitle";
import useTable from "../../../Components/Dashboard/Table/useTable";
import PaginateAll from "../../../Components/Utils/PaginateAll";
import NavigatorItem from "./NavigatorItem";
import Navigator from "./Navigator";

const AuditLogs = () => {
  useTitle("Audit Logs");
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
  const items = useLoaderData();
  const { id } = useParams();

  return (
    <>
      <Navigator>
        <NavigatorItem
          to={`/dashboard/customers/${id}`}
          label="Business Details"
          value={1}
        />
        <NavigatorItem
          to={`/dashboard/customers/${id}/approval`}
          label="KYC Document"
          value={2}
        />
        <NavigatorItem
          to={`/dashboard/customers/${id}/audit-logs`}
          label="Audit Logs"
          value={3}
        />
      </Navigator>
      <Panel>
        <Table>
          <RowHeader>
            <ColumnHeader>Name</ColumnHeader>
            <ColumnHeader>Email Address</ColumnHeader>
            <ColumnHeader>Type</ColumnHeader>
            <ColumnHeader>Action</ColumnHeader>
            <ColumnHeader>Timestamp</ColumnHeader>
          </RowHeader>
          <Body data={items}>
            {(data) =>
              data?.data?.data?.map((v, i) => (
                <Row key={i}>
                  <Column>
                    {v?.user?.first_name} {v?.user?.last_name}
                  </Column>
                  <Column>{v?.user?.email}</Column>
                  <Column>{v.event_type}</Column>
                  <Column>{v.activity}</Column>
                  <Column>{moment(v.created_at).format("llll")}</Column>
                </Row>
              ))
            }
          </Body>
        </Table>
      </Panel>
      <PaginateAll items={items} />
    </>
  );
};

export default AuditLogs;
