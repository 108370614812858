import { FiMenu } from "react-icons/fi";
import NotificationIcon from "./Notification/NotificationIcon";
import Profile from "./Profile";

const TopBar = ({ click }) => {
  return (
    <nav
      className="relative h-[80px] w-full flex-none bg-white shadow"
      style={{ borderBottom: "1.5px solid #E7EAEE" }}
    >
      <div className="flex h-full w-full items-center justify-between px-[20px] md:pl-20">
        <button onClick={click} className="lg:hidden">
          <FiMenu className="w-6 h-6" />
        </button>
        <div className="hidden md:block">
          {/* <div
            className={`max-w-[291.83px] w-full h-[40px] bg-[#e7eaee] rounded-[8px] flex items-center justify-start ${!value ? "" : "hidden lg:flex"
              }`}
          >
            <BiSearch className="w-[15px] h-[15px] flex-none border-none ml-[15px] mr-[6.5px]" />
            <input
              type="text"
              placeholder="search"
              className="flex-1 focus:ring-transparent bg-transparent h-full w-full border-none focus:outline-none focus:border-transparent appearance-none placeholder:text-[#64748b] font-[500] leading-[21px] text-[14px]"
              onChange={(e) => setSearchParams({ search: e.target.value })}
            />
          </div> */}
        </div>
        <div className="flex items-center justify-end">
          <NotificationIcon />
          <Profile />
        </div>
      </div>
    </nav>
  );
};

export default TopBar;
