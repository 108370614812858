import { Dropdown } from "antd";
import { BsThreeDots } from "react-icons/bs";
import moment from "moment/moment";

import Panel from "./Panel";
import {
  Body,
  Column,
  ColumnHeader,
  Row,
  RowHeader,
  Table,
} from "./Loan/Table";
import { Await, Link, useLoaderData } from "react-router-dom";
import { formatNaira } from "./../FormatMoney";
import usePagination from "./Table/usePagination";
import { Suspense } from "react";

export const LoanTable = () => {
  const data = useLoaderData();
  const { Next, Prev, Button, Pagination, setPage, PageSize, Description } =
    usePagination();

  return (
    <section>
      <div className="my-8 flex justify-between">
        <h2 className="text-xl font-semibold">Loan Table</h2>
      </div>
      <Suspense>
        <Await resolve={data?.data}>
          {(data) => {
            const LoanData = data?.LoanTransactions?.data?.data?.data;
            return (
              <Panel>
                <Table>
                  <RowHeader>
                    <ColumnHeader>description</ColumnHeader>
                    <ColumnHeader>Date Created</ColumnHeader>
                    <ColumnHeader>Amount</ColumnHeader>
                    <ColumnHeader>Status</ColumnHeader>
                    <ColumnHeader>Reference</ColumnHeader>
                  </RowHeader>
                  {LoanData.length < 1 ? (
                    <div>No data Available</div>
                  ) : (
                    <Body data={LoanData}>
                      {(data) =>
                        data.map((item) => (
                          <Row key={item.id}>
                            <Column>{item?.transaction_description}</Column>
                            <Column>
                              {" "}
                              {moment(item.created_at).format(
                                "dddd, Do MMMM YYYY: h:mmA"
                              )}
                            </Column>
                            <Column>
                              {formatNaira(item?.amount)}
                            </Column>
                            <Column>
                              <span classname="text-xs text-green-600 px-2 py-1 rounded-md bg-gray-50">
                                {item.status}
                              </span>
                            </Column>
                            <Column>{item?.reference}</Column>
                          </Row>
                        ))
                      }
                    </Body>
                  )}
                </Table>
                <div className="mt-2 flex items-center justify-between">
                  <PageSize numbers={[5, 10, 20, 50]} />
                  <Pagination>
                    <>
                      <Prev
                        onClick={() => {
                          setPage(1);
                        }}
                      />
                      {Array(data?.loanrequests?.data?.last_page)
                        .fill(5)
                        .map((v, i) => {
                          return (
                            <Button
                              key={i}
                              isActive={
                                data?.loanrequests?.data?.current_page === i + 1
                              }
                              onClick={() => {
                                setPage(i + 1);
                              }}
                            >
                              {i + 1}
                            </Button>
                          );
                        })}
                      <Next
                        onClick={() => {
                          setPage(data?.loanrequests?.data?.last_page);
                        }}
                      />
                    </>
                  </Pagination>
                </div>
              </Panel>
            );
          }}
        </Await>
      </Suspense>
    </section>
  );
};

export const LoanProfitTable = () => {
  const data = useLoaderData();
  const { Next, Prev, Button, Pagination, setPage, PageSize, Description } =
    usePagination();
  return (
    <section>
      <div className="my-8 flex justify-between">
        <h2 className="text-xl font-semibold">Loan Profit Table</h2>
      </div>
      <Suspense>
        <Await resolve={data?.data}>
          {(data) => {
            const LoanData = data?.LoanStats?.data?.data?.loanProfitList?.data;
            return (
              <Panel>
                <Table>
                  <RowHeader>
                    <ColumnHeader>description</ColumnHeader>
                    <ColumnHeader>Date Created</ColumnHeader>
                    <ColumnHeader>Amount</ColumnHeader>
                    <ColumnHeader>Status</ColumnHeader>
                    <ColumnHeader>Reference</ColumnHeader>
                  </RowHeader>
                  {LoanData.length < 1 ? (
                    <div>No data Available</div>
                  ) : (
                    <Body data={LoanData}>
                      {(data) =>
                        data.map((item) => (
                          <Row key={item.id}>
                            <Column>{item?.transaction_description}</Column>
                            <Column>
                              {" "}
                              {moment(item.created_at).format(
                                "dddd, Do MMMM YYYY: h:mmA"
                              )}
                            </Column>
                            <Column>
                              {formatNaira(item?.amount)}
                            </Column>
                            <Column>
                              <span classname="text-xs text-green-600 px-2 py-1 rounded-md bg-gray-50">
                                {item.status}
                              </span>
                            </Column>
                            <Column>{item?.reference}</Column>
                          </Row>
                        ))
                      }
                    </Body>
                  )}
                </Table>
                <div className="mt-2 flex items-center justify-between">
                  <PageSize numbers={[5, 10, 20, 50]} />
                  <Pagination>
                    <>
                      <Prev
                        onClick={() => {
                          setPage(1);
                        }}
                      />
                      {Array(data?.loanrequests?.data?.last_page)
                        .fill(5)
                        .map((v, i) => {
                          return (
                            <Button
                              key={i}
                              isActive={
                                data?.loanrequests?.data?.current_page === i + 1
                              }
                              onClick={() => {
                                setPage(i + 1);
                              }}
                            >
                              {i + 1}
                            </Button>
                          );
                        })}
                      <Next
                        onClick={() => {
                          setPage(data?.loanrequests?.data?.last_page);
                        }}
                      />
                    </>
                  </Pagination>
                </div>
              </Panel>
            );
          }}
        </Await>
      </Suspense>
    </section>
  );
};