import { Tab } from "@headlessui/react";
import { useLoaderData } from "react-router-dom";

////////////////////

import LoanRepayment from "../../../Components/Dashboard/Loan/LoanRepayment";
import LoanDibursed from "../../../Components/Dashboard/Loan/LoanDisbursed";
import LoanPayment from "../../../Components/Dashboard/Loan/LoanPayment";

const LoanIndex = () => {
  return (
    <div>
      <LoanTab />
    </div>
  );
};

export default LoanIndex;

const LoanTab = () => {
  return (
    <Tab.Group>
      <Tab.List
        className={`flex h-16 w-full justify-evenly border bg-white text-xs font-bold md:text-base my-8`}
      >
        <Tab
          className={`ui-selected:border-fedacash-header w-1/2 ui-selected:border-b-2`}
        >
          Loan Request
        </Tab>
        <Tab
          className={`ui-selected:border-fedacash-header w-1/2 ui-selected:border-b-2`}
        >
          Loan Disbursed
        </Tab>
        <Tab
          className={`ui-selected:border-fedacash-header w-1/2 ui-selected:border-b-2`}
        >
          Loan Repayment
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel className={`overview`}>
          <section className="">
            <LoanRepayment/>
          </section>
        </Tab.Panel>
        <Tab.Panel>
          <section>
            <LoanDibursed />
          </section>
        </Tab.Panel>
        <Tab.Panel>
          <section>
            <LoanPayment/>
          </section>
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};
