import BaseController from "./BaseController";
import http from "../../Store/baseHttp";
import ResponseObject from "../../DataObjects/ResponseObject";

class CooperativeService extends BaseController {
  async index({ request }) {
    const url = new URL(request.url);
    url.searchParams.append("sort_type", "DESC");
    url.searchParams.append("sort_by", "id");
    try {
      let response = await http.get(
        "/admin/cooperative/packages/list" + url.search
      );
      let transactionlist = await http.get("/admin/transactions" + url.search);
      let balance = await http.get("/admin/paystack/cooperative/total");
      response = ResponseObject.from(response);
      transactionlist = ResponseObject.from(transactionlist);
      balance = ResponseObject.from(balance);
      return { response, transactionlist, balance };
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async post({ request }) {
    const formData = Object.fromEntries(await request.formData());
    const form = new FormData();
    Object.keys(formData).forEach((value, key) => {
      form.append(value, formData[value]);
    });

    try {
      let response = await http.post("/admin/cooperative/packages", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async put({ request }) {
    const formData = Object.fromEntries(await request.formData());
    const form = new FormData();
    Object.keys(formData).forEach((value, key) => {
      form.append(value, formData[value]);
    });

    try {
      let response = await http.post(
        "/admin/cooperative/packages/edit/" + formData.id,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }
}

export default CooperativeService;
export async function SaveandPackLoader({request, params}) {
  const url = new URL(request.url);
  url.searchParams.append("sort_type", "DESC");
  url.searchParams.append("sort_by", "id");
  try {
    let response = await http.get(
      "/admin/cooperative/packages/list" + url.search
    );
    let transactionlist = await http.get("/admin/transactions" + url.search);
    let balance = await http.get("/admin/paystack/cooperative/total");
    response = ResponseObject.from(response);
    transactionlist = ResponseObject.from(transactionlist);
    balance = ResponseObject.from(balance);
    return { response, transactionlist, balance };
  } catch (e) {
    return ResponseObject.from(e?.response);
  }
}
export async function deletePlan(token) {
  try {
    let deleteRequest = await http.delete(
      `/admin/cooperative/packages/delete/${token}`
    );
    deleteRequest = ResponseObject.from(deleteRequest);
    return deleteRequest;
  } catch (error) {
    console.log(error);
  }
}

export async function disableSubscription(token) {
  try {
    let disableSub = await http.post(
      `/admin/cooperative/packages/disable/subscriptions`,
      {
        package_token: token,
      }
    );
    disableSub = ResponseObject.from(disableSub);
    return disableSub;
  } catch (error) {
    console.log(error);
  }
}

export async function enableSubscription(token) {
  try {
    let enableSub = await http.post(
      "/cooperative/saveandgo/enable/subscriptions",
      {
        plan: "p10k3XnHcDNagEUD",
      }
    );
    enableSub = ResponseObject.from(enableSub);
    return enableSub;
  } catch (error) {
    console.log(error);
  }
}
