import InvoiceInput from './InvoiceInput'
import { useEffect, useState } from 'react'
import GeneralService from '../../../Services/Dashboard/GeneralService'

const InvoiceNumber = ({ setInvoiceNumber }) => {
  const [state, setState] = useState(0)

  useEffect(() => {
    async function fetchData () {
      const response = await GeneralService.invoiceNumber()
      if (response.success) {
        const id = parseInt(response?.data?.data[0]?.invoice_number ?? 0) + 1 ?? '0000001'
        setState(id)
        setInvoiceNumber(id)
      }
    }

    fetchData()
  })

  return (
    <>
      <InvoiceInput
        type='text' placeholder='Invoice Number' name='invoice_number_format' options={{
          numeral: true,
          prefix: '#',
          rawValueTrimPrefix: true,
          numeralDecimalScale: 0,
          delimiter: ''
        }}
        value={state}
      />
      <input type='hidden' name='invoice_number' defaultValue={state} />
    </>
  )
}

export default InvoiceNumber
