import Panel from "../../../Components/Dashboard/Panel";
import { Await, useLoaderData, useParams } from "react-router-dom";
import { Suspense } from "react";
import NavigatorItem from "./NavigatorItem";
import Navigator from "./Navigator";
import { formatAsNaira } from "../../../Components/FormatMoney";

const CustomerView = () => {
  const data = useLoaderData();
  const { id } = useParams();

  return (
    <div>
      <Navigator>
        <NavigatorItem
          to={`/dashboard/customers/${id}`}
          label="Business Details"
          value={1}
        />
        <NavigatorItem
          to={`/dashboard/customers/${id}/approval`}
          label="KYC Document"
          value={2}
        />
        <NavigatorItem
          to={`/dashboard/customers/${id}/audit-logs`}
          label="Audit Logs"
          value={3}
        />
      </Navigator>
      <Suspense fallback={<p>Loading...</p>}>
        <Await resolve={data}>
          {(data) => {
            const item = data?.data;
            return (
              <div className="mt-5">
                <Panel>
                  <div className="flex w-full flex-col items-start justify-start divide-y divide-gray-500/50">
                    <div className="grid w-full grid-cols-1 gap-5 p-[40px] md:grid-cols-5">
                      <div className="col-span-2">
                        <h1 className="text-[24px] font-[900] leading-[27px]">
                          Business Details
                        </h1>
                        <p className="text-[14px] font-[400] leading-[21px]">
                          Basic Information About Your business
                        </p>
                      </div>
                      <div className="col-span-3 grid grid-cols-2 gap-y-[24px] gap-x-[78px]">
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Business Name
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.name}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Category Of Business
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.category}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            State
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.state}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            City
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.city}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Country of Operation
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.country}
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Address
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.address}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="grid w-full grid-cols-1 gap-5 p-[40px] md:grid-cols-5">
                      <div className="col-span-2">
                        <h1 className="text-[24px] font-[900] leading-[27px]">
                          Business Details
                        </h1>
                        <p className="text-[14px] font-[400] leading-[21px]">
                          Basic Information About Your business
                        </p>
                      </div>
                      <div className="col-span-3 flex flex-col items-start justify-start gap-y-[24px] gap-x-[78px]">
                        <div className="p- flex h-[88px] w-full max-w-[378px] rounded-[16px] border-[1.5px]  border-[#E7EAEE] p-[12px]">
                          <div className="aspect-square h-full flex-none rounded-[16px] bg-gray-500" />
                          <div className="ml-[12px]">
                          <p className="text-xl font-bold leading-[30px]">
                              Bank Details
                            </p>
                            <p className="">
                              {item?.account_number ?? "N/A"}
                            </p>
                            <p className="">
                              {item?.bank_name ?? "N/A"}
                            </p>
                          </div>
                        </div>
                        <div className="p- flex h-[88px] w-full max-w-[378px] rounded-[16px] border-[1.5px]  border-[#E7EAEE] p-[12px]">
                          <div className="aspect-square h-full flex-none rounded-[16px] bg-gray-500" />
                          <div className="ml-[12px]">
                            <p className="text-[20px] font-[700] leading-[30px]">
                           {formatAsNaira(item?.creator?.paystack_wallet_total)}
                            </p>
                            <p className="text-[16px] font-[400] leading-[24px]">
                              Wallet Balance
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid w-full grid-cols-1 gap-5 p-[40px] md:grid-cols-5">
                      <div className="col-span-2">
                        <h1 className="text-[24px] font-[900] leading-[27px]">
                          Business Details
                        </h1>
                        <p className="text-[14px] font-[400] leading-[21px]">
                          Basic Information About Your business
                        </p>
                      </div>
                      <div className="col-span-3 grid grid-cols-2 gap-y-[24px] gap-x-[78px]">
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Member of Cooperative
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            N/A
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Rejected Invoice
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.rejected_invoice ?? 0}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Approved Invoice
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.approved_invoice ?? 0}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Total Number of Invoice Request
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.total_request ?? 0}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>
              </div>
            );
          }}
        </Await>
      </Suspense>
    </div>
  );
};

export default CustomerView;
