import { Link } from 'react-router-dom'
import Logo from '../../Assets/logo/Logo.png'

export default function HeaderLogo () {
  return (
    <Link to='/'>
      <img
        src={Logo}
        style={{ height: '48px', marginTop: '50px' }}
        alt='fedacash Log'
        className='block lg:hidden'
      />
    </Link>
  )
}
