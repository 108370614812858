import ResponseObject from "../../DataObjects/ResponseObject";
import http from "../../Store/baseHttp";

export async function LoanRequestLoader({ request, params }) {
  const url = new URL(request.url);

  try {
    let loanrequests = await http.get("/admin/loan/requests" + url.search);
    let repaidRequests = await http.get(
      "/admin/loan/repaid/list"
    );
    let paidRequests = await http.get("/admin/loan/paid/requests" + url.search);
    loanrequests = ResponseObject.from(loanrequests);
    paidRequests = ResponseObject.from(paidRequests);
    repaidRequests = ResponseObject.from(repaidRequests);
    // console.log(paidRequests);
    return { loanrequests, paidRequests, repaidRequests};
  } catch (e) {
    return ResponseObject.from(e?.response);
  }
}

export async function SingleLoanRequestLoader({ request, params }) {
  try {
    let loanrequest = await http.get(
      `/admin/loan/request/details/${params.id}`
    );
    loanrequest = ResponseObject.from(loanrequest);
    return { loanrequest };
  } catch (e) {
    return ResponseObject.from(e?.response);
  }
}
export async function LoanTransactionLoader({ request, params }) {
  const url = new URL(request.url);

  try {
    let loantransactions = await http.get(
      `/admin/loan/transactions` + url.search
    );
    loantransactions = ResponseObject.from(loantransactions);
    return { loantransactions };
  } catch (e) {
    return ResponseObject.from(e?.response);
  }
}
export async function UpdateLoanRequest(data) {
  // console.log(data);
  try {
    let loanrequest = await http.post(`/admin/loan/create/recipient`, data);
    loanrequest = ResponseObject.from(loanrequest);
    return { loanrequest };
  } catch (e) {
    return ResponseObject.from(e?.response);
  }
}
export async function ReviewLoanRequest(data) {
  try {
    let loanrequest = await http.put(`/admin/loan/update/request`, {
      request_status: data.status,
      loan_token: data.token,
      reason: data.reason,
      set_approval_amount: data.set_approval_amount
    });
    loanrequest = ResponseObject.from(loanrequest);
    return loanrequest;
  } catch (e) {
    return ResponseObject.from(e?.response);
  }
}
export async function CompleteLoanRequestPayment(loan_token) {
  try {
    let paymentRequest = await http.post(`/admin/loan/complete/payment`, {
      loan_token: loan_token,
    });
    paymentRequest = ResponseObject.from(paymentRequest);
    return paymentRequest;
  } catch (e) {
    return ResponseObject.from(e?.response);
  }
}
