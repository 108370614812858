import { useLoaderData } from "react-router-dom";
////file
import usePagination from "../Table/usePagination";
import useTable from "../Table/useTable";
import Panel from "../Panel";
import { formatAsNaira } from "../../FormatMoney";
import moment from "moment";
import { Link } from "react-router-dom";


export const SaveandGoContributionTable = () => {
    const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
    const { Next, Prev, Button, Pagination, setPage, PageSize, Description } =
      usePagination();
    const transactions = useLoaderData();
    function formatAsNaira(number) {
      if (number === null || number === undefined) {
        return "N/A";
      }
  
      const num = Number(number);
  
      if (isNaN(num)) {
        return "N/A";
      }
  
      const formatter = new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: "NGN",
      });
  
      return formatter.format(num);
    }
    const PAGINATION_DATA = transactions?.transactionlist?.data;
    return (
      <Panel className={`mt-4`}>
        <Table>
          <RowHeader>
            <ColumnHeader>Amount</ColumnHeader>
            <ColumnHeader>Transaction Date</ColumnHeader>
            <ColumnHeader>Plan</ColumnHeader>
            <ColumnHeader>Name</ColumnHeader>
          </RowHeader>
          <tbody>
            {transactions?.transactionlist?.data?.data.map(
              (transaction, index) => (
                <Row key={index}>
                  <Column>{formatAsNaira(transaction.amount_paid)}</Column>
                  <Column>
                    {moment(transaction.transaction_date).format("DD-MM-YYYY")}
                  </Column>
                  <Column>{transaction?.package_name ?? "N/A"}</Column>
                  <Column>
                    <span className="whitespace-nowrap">
                      {transaction?.customer_fullname}
                    </span>
                  </Column>
                </Row>
              )
            )}
          </tbody>
        </Table>
        <div className="mt-4">
          <div className="mb-4 flex items-center gap-x-4">
            <span>
              Showing {PAGINATION_DATA?.from} to {PAGINATION_DATA?.to} of{" "}
              {PAGINATION_DATA?.total} entries
            </span>
            <PageSize numbers={[5, 10, 20, 50]} />
          </div>
  
          <Pagination>
            <>
              <Prev
                onClick={() => {
                  setPage(1);
                }}
              />
              {Array(PAGINATION_DATA?.last_page)
                .fill(5)
                .map((v, i) => {
                  return (
                    <Button
                      key={i}
                      isActive={PAGINATION_DATA?.current_page === i + 1}
                      onClick={() => {
                        setPage(i + 1);
                      }}
                    >
                      {i + 1}
                    </Button>
                  );
                })}
              <Next
                onClick={() => {
                  setPage(PAGINATION_DATA?.last_page);
                }}
              />
            </>
          </Pagination>
        </div>
      </Panel>
    );
  };
  