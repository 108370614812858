import PageHeader from "../../../Components/Dashboard/PageHeader";
import useTitle from "../../../Hooks/useTitle";
import Panel from "../../../Components/Dashboard/Panel";
import Document from "../../../Assets/dashboard/Document.svg";
import Upload from "../../../Assets/dashboard/Upload.svg";

import MoneyFormat from "../../../Components/Utils/MoneyFormat";
import Input from "../../../Components/Forms/Input";
import TextArea from "../../../Components/Forms/TextArea";
import Select from "../../../Components/Forms/Select";
import Submit from "../../../Components/Forms/Submit";
import ToastContext from "../../../Routes/ToastContext";
import walletimage from "../../../Assets/dashboard/EmptyStatewallet.svg";

//node imports
import { Await, useFetcher, useLoaderData } from "react-router-dom";
import useTable from "../../../Components/Dashboard/Table/useTable";
import { Dropdown, Popconfirm } from "antd";
import { FiEdit3 } from "react-icons/fi";
import { MdCancel } from "react-icons/md";
import { BsFillTrashFill, BsPlus, BsX } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";

import { Suspense, useContext, useEffect, useState, Fragment } from "react";
import {
  AiOutlineArrowDown,
  AiOutlineClose,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { Tab, Dialog, Transition, Menu } from "@headlessui/react";
import moment from "moment";
import {
  deletePlan,
  disableSubscription,
  enableSubscription,
} from "../../../Services/Dashboard/CooperativeService";
import usePagination from "../../../Components/Dashboard/Table/usePagination";

const SaveandPack = () => {
  useTitle("Cooperative");
  const { Next, Prev, Button, Pagination, setPage, PageSize, Description } =
    usePagination();
  const initialValues = {
    logo: "",
    title: "",
    minimum_saving: "",
    total_savings_amount: "",
    amount_to_contribute: "",
    duration: "",
    commission_fee: "",
    size_of_savings_group: "",
    contribution_frequency: "",
    more_information: "",
    open: false,
    mode: "",
  };

  const [cooperate, setCooperate] = useState(initialValues);
  const plans = useLoaderData();
  // console.log(plans);
  //   const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
  const [plantitle, setPlantitle] = useState("");
  const [isViewOpen, setIsViewOpen] = useState(false);

  function closeViewModal() {
    setIsViewOpen(false);
  }
  function openViewModal() {
    setIsViewOpen(true);
  }
  return (
    <div>
      <div>
        <PageHeader>Save and Pack</PageHeader>
        <p className="font-[400] leading-[24px] text-[#64748B] text-[px]">
          {new Date().toDateString()}
        </p>
      </div>

      <div className="mt-[23.5px] ">
        <Suspense fallback={<div>Loading...</div>}>
          <Await resolve={plans?.data}>
            {(data) => (
              <section className="space-y-6">
                <Wallet balance={data.balance} />
                <Tab.Group>
                  {/* {console.log(data)} */}
                  <Tab.List className="flex h-12 w-full justify-evenly border bg-white text-xs font-bold md:text-base ">
                    <Tab className="w-1/2 ui-selected:border-b-2 ui-selected:border-fedacash-header">
                      Overview
                    </Tab>
                    <Tab className="w-1/2 ui-selected:border-b-2 ui-selected:border-fedacash-header">
                      Transaction History
                    </Tab>
                  </Tab.List>
                  <Tab.Panels className="mt-4">
                    <Tab.Panel>
                      <div>
                        <div className="grid w-full grid-cols-1 gap-[26px]  xl:grid-cols-3">
                          {data?.response?.data?.data?.map((plan, index) => (
                            <div key={index}>
                              <div>
                                <CooperativePanel
                                  setplan={setCooperate}
                                  plan={plan}
                                  openViewModal={openViewModal}
                                  initialValues={initialValues}
                                  setPlantitle={setPlantitle}
                                />
                              </div>
                            </div>
                          ))}
                          <CooperativeButton
                            onClick={() => {
                              setCooperate((v) => ({
                                ...v,
                                ...initialValues,
                                open: true,
                                mode: "create",
                              }));
                            }}
                          ></CooperativeButton>
                        </div>
                        <div className="mt-4">
                          <Pagination>
                            <Suspense fallback={<p />}>
                              <Await
                                resolve={plans?.data}
                                errorElement={<span>Loading</span>}
                              >
                                {(plan) => {
                                  return (
                                    <>
                                      <Prev
                                        onClick={() => {
                                          setPage(1);
                                        }}
                                      />
                                      {Array(plan?.response?.data?.last_page)
                                        .fill(5)
                                        .map((v, i) => {
                                          return (
                                            <Button
                                              key={i}
                                              isActive={
                                                plan?.response?.data
                                                  ?.current_page ===
                                                i + 1
                                              }
                                              onClick={() => {
                                                setPage(i + 1);
                                              }}
                                            >
                                              {i + 1}
                                            </Button>
                                          );
                                        })}
                                      <Next
                                        onClick={() => {
                                          setPage(
                                            plan?.response?.data?.last_page
                                          );
                                        }}
                                      />
                                    </>
                                  );
                                }}
                              </Await>
                            </Suspense>
                          </Pagination>
                        </div>
                      </div>
                    </Tab.Panel>

                    <Tab.Panel>
                      <ContributionTable />
                    </Tab.Panel>
                    <Tab.Panel>Content 3</Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </section>
            )}
          </Await>
        </Suspense>
      </div>
      <CooperativeModal
        plan={cooperate}
        setplan={setCooperate}
        initialValues={initialValues}
      />
      <ViewPlanModal
        isViewOpen={isViewOpen}
        closeModal={closeViewModal}
        plantitle={plantitle}
      />
    </div>
  );
};

const CooperativePanel = ({ plan, setplan, openViewModal, setPlantitle }) => {
  const [responseData, setResponseData] = useState(null);
  const { flashToast } = useContext(ToastContext);

  // console.log(plan.token);
  // Define an async function expression that runs the delete function, takes the return value and adds it to a state
  const onClickHandler = async (token) => {
    // Call the delete function with the token and await for the return value
    const deleteRequest = await deletePlan(token);
    // Use the setter function to update the state with the return value
    // console.log(deleteRequest);
    setResponseData(deleteRequest);
  };
  const disablePlan = async (token) => {
    // Call the delete function with the token and await for the return value
    const disableRequest = await disableSubscription(token);
    // Use the setter function to update the state with the return value
    // console.log('disableRequest',disableRequest);
    flashToast(true, disableRequest?.message);
  };
  const enablePlan = async (token) => {
    const enableRequest = await enableSubscription(token);
    console.log("disableRequest", enableRequest);
    flashToast(true, enableRequest?.message);
  };

  return (
    <Panel>
      <div className="plans-center ml-[26px] flex h-[145px] justify-start">
        <div className="mr-[24px] aspect-square h-[56px] w-[56px] rounded-full bg-[#590DA4] p-[12px] tracking-[-2%]">
          <img
            src={plan?.logo_path ?? Document}
            className="h-full w-full"
            alt="document"
          />
        </div>
        <div>
          <h3 className="text-xl font-medium capitalize leading-[32.28px] text-[#0D0F11]">
            {plan?.title}
          </h3>
          <p className="mb-2 mt-1 font-medium text-gray-600">
            ₦{MoneyFormat(plan?.amount_to_contribute)}
          </p>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <button
                      onClick={() => {
                        setplan(() => ({ ...plan, open: true, mode: "edit" }));
                      }}
                      type="button"
                      className="flex items-center justify-start space-x-1 text-sm font-medium focus:border-none focus:outline-none"
                    >
                      <FiEdit3 className="aspect-square w-6" />
                      <span>Edit package</span>
                    </button>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <button
                      onClick={() => {
                        openViewModal();
                        setPlantitle(() => plan?.title);
                      }}
                      className="flex items-center justify-start gap-x-1 text-sm font-medium focus:border-none focus:outline-none"
                    >
                      <AiOutlineEye /> <span>View More</span>
                    </button>
                  ),
                },
                {
                  key: "3",
                  label: (
                    <button
                      onClick={() => onClickHandler(plan?.token)}
                      className="flex items-center justify-start gap-x-1 text-sm font-medium focus:border-none focus:outline-none"
                    >
                      <BsFillTrashFill /> <span>Delete Plan</span>
                    </button>
                  ),
                },
                {
                  key: "4",
                  label: (
                    <button
                      onClick={() => disablePlan(plan?.token)}
                      className="flex items-center justify-start gap-x-1 text-sm font-medium focus:border-none focus:outline-none"
                    >
                      <MdCancel /> <span>Disable</span>
                    </button>
                  ),
                },
                {
                  key: "5",
                  label: (
                    <button
                      onClick={() => enablePlan(plan?.token)}
                      className="flex items-center justify-start gap-x-1 text-sm font-medium focus:border-none focus:outline-none"
                    >
                      <FaCheck /> <span>Enable</span>
                    </button>
                  ),
                },
              ],
            }}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <div className="flex cursor-pointer items-center gap-x-1">
              <AiOutlineArrowDown size={16} /> <span>View More</span>
            </div>
          </Dropdown>
        </div>
      </div>
    </Panel>
  );
};

const CooperativeButton = ({ ...props }) => {
  return (
    <button
      {...props}
      className="transition-all hover:drop-shadow-lg focus:border-none focus:outline-none"
    >
      <Panel>
        <div className="flex h-[145px] w-full flex-col items-center justify-center space-y-3 text-[#590DA4]">
          <span className="inline-block  rounded-md border-2 border-[#590DA4]">
            <BsPlus size={30} />
          </span>
          <span className="font-medium leading-[27px]">Add new package</span>
        </div>
      </Panel>
    </button>
  );
};

const CooperativeModal = ({ plan = null, setplan, initialValues }) => {
  const { Form, data, state } = useFetcher();
  const { flashToast } = useContext(ToastContext);
  const onChange = (e) => {
    setplan((plan) => ({ ...plan, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    flashToast(data?.success, data?.message);
    if (data?.success) {
      setplan((v) => ({ ...v, ...initialValues }));
    }
  }, [data?.message]);

  return (
    <div
      className="plans-start fixed left-0 top-0 z-50 flex h-screen w-full justify-center overflow-y-auto bg-[#24054299] pt-10 data-[hidden=false]:hidden"
      data-hidden={plan.open}
    >
      <div className="m-10 w-full max-w-3xl">
        <Panel>
          <div className="relative p-6">
            <button
              className="absolute right-0 top-0"
              onClick={() => {
                setplan((v) => ({ ...v, ...initialValues }));
              }}
            >
              <BsX className="h-6 w-6" />
            </button>
            <h3 className="text-[18px] font-[700] leading-[28px] text-[#344054]">
              Add Cooperative Package
            </h3>
            <hr className="my-[22px] w-full border-[#EAECF0]" />
            <Form
              method={plan?.mode === "create" ? "post" : "put"}
              className="plans-start flex w-full flex-col justify-start space-y-5"
              encType="multipart/form-data"
            >
              <div className="w-full">
                {plan?.token && (
                  <Input
                    type="hidden"
                    name="id"
                    normalize={true}
                    defaultValue={plan?.token}
                  />
                )}
                <File name="logo" />
                <Input
                  label="Title"
                  placeholder="Title"
                  name="title"
                  normalize={true}
                  onChange={onChange}
                  value={plan?.title}
                  error={data?.errors?.title}
                />
                <Input
                  label="Total Savings Amount"
                  name={"total_savings_amount"}
                  placeholder="Total Savings Amount"
                  // type="number"
                  normalize={true}
                  error={data?.errors?.total_savings_amount}
                  options={{ numeral: true }}
                  onChange={onChange}
                  value={plan?.total_savings_amount}
                />
                <Input
                  label="Amount To Contribute"
                  placeholder="Amount To Contribute"
                  name={"amount_to_contribute"}
                  type="number"
                  normalize={true}
                  error={data?.errors?.amount_to_contribute}
                  options={{ numeral: true, numeralDecimalScale: 2 }}
                  onChange={onChange}
                  value={plan?.amount_to_contribute}
                />
                <Input
                  label="duration"
                  placeholder="duration"
                  name={"duration"}
                  type="number"
                  // options={{ numeral: true}}
                  normalize={true}
                  error={data?.errors?.duration}
                  onChange={onChange}
                  value={plan?.duration}
                />
                <Input
                  label="Size of the savings group"
                  placeholder="Size of the savings group"
                  normalize={true}
                  name={"size_of_savings_group"}
                  type="number"
                  onChange={onChange}
                  value={plan?.size_of_savings_group}
                  error={data?.errors?.size_of_savings_group}
                />
                <Input
                  label="Commission Fee"
                  placeholder="Commission Fee"
                  normalize={true}
                  name={"commission_fee"}
                  type="number"
                  onChange={onChange}
                  value={plan?.commission_fee}
                  error={data?.errors?.commission_fee}
                />

                <Select
                  label="Contribution Frequency"
                  name={"contribution_frequency"}
                  onChange={onChange}
                  value={plan?.contribution_frequency}
                  error={data?.errors?.contribution_frequency}
                >
                  <option>--Select Option--</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="annually">Yearly</option>
                </Select>
                <TextArea
                  label="More Information"
                  name={"more_information"}
                  placeholder="More Information."
                  rows={3}
                  onChange={onChange}
                  value={plan?.more_information}
                />
                <Submit isLoading={state !== "idle"}>Submit</Submit>
              </div>
            </Form>
          </div>
        </Panel>
      </div>
    </div>
  );
};

const File = ({ ...props }) => {
  return (
    <label className="plans-center mb-5 flex w-full flex-col rounded-lg border bg-transparent px-4 py-4 text-base font-semibold tracking-wide text-gray-800 placeholder:text-gray-400 focus:border-purple-800 focus:outline-none ">
      <input type="file" {...props} className="hidden" accept="image/*" />
      <span>
        <img src={Upload} alt="upload icon" />
      </span>
      <p className="text-[14px] font-[500] leading-[20px] text-[#1D2939]">
        Click to upload{" "}
        <span className="font-[600] text-[#667085]">or drag and drop</span>
      </p>
      <p className="text-[12px] font-[400] leading-[18px] text-[#667085]">
        SVG, PNG, JPG, or GIF (max. 800x400px)
      </p>
    </label>
  );
};
const ContributionTable = ({}) => {
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
  const { Next, Prev, Button, Pagination, setPage, PageSize, Description } =
    usePagination();
  const plans = useLoaderData();
  function formatAsNaira(number) {
    if (number === null || number === undefined) {
      return "N/A";
    }

    const num = Number(number);

    if (isNaN(num)) {
      return "N/A";
    }

    const formatter = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    });

    return formatter.format(num);
  }

  return (
    <section className="min-h-[30%] bg-white">
      <Panel>
        <Table>
          <RowHeader>
            <ColumnHeader>Amount</ColumnHeader>
            <ColumnHeader>Transaction Date</ColumnHeader>
            <ColumnHeader>Plan</ColumnHeader>
            <ColumnHeader>Name</ColumnHeader>
          </RowHeader>
          <Body data={plans}>
            {(data) =>
              data?.transactionlist?.data?.data?.map((transaction, i) => (
                <Row key={i}>
                  <Column>{formatAsNaira(transaction.amount_paid)}</Column>
                  <Column>
                    {moment(transaction.transaction_date).format("DD-MM-YYYY")}
                  </Column>
                  <Column>{transaction?.package_name ?? "N/A"}</Column>

                  <Column>
                    <span className="whitespace-nowrap">
                      {transaction?.customer_fullname}
                    </span>
                  </Column>
                </Row>
              ))
            }
          </Body>
        </Table>
        <Pagination>
          <Suspense fallback={<p />}>
            <Await resolve={plans?.data} errorElement={<span>Loading</span>}>
              {(plan) => {
                return (
                  <>
                    <Prev
                      onClick={() => {
                        setPage(1);
                      }}
                    />
                    {Array(plan?.transactionlist?.data?.last_page)
                      .fill(5)
                      .map((v, i) => {
                        return (
                          <Button
                            key={i}
                            isActive={
                              plan?.transactionlist?.data?.current_page ===
                              i + 1
                            }
                            onClick={() => {
                              setPage(i + 1);
                            }}
                          >
                            {i + 1}
                          </Button>
                        );
                      })}
                    <Next
                      onClick={() => {
                        setPage(plan?.transactionlist?.data?.last_page);
                      }}
                    />
                  </>
                );
              }}
            </Await>
          </Suspense>
        </Pagination>
      </Panel>
    </section>
  );
};

const ViewPlanModal = ({ plantitle, closeModal, isViewOpen }) => {
  const plans = useLoaderData();
  function formatAsNaira(number) {
    const formatter = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(number);
  }
  const [isWithdrawalOpen, setIsWithdrawalOpen] = useState(false);
  const toggleWithdrawalModal = () => {
    setIsWithdrawalOpen((prev) => !prev);
  };
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Await resolve={plans?.data}>
          {(data) => {
            const plan = data?.response?.data.data?.find(
              (plan) => plan.title === plantitle
            );
            // console.log(plan);

            return (
              <Transition appear show={isViewOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="bg-fedacash-[#DECFED] fixed inset-0 bg-opacity-25" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-[50%] items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="div"
                            className="flex justify-between gap-3 border-b py-2 text-lg font-bold leading-6 text-[#475467]"
                          >
                            <h2>{plan?.title}</h2>
                            <button
                              type="button"
                              className=""
                              onClick={closeModal}
                            >
                              <AiOutlineClose />
                            </button>
                          </Dialog.Title>
                          <div className="mt-3 flex gap-x-5 text-sm">
                            <p className="rounded-md border-2 border-fedacash-header bg-[#DECFED] px-2 py-[10px] font-bold text-fedacash-header">
                              {formatAsNaira(plan?.total_savings_amount)}
                            </p>
                            <p className="rounded-md border px-2 py-[10px] capitalize">
                              next {plan?.duration} months
                            </p>
                            <p className="rounded-md border px-2 py-[10px]">
                              {plan?.size_of_savings_group} People
                            </p>
                            <p className="rounded-md border px-2 py-[10px]">
                              {formatAsNaira(plan?.amount_to_contribute)}
                            </p>
                          </div>
                          <div className="plans-center mt-2 flex items-center gap-x-3">
                            <p className="text-sm text-fedacash-header underline underline-offset-2">
                              Total Amount Contributed
                            </p>
                            <p className="rounded-full bg-gray-200 px-3 py-2 text-lg font-bold text-fedacash-header underline underline-offset-2">
                              ₦0.00
                            </p>
                          </div>

                          <div className="w-full gap-y-6 divide-y divide-black overflow-y-auto text-sm font-semibold">
                            {plan?.members
                              .sort(
                                (a, b) =>
                                  a?.entry_payment_date - b?.entry_payment_date
                              )
                              .map((member, index) => (
                                <div
                                  className="plans-center flex items-center justify-between gap-x-16  overflow-y-auto px-6 py-2"
                                  key={index}
                                >
                                  <div className="plans-center flex gap-x-4">
                                    <p>{index + 1}</p>
                                    <p>
                                      {member?.user?.first_name}{" "}
                                      {member?.user?.last_name}
                                    </p>
                                  </div>
                                  <p>
                                    {moment(member?.entry_payment_date).format(
                                      "DD-MMM-YY"
                                    )}
                                  </p>
                                  <button className="w-14 rounded-md bg-[#590DA4] px-2 py-2 capitalize text-white">
                                    pay
                                  </button>
                                </div>
                              ))}
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            );
          }}
        </Await>
      </Suspense>
      <PayoutModal
        toggleWithdrawalModal={toggleWithdrawalModal}
        isWithdrawalOpen={isWithdrawalOpen}
      />
    </>
  );
};
const PayoutModal = ({
  plan,
  user,
  toggleWithdrawalModal,
  isWithdrawalOpen,
}) => {
  const [] = useState();
  return (
    <div>
      <Transition appear show={isWithdrawalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={toggleWithdrawalModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex justify-evenly gap-3 text-lg font-bold leading-6 text-[#475467]"
                  >
                    How much do you want to pay to
                    <button
                      type="button"
                      className=""
                      onClick={() => toggleWithdrawalModal()}
                    >
                      <AiOutlineClose />
                    </button>
                  </Dialog.Title>
                  <div className="flex flex-col items-center justify-center">
                    <img src={walletimage} alt="" />
                    <div className="space-y-3 py-4">
                      <div>
                        <label>Name</label>
                        <input
                          value={``}
                          className="w-full rounded-[8px] border-[1.5px] border-[#b8c0cc] px-[19px] py-[11px] text-[12px] font-bold leading-[18px] placeholder:text-[#b8c0cc] focus:outline-none"
                        />
                      </div>
                      <div>
                        <label>Category</label>
                        <input
                          placeholder="Amount"
                          className="w-full rounded-[8px] border-[1.5px] border-[#b8c0cc] px-[19px] py-[11px] text-[12px] font-bold leading-[18px] placeholder:text-[#b8c0cc] focus:outline-none"
                        />
                      </div>
                      <div>
                        <label>Amount</label>
                        <input
                          placeholder="Enter Amount"
                          className="w-full rounded-[8px] border-[1.5px] border-[#b8c0cc] px-[19px] py-[11px] text-[12px] font-bold leading-[18px] placeholder:text-[#b8c0cc] focus:outline-none"
                        />
                      </div>
                      <div className="mt-4 flex gap-x-4">
                        <button
                          onClick={() => toggleWithdrawalModal()}
                          className={`flex w-full items-center justify-center rounded-md border-2 border-[#590DA4] px-2 py-3 text-[#590DA4]`}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="w-full rounded-md bg-fedacash-header px-2 py-3 text-center text-white"
                        >
                          Proceed to payment
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
const Wallet = ({ balance }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };
  function formatNairaAmount(amount) {
    // Check if the input is a valid number
    if (typeof amount !== "number" || isNaN(amount)) {
      return "Invalid Amount";
    }
    // Convert the number to a string and split it into parts before and after the decimal point
    const parts = amount.toFixed(2).toString().split(".");

    // Add commas for thousands separator
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Combine the parts with the decimal point and prefix with "₦"
    return `₦${parts.join(".")}`;
  }
  return (
    <div className="w-full lg:w-1/2 xl:w-2/5">
      <div className="plans-center mt-2 flex gap-x-4 rounded-[21.912px] bg-gradient-to-br from-[#FAB2FF] to-[#6704E5] px-6 py-14 text-white shadow-md ">
        <div className="flex flex-col gap-y-3">
          <p>Total Cooperative Wallet</p>
          <h3 className="text-3xl">
            {isVisible ? formatNairaAmount(balance?.data) : "*****"}
          </h3>
        </div>
        <div onClick={handleClick}>
          <AiOutlineEyeInvisible size={32} />
        </div>
      </div>
    </div>
  );
};
export default SaveandPack;
