import { Dropdown } from "antd";
import { BsThreeDots } from "react-icons/bs";
import moment from "moment/moment";

import Panel from "../Panel";
import { Body, Column, ColumnHeader, Row, RowHeader, Table } from "./Table";
import { Link, useLoaderData } from "react-router-dom";
import { formatNaira } from "../../FormatMoney";
import usePagination from "../Table/usePagination";

//TODO:// change the name of the componetn to Loan request table
const LoanRepayment = ({}) => {
  const data = useLoaderData();
  const loandata = data?.loanrequests?.data?.data;
  const { Next, Prev, Button, Pagination, setPage, PageSize, Description } =
    usePagination();
  return (
    <Panel>
      <Table>
        <RowHeader>
          <ColumnHeader>Description</ColumnHeader>
          <ColumnHeader>Transaction Date</ColumnHeader>
          <ColumnHeader>Amount</ColumnHeader>
          <ColumnHeader>Status</ColumnHeader>
          <ColumnHeader>Action</ColumnHeader>
        </RowHeader>
        {loandata?.length < 1 ? (
          <div>No data Available</div>
        ) : (
          <Body data={loandata}>
            {(data) =>
              data.map((item) => (
                <Row key={item.loan_token}>
                  <Column>{item.description}</Column>
                  <Column>
                    {moment(item.created_at).format(
                      "dddd, Do MMMM YYYY: h:mmA"
                    )}
                  </Column>
                  <Column>{formatNaira(item.amount)}</Column>
                  <Column>
                    <span classname="text-xs text-green-600 px-2 py-1 rounded-md bg-gray-50">
                      {item.request_status}
                    </span>
                  </Column>
                  <Column>
                    <Dropdown
                      trigger={["click"]}
                      menu={{
                        items: [
                          {
                            key: "1",
                            label: (
                              <Link
                                to={`/dashboard/loan/request/${item.id}`}
                                className="flex items-center justify-start space-x-1 text-sm font-medium focus:border-none focus:outline-none"
                              >
                                <span>View More</span>
                              </Link>
                            ),
                          },
                        ],
                      }}
                      placement="bottomRight"
                      arrow={{ pointAtCenter: true }}
                    >
                      <div className="flex cursor-pointer items-center gap-x-1">
                        <BsThreeDots />
                      </div>
                    </Dropdown>
                  </Column>
                </Row>
              ))
            }
          </Body>
        )}
      </Table>
      <div className="mt-2 flex items-center justify-between">
        <PageSize numbers={[5, 10, 20, 50]} />
        <Pagination>
          <>
            <Prev
              onClick={() => {
                setPage(1);
              }}
            />
            {Array(data?.loanrequests?.data?.last_page)
              .fill(5)
              .map((v, i) => {
                return (
                  <Button
                    key={i}
                    isActive={data?.loanrequests?.data?.current_page === i + 1}
                    onClick={() => {
                      setPage(i + 1);
                    }}
                  >
                    {i + 1}
                  </Button>
                );
              })}
            <Next
              onClick={() => {
                setPage(data?.loanrequests?.data?.last_page);
              }}
            />
          </>
        </Pagination>
      </div>
    </Panel>
  );
};

export default LoanRepayment;
const RequestStatusBadge = ({ request_status }) => {
  let badgeColor = "";
  switch (request_status) {
    case "Approved":
      badgeColor = "text-green-600";
      break;
    case "pending":
      badgeColor = "text-yellow-600";
      break;
    case "Declined":
      badgeColor = "text-red-700";
      break;
    case "Paid":
      badgeColor = "text-green-700";
      break;
    default:
      badgeColor = "text-gray-500";
      break;
  }

  return (
    <div
      className={`flex items-start justify-start gap-2 rounded-lg bg-gray-300 px-2 py-0.5`}
    >
      <div className={`text-sm font-bold leading-tight ${badgeColor}`}>
        {request_status}
      </div>
    </div>
  );
};
