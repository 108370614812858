import { BiChevronLeft } from "react-icons/bi";
import { useLoaderData, useNavigate } from "react-router-dom";
import { LoanInput } from "../../../Components/Dashboard/Loan/LoanInput";
import { formatAsNaira } from "../../../Components/FormatMoney";
import moment from "moment";
import PageHeader from "../../../Components/Dashboard/PageHeader";

const SaveandGoSubscriptions = () => {
  const { subscription } = useLoaderData();
  const navigate = useNavigate();

  function CalculateMonths(months) {
    if (isNaN(months)) return "Invalid Duration";
    return parseInt(months) === 1 ? `1 Month` : `${months} Months`;
  }
  console.log(subscription);
  return (
    <div>
      <div>
        <button
          className="inline-flex h-9 w-9 items-center justify-center gap-2 rounded-full border bg-gray-300"
          onClick={() => navigate(-1)}
        >
          <BiChevronLeft size={24} />
        </button>
      </div>
      <div className="mt-4 px-2">
        <PageHeader>
          {subscription?.data?.description}: Save and Go Plan
        </PageHeader>
        <p className="text-[#64748B]">{new Date().toDateString()}</p>
      </div>
      <div>
        <div className="mt-4 space-y-5">
          <LoanInput
            name="Contribution Amount"
            value={formatAsNaira(subscription?.data?.amount_to_contribute)}
          />
          <LoanInput
            name={`Created At`}
            value={moment(subscription?.data?.created_at).format(
              "MMMM D , dddd YYYY, h:mmA"
            )}
          />
          <LoanInput
            name={`Duration`}
            value={CalculateMonths(subscription?.data?.frequency_duration)}
          />
          <LoanInput
            name={`Frequency`}
            value={subscription?.data?.frequency_selection}
          />
          <LoanInput
            name={`Payment Option`}
            value={subscription?.data?.payment_option}
          />
        </div>
      </div>
    </div>
  );
};

export default SaveandGoSubscriptions;
