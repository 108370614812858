import ResponseObject from "../../DataObjects/ResponseObject";
import http from "../../Store/baseHttp";

export async function ThirdPartyInvoiceLoader({ request, params }) {
  const url = new URL(request.url);

  try {
    let InvoiceRequests = await http.get("/admin/invoice/list" + url.search);
    InvoiceRequests = ResponseObject.from(InvoiceRequests);
    // console.log(paidRequests);
    return { InvoiceRequests };
  } catch (e) {
    return ResponseObject.from(e?.response);
  }
}
