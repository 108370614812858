import { getReasonPhrase } from 'http-status-codes'
import { useParams } from 'react-router-dom'

const Error = () => {
  const params = useParams()
  let code = parseInt(params?.id?.replace(/\D/g, '')) ?? 404
  if (!code) {
    code = 404
  }

  return (
    <div className='relative flex h-full w-full items-center justify-center'>
      <div className='flex items-center justify-center divide-x divide-purple-800 border-transparent bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-3xl font-bold text-transparent'>
        <span className='px-5'>Error {code}</span>
        <span className='px-5'>{getReasonPhrase(code)}</span>
      </div>
    </div>
  )
}

export default Error
