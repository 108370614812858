import { BsChevronLeft, BsPlus, BsPlusCircle, BsThreeDots, BsX } from 'react-icons/bs'
import Input from '../../../Components/Forms/Input'
import useTable from '../../../Components/Dashboard/Table/useTable'
import TextArea from '../../../Components/Forms/TextArea'
import Select from '../../../Components/Forms/Select'
import { useContext, useEffect, useState } from 'react'
import InvoiceCalc from './InvoiceCalc'
import InvoiceInput from './InvoiceInput'
import { Link, useFetcher, useLoaderData, useNavigate } from 'react-router-dom'
import Panel from '../../../Components/Dashboard/Panel'
import Submit from '../../../Components/Forms/Submit'
import Img from '../../../Assets/images/ResetDone.png'
import UserObject from '../../../DataObjects/UserObject'
import useTitle from '../../../Hooks/useTitle'
import InvoiceNumber from './InvoiceNumber'
import InvoiceCompany from './InvoiceCompany'
import ToastContext from '../../../Routes/ToastContext'

const InvoiceCreate = () => {
  useTitle('Invoice Create')
  const { Row, Column } = useTable()
  const { Form, data, state } = useFetcher()
  const template = useLoaderData()
  const { flashToast } = useContext(ToastContext)

  const staticColors = ['#590DA4', '#88C02C', '#1D4ED8', '#F59E0B', '#EF4444', '#F7CE46', '#610BEF', '#03CFBB', '#CA024F', '#AA33FF', '#E3026F', '#2AA8F8']
  const [value, setValue] = useState([])
  const [subTotal, setSubtotal] = useState(0)
  const [tax, setTax] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [total, setTotal] = useState(0)
  const [color, setColor] = useState(staticColors[0])
  const [asEmail, setAsEmail] = useState(false)
  const [toast, setToast] = useState(false)
  const [file, setFile] = useState(null)
  const [notes, setNotes] = useState('')
  const [dueDate, setDueDate] = useState('')
  const [issueDate, setIssueDate] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [company, setCompany] = useState('')
  const [invoiceNumber, setInvoiceNumber] = useState('')
  const [existingLogo, setExistingLogo] = useState('')
  const [taxType, setTaxType] = useState()
  const [currency, setCurrency] = useState('NGN')
  const navigate = useNavigate()

  useEffect(() => {
    if (data?.success) {
      setAsEmail(false)
      setToast(true)
    }

    if (!data?.success) {
      setAsEmail(false)
      flashToast(data?.success, data?.message)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (template.success && template.data) {
      setColor(template?.data?.color)
      setExistingLogo(template?.data?.logo_path)
      setTaxType(template?.data?.tax_type ?? '')
      setCurrency(template?.data?.currency ?? 'NGN')
    }
  }, [template])

  const locale = 'en-NG'
  // const currency = 'NGN';

  const lineItem = {
    item: '', unit_price: '0.00', quantity: 0
  }

  const addItem = () => {
    setValue(v => [...v ?? [], lineItem])
  }

  const removeItem = (index) => {
    setValue(v => {
      return v.filter((value, i) => {
        return index !== i
      })
    })
  }

  const formatCurrency = (amount) => {
    return (new Intl.NumberFormat(locale, {
      style: 'currency', currency: 'NGN', minimumFractionDigits: 2, maximumFractionDigits: 2
    }).format(amount))
  }

  const handleChange = (elementIndex) => (event) => {
    setValue(value => value.map((item, i) => {
      if (elementIndex !== i) return item
      return { ...item, [event.target.name]: event.target.value }
    }))
  }

  const calculateDiscount = () => {
    return (discount * subTotal / 100)
  }

  const calculateTax = () => {
    return (tax * subTotal / 100)
  }

  useEffect(() => {
    setSubtotal(value.map(v => {
      return v.unit_price.toString().replaceAll(',', '').replace('₦', '') * v.quantity.toString().replace('₦', '')
    })
      .reduce((c, v) => (c + v), 0))
  }, [value])

  useEffect(() => {
    setTotal(subTotal + calculateTax() - calculateDiscount())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotal, tax, discount])

  const handleFileChange = e => {
    setFile(e.target.files[0])
  }

  useEffect(() => {

  }, [])

  return (
    <Form method='post' encType='multipart/form-data'>
      <input type='hidden' name='items' value={JSON.stringify(value)} />
      <div className='flex items-center justify-start space-x-3'>
        <Link to='/dashboard/invoice' className='text-black bg-white rounded-sm px-1 py-2 shadow'>
          <BsChevronLeft className='w-4 h-3 ' />
        </Link>
        <h1 className='text-3xl font-bold'>
          Invoice
        </h1>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-3 mt-5'>
        <div
          className='col-span-full md:col-span-2 pb-10 gap-10 bg-white shadow w-full'
        >
          <div className='h-[36px] rounded-[11px]' style={{ backgroundColor: color }} />
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-10 w-full px-6 pt-[21px]'>
            <div className='w-2/3 place-self-start'>
              <label
                className='bg-[#E7EAEE] h-[111px] w-full inline-flex overflow-hidden items-center justify-center font-[700] text-[16px] leading-[27px]'
              >
                {!file && !existingLogo &&
                  <span className='inline-flex items-center justify-center space-x-3 w-full'>
                    <BsPlus className='w-6 h-6' />
                    <span>Add Your Logo</span>
                  </span>}
                {file && <div
                  className='w-full h-full bg-center bg-cover'
                  style={{ backgroundImage: `url(${URL.createObjectURL(file)})` }}
                         />}
                <input
                  className='hidden' type='file' accept='image/jpeg, image/png, image/jpg' name='logo'
                  onChange={handleFileChange}
                />
                {!file && existingLogo && <img src={existingLogo} alt='existinglogo' className='object-center object-scale-down aspect-auto w-full' />}
                {!file && existingLogo && <input type='hidden' name='existing_logo' defaultValue={existingLogo} />}
              </label>
            </div>
            <div className='w-2/3 place-self-end'>
              <h1 className='font-[700] text-[33px] mb-4 leading-[38.05px] text-center uppercase tracking-[2%]'>Invoice</h1>
              <InvoiceNumber setInvoiceNumber={setInvoiceNumber} />
              <InvoiceInput
                name='due_date' placeholder='Due Date'
                value={dueDate}
                onChange={e => setDueDate(e.target.value)}
                type='text'
                onFocus={(e) => e.target.type = 'date'}
                onBlur={e => e.target.value !== '' ? e.target.value : e.target.type = 'text'}
                error={data?.errors?.due_date}
              />
              <InvoiceInput
                type='text'
                onFocus={(e) => e.target.type = 'date'}
                onBlur={e => e.target.value !== '' ? e.target.value : e.target.type = 'text'}
                name='issue_date'
                placeholder='Issue date'
                value={issueDate}
                onChange={e => setIssueDate(e.target.value)}
                error={data?.errors?.issue_date}
              />
            </div>
            <div className='w-full'>
              <h1 className='font-[700] text-[16px] leading-[24px]'>From</h1>
              <InvoiceInput
                type='name' normalize label='From' list='companies' placeholder='Company Name'
                value={UserObject.getUser().company_name} readOnly
              />
            </div>
            <div>
              <h1 className='font-[700] text-[16px] leading-[24px]'>To</h1>
              <InvoiceCompany data={data} name='company_name' />
            </div>
            <div className='col-span-full'>
              <table className='table-fixed w-full rounded-[8px] overflow-hidden'>
                <thead>
                  <tr
                    className='rounded-[8px] text-white border-collapse'
                    style={{ backgroundColor: color }}
                  >
                    <th className='w-4/12 py-4 px-1 border-none'>
                      Item
                    </th>
                    <th className='w-2/12 px-1 py-4 border-none'>
                      QTY
                    </th>
                    <th className='w-3/12 px-1 py-4 border-none'>
                      Price
                    </th>
                    <th className='w-2/12 px-2 py-4 border-none'>
                      Total
                    </th>
                    <th className='w-1/12 border-none' />
                  </tr>
                </thead>
                <tbody>
                  {value?.map((v, i) => (<InvoiceCalc
                    key={i}
                    item={v.item}
                    index={i}
                    removeItem={removeItem}
                    handleChange={handleChange}
                    price={v.unit_price}
                    quantity={v.quantity}
                    formatCurrency={formatCurrency}
                    id={i}
                                         />))}
                  <Row>
                    <Column>
                      <button
                        onClick={addItem} type='button'
                        className='flex focus:outline-none items-center justify-start space-x-3'
                      >
                        <BsPlusCircle className='w-4 h-4' />
                        <p className='underline text-purple-800 whitespace-nowrap'>Add New</p>
                      </button>
                    </Column>
                  </Row>
                </tbody>
              </table>
            </div>
            <hr className='border-t border-gray-500 w-full col-span-full' />
            <div className='col-span-full'>
              <div className='flex items-center justify-end space-x-2'>
                <span className='text-right'>Subtotal</span>
                <span className='text-right w-28'>{new Intl.NumberFormat('en-NG', {
                  style: 'currency', currency: 'NGN'
                }).format(subTotal)}
                </span>
                <input type='hidden' name='sub_total' value={subTotal} />
              </div>
              <div className='flex items-center justify-end space-x-2'>
                <span className='text-right'>Discount({discount}%)</span>
                <span className='text-right w-28'>{new Intl.NumberFormat('en-NG', {
                  style: 'currency', currency: 'NGN'
                }).format(calculateDiscount())}
                </span>
              </div>
              <div className='flex items-center justify-end space-x-2'>
                <span className='text-right'>Tax({tax}%)</span>
                <span className='text-right w-28'>{new Intl.NumberFormat('en-NG', {
                  style: 'currency', currency: 'NGN'
                }).format(calculateTax())}
                </span>
              </div>
              <div className='flex items-center justify-end'>
                <span className='text-right'>Total</span>
                <span className='text-right w-28'>{new Intl.NumberFormat('en-NG', {
                  style: 'currency', currency: 'NGN'
                }).format(total)}
                </span>
                <input type='hidden' name='total' value={total} />
              </div>
              <div className='flex items-center justify-end'>
                <span className='text-right'>Balance Due</span>
                <span className='text-right w-28'>{new Intl.NumberFormat('en-NG', {
                  style: 'currency', currency: 'NGN'
                }).format(total)}
                </span>
              </div>
            </div>
            <hr className='border-t border-gray-500 w-full col-span-full' />
            <div className='col-span-full'>
              <TextArea label='Notes' value={notes} onChange={e => setNotes(e.target.value)} />
            </div>
            <label className='flex items-center justify-start space-x-3'>
              <input type='checkbox' name='save_as_template' value='1' />
              <p>Save as a Template</p>
            </label>
          </div>
        </div>
        <div
          className='col-span-full md:col-span-1 flex flex-col px-6 bg-white md:bg-transparent shadow md:shadow-none items-start justify-start w-full space-y-10'
        >
          <div className='w-full'>
            <h2 className='mb-5 font-bold text-lg'>Color</h2>
            <div className='grid grid-cols-6 w-full gap-2'>
              {staticColors.map((v, k) => (<button
                type='button' onClick={() => {
                  setColor(v)
                }} key={k}
                data-active={color === v}
                className='rounded-full w-[34px] h-[34px] bg-white data-[active=true]:border-2 data-[active=true]:p-0.5'
                style={{ borderColor: v }}
                                           >
                <div
                  className='block w-full h-full rounded-full'
                  style={{ backgroundColor: v }}
                />
              </button>))}
            </div>
            <div className='mt-5'>
              <label
                className='w-full relative border-2 rounded-[8px] hover:bg-[#590DA4] hover:text-white text-[#590DA4] font-[700] text-[16px] leading-[24px] border-[#590DA4] flex items-center justify-center border-purple-500 h-[54px]'
              >
                <input
                  type='color' className='hidden static' name='color' value={color}
                  onChange={e => setColor(e.target.value)}
                />
                <span>Custom Color</span>
              </label>
              {/* <input type="color"/> */}
            </div>
          </div>
          <div className='w-full'>
            <h2 className='mb-5 font-bold text-lg'>Currency</h2>
            <Select name='currency' value={currency} onChange={e => setCurrency(e.target.value)}>
              <option value=''>--Select Currency --</option>
              <option value='NGN'>
                Nigeria (₦)
              </option>
            </Select>
          </div>
          <div className='flex flex-col space-y-2 items-start w-full'>
            <h2 className='mt-5 font-bold text-lg'>Tax</h2>
            <hr className='w-full' />
            <div className='flex flex-col w-full space-y-2'>
              <label className='flex items-center w-full justify-between'>
                <span>Type</span>
                <div className='w-40'>
                  <Select name='tax_type' value={taxType} onChange={e => setTaxType(e.target.value)}>
                    <option value=''>-- Select --</option>
                    <option value='VAT'>VAT</option>
                  </Select>
                  <Input type='hidden' error={data?.errors?.tax_type} />
                </div>
              </label>
              <label className='flex items-center justify-between w-full'>
                <span>Percent</span>
                <div className='w-40'>
                  <InvoiceInput
                    type='text'
                    name='tax_value'
                    value={tax}
                    onChange={(e) => {
                      setTax(e.target.value)
                    }}
                    options={{
                      numeral: true, numeralDecimalScale: 2, delimiter: ''
                    }}
                    error={data?.errors?.tax_value}
                  />
                </div>
              </label>
            </div>
          </div>
          <div className='flex flex-col space-y-2 items-start w-full'>
            <h2 className='mt-5 font-bold text-lg'>Discount</h2>
            <hr className='w-full' />
            <div className='flex flex-col w-full'>
              <label className='flex items-center justify-between w-full'>
                <span>Percent</span>
                <div className='w-40'>
                  <InvoiceInput
                    type='text'
                    value={discount}
                    name='discount_value'
                    onChange={(e) => {
                      setDiscount(e.target.value)
                    }}
                    options={{
                      numeral: true, numeralDecimalScale: 2, delimiter: ''
                    }}
                    error={data?.errors?.discount}
                  />
                  <input type='hidden' name='discount' defaultValue={calculateDiscount()} />
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <Preview
        color={color}
        value={value}
        formatCurrency={formatCurrency}
        subTotal={subTotal}
        total={total}
        discount={discount}
        tax={tax}
        issueDate={issueDate}
        dueDate={dueDate}
        invoiceNumber={invoiceNumber}
        amountDue={total}
        notes={notes}
        company={company}
      />
      <input type='hidden' name='send_as_email' value={asEmail ? 1 : 0} />
      <div className='flex items-center justify-start space-x-[24px] mt-5'>
        <button
          type='submit'
          className={`font-[700] rounded-[8px] text-[18px] leading-[27px] h-[59px] w-[261px] flex items-center justify-center border-2 border-[#590DA4] text-[#590DA4] ${state !== 'idle' ? 'opacity-50' : ''}`}
        >
          {state !== 'idle'
            ? <BsThreeDots className='w-6 h-6 animate-pulse text-purple-900' />
            : <span>Save as Draft</span>}
        </button>
        <button
          type='button'
          onClick={() => {
            setAsEmail(true)
          }}
          className='bg-[#590DA4] rounded-[8px] text-white font-[700] text-[18px] leading-[27px] h-[59px] w-[261px] flex items-center justify-center'
        >
          <span>Send as Mail</span>
        </button>
      </div>
      <div
        className={`fixed z-[999] top-0 left-0 w-full h-full items-center bg-purple-700/70 justify-center ${asEmail ? 'flex' : 'hidden'}`}
      >
        <div className='relative max-w-sm w-full bg-white shadow rounded-[8px] p-10'>
          <button
            onClick={() => setAsEmail(false)}
            className='rounded-full p-1 absolute -top-2 -left-2 bg-white'
          ><BsX
            className='w-6 h-6'
          />
          </button>
          <h2 className='font-[700] text-[24px] leading-[27.67px] tracking-[2%] mb-[24px]'>Send Via Email</h2>
          <p className='font-[400] text-[18px] leading-[27px]'>Send Generated Invoice</p>
          {asEmail && <Input type='email' name='company_email' multiple normalize placeholder='Enter your email' />}
          <p className='-mt-5 mb-3text-xs text-gray-300'>(Enter emails comma seperated)</p>
          <Submit isLoading={state !== 'idle'}>Send as Email</Submit>
        </div>
      </div>
      <div
        className={`fixed w-full h-screen items-center justify-center z-[999] bg-purple-500/70 top-0 left-0 ${toast ? 'flex' : 'hidden'}`}
      >
        <div className='w-full max-w-sm relative'>
          <Panel>
            <button
              type='button' onClick={() => {
                setToast(false)
                navigate('/dashboard/invoice')
              }} className='p-1 bg-white absolute -top-3 -left-3 rounded-full'
            >
              <BsX className='w-6 h-6 text-gray-500' />
            </button>
            <div className='flex flex-col items-center space-y-2 px-[15px] py-[20px]'>
              <img src={Img} alt='sent invoice' />
              <p className='text-[#4B5768] font-[400] text-[18px] text-center leading-[27px]'>
                {asEmail
                  ? 'Your Generated Invoice has been sent'
                  : 'Your Invoice has been saved as draft'}
              </p>
            </div>
          </Panel>
        </div>
      </div>
    </Form>
  )
}

const Preview = ({
  color,
  value,
  formatCurrency,
  subTotal,
  total,
  tax,
  discount,
  issueDate,
  dueDate,
  amountDue,
  invoiceNumber,
  notes,
  company
}) => {
  const [state, setState] = useState(true)
  const { company_name, phone_number } = UserObject.getUser()
  return (
    <>
      <button
        type='button' onClick={() => setState(v => !v)} className='text-sm my-2'
        style={{ color }}
      >Preview Invoice
      </button>
      <div
        className={`w-full fixed items-start py-20 justify-center bg-purple-800/50 top-0 left-0 z-[999] overflow-y-auto h-screen ${state ? 'hidden' : 'flex'}`}
      >
        <div className='max-w-[593px] relative pb-[50px] bg-white w-full rounded-[10px]'>
          <button
            type='button'
            className='bg-white absolute -top-5 -right-5 p-1 rounded-full'
            onClick={() => {
              setState(v => !v)
            }}
          >
            <BsX className='w-4 h-4' />
          </button>
          <div className=' mb-[28px] h-[26.29px] bg-[#590DA4] rounded-[10px]' />
          <div className='px-[38px]'>
            <div className='flex mb-[20.43px] items-start justify-between'>
              <h1 className='font-[700] text-[30px] leading-[34.59px] text-[#0D0F11] tracking-[4%]'>Invoice</h1>
              <div className='font-[400] text-[14px] leading-[21px] text-right'>
                <p>{company_name}</p>
                <p>Lagos, Nigeria</p>
                <p>1234, Street</p>
                <p>{phone_number}</p>
              </div>
            </div>
            <div className='flex items-start text-left justify-between space-x-6 mb-[13.25px]'>
              <div className='font-[400] text-[14px] leading-[21px] w-2/5'>
                <span
                  className='font-[700] text-[12px] leading-[18px] text-[#590DA4] whitespace-nowrap'
                >Billed to
                </span>
                <p>{company}</p>
                <p>Lagos, Nigeria</p>
                <p>1234, Street</p>
                <p>08061266260</p>
              </div>
              <div className='font-[400] text-[14px] leading-[21px] w-1/5'>
                <div>
                  <span
                    className='font-[700] text-[12px] leading-[18px] text-[#590DA4] whitespace-nowrap'
                  >Date Issued
                  </span>
                  <p>{issueDate}</p>
                </div>
                <div>
                  <span
                    className='font-[700] text-[12px] leading-[18px] text-[#590DA4] whitespace-nowrap'
                  >Due Date
                  </span>
                  <p>{dueDate}</p>
                </div>
              </div>
              <div className='font-[400] text-[14px] leading-[21px] w-1/5'>
                <span
                  className='font-[700] text-[12px] leading-[18px] text-[#590DA4] whitespace-nowrap'
                >Invoice Number
                </span>
                <p>{invoiceNumber}</p>
              </div>
              <div className='font-[400] text-[14px] leading-[21px] w-1/5'>
                <span
                  className='font-[700] text-[12px] leading-[18px] text-[#590DA4] whitespace-nowrap'
                >Amount Due
                </span>
                <p>{amountDue}</p>
              </div>
            </div>
            <div className='mb-[35px]'>
              <table className='w-full rounded-[8px] overflow-hidden'>
                <thead className='w-full'>
                  <tr className='max-h-[43.02] rounded-[8px] h-full bg-[#590DA4] font-[400] whitespace-nowrap text-[14px] leading-[21px] text-white'>
                    <th className='border-none px-[25.26px] py-[11px]'>
                      <span className='px-[25.26px] py-[11px]'>
                        Item
                      </span>
                    </th>
                    <th className='border-none px-[25.26px] py-[11px]'>
                      <span className=''>
                        QTY
                      </span>
                    </th>
                    <th className='border-none px-[25.26px] py-[11px]'>
                      <span className=''>
                        Unit Price
                      </span>
                    </th>
                    <th className='border-none px-[25.26px] py-[11px]'>
                      <span className=''>
                        Total
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {value && value.map((v, index) => <tr
                    key={index}
                    className='border-b  last:border-b-transparent'
                                                    >
                    <td className='pl-[5px] pr-[10px] pt-[18px] pb-[5px] '>
                      {v.item}
                    </td>
                    <td>
                      {v.quantity}
                    </td>
                    <td>
                      {v.unit_price}
                    </td>
                    <td>
                      {v.quantity * v.unit_price.replace('₦', '').replaceAll(',', '')}
                    </td>
                                                    </tr>)}
                </tbody>
              </table>

            </div>
            <div className=' flex flex-col items-end justify-start'>
              <div
                className='flex items-center justify-end border-b space-x-2 py-2'
                style={{ borderColor: color }}
              >
                <div className='flex flex-col items-end justify-start text-right '>
                  <span>Subtotal</span>
                  <span>Discount</span>
                  <span>Tax</span>
                </div>
                <div className='flex flex-col items-end justify-start text-right'>
                  <span>{formatCurrency(subTotal)}</span>
                  <span>{formatCurrency(discount)}</span>
                  <span>{formatCurrency(tax)}</span>
                </div>
              </div>
              <div className='flex items-center justify-end space-x-2 py-2'>
                <div className='flex flex-col items-end justify-start text-right '>
                  <span>Total</span>
                  <span>Balance Due</span>
                </div>
                <div className='flex flex-col items-end justify-start text-right'>
                  <span>{formatCurrency(total)}</span>
                  <span>0.00</span>
                </div>
              </div>
            </div>
            <div className='font-[400] text-[14px] leading-[21px]'>
              <div className='mb-[10px]'>
                <h4 className='text-[#590DA4] mb-[5px] font-[700] text-[12px] leading-[18px]'> Notes</h4>
                <p>{notes}</p>
              </div>
              <div>
                <h4 className='text-[#590DA4] mb-[5px] font-[700] text-[12px] leading-[18px]'> Terms</h4>
                <p>Please Pay withing 30days using the link in your invoice email</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InvoiceCreate
