import ResponseObject from "../../DataObjects/ResponseObject";
import http from "../../Store/baseHttp";

export async function AddPayment({ request }) {
  const formData = Object.fromEntries(await request.formData());
  const form = new FormData();
  Object.keys(formData).forEach((value, key) => {
    form.append(value, formData[value]);
    // console.log(value, formData[value]);
  });
  try {
    let createPayment = await http.post("/admin/create/payment", form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    createPayment = ResponseObject.from(createPayment);
    return createPayment;
  } catch (e) {
    return ResponseObject.from(e?.response);
  }
}
export async function CooperativeList(url) {
  function getTitleAndMembers(list) {
    return list?.data?.data.map((obj) => {
      return {
        title: obj.title,
        members: obj.members.map((member) => {
          return {
            firstname: member.user.first_name,
            lastname: member.user.last_name,
            email: member.user.email,
            token: member.token,
          };
        }),
      };
    });
  }
  try {
    const response = await http.get("/admin/cooperative/packages/list");
    const list = ResponseObject.from(response);
    // console.log(getTitleAndMembers(list))
    return getTitleAndMembers(list);
  } catch (error) {
    // console.error(error);
    return [];
  }
}
