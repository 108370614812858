import useTable from '../../../Components/Dashboard/Table/useTable'
import { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import InvoiceInput from './InvoiceInput'

const InvoiceCalc = ({ item = '', quantity = '0', price = '0', removeItem, handleChange, index, formatCurrency }) => {
  const { Row, Column } = useTable()
  const [total, setTotal] = useState(formatCurrency(0))

  useEffect(() => {
    setTotal(formatCurrency(price.replaceAll(',', '').replace('₦', '') * quantity))
  }, [formatCurrency, price, quantity])

  return (
    <Row>
      <td className='px-1'>
        <InvoiceInput
          type='text'
          name='item'
          value={item}
          onChange={handleChange(index)}
          normalize
          required
        />
      </td>
      <td className='px-1'>
        <InvoiceInput
          type='number'
          name='quantity'
          value={quantity}
          options={{
            numeral: true,
            numeralDecimalScale: 0,
            delimiter: ''
          }}
          onChange={handleChange(index)}
          required
        />
        {/* <input type="hidden" name='items.1.quantity' value={quantity}/> */}
      </td>
      <td className='px-1'>
        <InvoiceInput
          type='text'
          name='unit_price'
          value={price}
          options={{
            numeral: true,
            prefix: '₦',
            rawValueTrimPrefix: true,
            numeralDecimalScale: 2
          }}
          onChange={handleChange(index)}
          required
        />
      </td>
      <td className='px-1'>
        {total}
      </td>
      <td className='px-1'>
        <button onClick={() => {
          removeItem(index)
        }}
        >
          <BsTrash className='w-4 h-4' />
        </button>
      </td>
    </Row>
  )
}

export default InvoiceCalc
