import { useLoaderData } from "react-router-dom";
import usePagination from "../../../Components/Dashboard/Table/usePagination";
import useTable from "../../../Components/Dashboard/Table/useTable";
import { formatAsNaira } from "../../../Components/FormatMoney";
import moment from "moment";
import Panel from "../../../Components/Dashboard/Panel";
import PageHeader from "../../../Components/Dashboard/PageHeader";

const LoanTransactions = () => {
  const { loantransactions } = useLoaderData();
  return (
    <div>
      <div>
        <PageHeader>Loan Transactions</PageHeader>
        <p className="font-[400] leading-[24px] text-[#64748B] text-[px]">
          {new Date().toDateString()}
        </p>
      </div>{" "}
      <LoanRepayment />
    </div>
  );
};

export default LoanTransactions;

const LoanRepayment = ({}) => {
  const { loantransactions } = useLoaderData();

  const { Next, Prev, Button, Pagination, setPage, PageSize, Description } =
    usePagination();
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
// console.log(loantransactions)
  return (
    <Panel className="mt-4">
      <Table>
        <RowHeader>
          <ColumnHeader>ID</ColumnHeader>
          <ColumnHeader>Amount</ColumnHeader>
          <ColumnHeader>Transaction Date</ColumnHeader>
          <ColumnHeader>Transaction Title</ColumnHeader>
          <ColumnHeader>Payment Method</ColumnHeader>
          <ColumnHeader>Company Name</ColumnHeader>
          <ColumnHeader>Customer Name</ColumnHeader>
        </RowHeader>
        <tbody>
          {loantransactions?.data?.data.map((transaction, index) => (
            <Row key={index}>
              <Column>{index + 1}</Column>

              <Column>{formatAsNaira(transaction.amount)}</Column>
              <Column>
                {moment(transaction.created_at).format("dddd, Do MMMM YYYY: h:mmA")}
              </Column>
              <Column>{transaction?.transaction_title ?? "N/A"}</Column>
              <Column>{transaction?.payment_method ?? "N/A"}</Column>
              <Column>{transaction?.company_name ?? "N/A"}</Column>

              <Column>{transaction?.customer_fullname ?? "N/A"}</Column>
            </Row>
          ))}
        </tbody>
      </Table>
      <div className="mt-2 flex items-center justify-between">
        <PageSize numbers={[5, 10, 20, 50]} />
        <Pagination>
          <>
            <Prev
              onClick={() => {
                setPage(1);
              }}
            />
            {Array(loantransactions?.data?.last_page)
              .fill(5)
              .map((v, i) => {
                return (
                  <Button
                    key={i}
                    isActive={loantransactions?.data?.current_page === i + 1}
                    onClick={() => {
                      setPage(i + 1);
                    }}
                  >
                    {i + 1}
                  </Button>
                );
              })}
            <Next
              onClick={() => {
                setPage(loantransactions?.data?.last_page);
              }}
            />
          </>
        </Pagination>
      </div>
    </Panel>
  );
};
