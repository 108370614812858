/* eslint-disable react-hooks/exhaustive-deps */
import {
  BsCheck,
  BsChevronLeft,
  BsChevronUp,
  BsEye,
  BsThreeDots,
  BsX,
} from "react-icons/bs";
import { Link, useFetcher, useLoaderData, useParams } from "react-router-dom";
import Panel from "../../../Components/Dashboard/Panel";
import { TiArrowUnsorted } from "react-icons/ti";
import { useContext, useEffect, useRef, useState } from "react";
import useClickOutside from "../../../Hooks/useClickOutside";
import ToastContext from "../../../Routes/ToastContext";

const TransactionApproval = () => {
  const {
    data: { data: item },
  } = useLoaderData();

  const { Form, data, state } = useFetcher();

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message ?? "Server Error");
  }, [data]);

  const { flashToast } = useContext(ToastContext);

  const { data: dt, submit } = useFetcher();

  const { id } = useParams();

  const handleSubmit = (data) => {
    submit(data, { method: "put" });
  };

  const [entityToken, setEntityToken] = useState("");

  useEffect(() => {
    setEntityToken(item?.request_token);
  }, [item]);

  const autoResize = ({ target: e }) => {
    const offset = e.offsetHeight - e.clientHeight;
    e.style.height = "auto";
    e.style.height = e.scrollHeight + offset + "px";
  };

  useEffect(() => {
    flashToast(dt?.success, dt?.message);
  }, [dt]);

  return (
    <div>
      <div className="inline-flex items-center justify-between w-full">
        <Link
          className="self-start p-3 bg-white rounded-full"
          to={`/dashboard/transactions/${id}`}
        >
          <BsChevronLeft className="w-4 h-4" />
        </Link>
      </div>
      <Panel>
        <div className="w-full p-16">
          <Messages messages={item?.comments.slice(-2)} />
          <div className="flex items-center justify-start mt-16 space-x-20">
            <span className="text-2xl font-semibold">KYC</span>
            <OverallStatus value={item?.kyc_status} />
          </div>
          <p>Valid business Documentation</p>

          <div className="flex items-start justify-between w-full mt-16">
            <div>
              <div className="flex items-center justify-start mb-2 space-x-3">
                <h3 className="text-sm font-semibold">Bank Statement</h3>
                <Status value={item?.bank_statement_status} />
              </div>
              <div className="flex min-w-[350px] items-center  justify-center rounded-[16px] bg-purple-900/70 px-10 py-8">
                <Download to={item?.bank_statement_path} />
              </div>
            </div>
            <div>
              <UpdateStatus>
                <UpdateStatusButton
                  onClick={() =>
                    handleSubmit({
                      request_token: id,
                      document_type: "bank_statement",
                      status: 1,
                      comments: "",
                    })
                  }
                  Icon={BsCheck}
                >
                  Approve
                </UpdateStatusButton>
                <UpdateStatusButton
                  onClick={() =>
                    handleSubmit({
                      request_token: id,
                      document_type: "bank_statement",
                      status: 2,
                      comments: "",
                    })
                  }
                  Icon={BsX}
                >
                  Decline
                </UpdateStatusButton>
              </UpdateStatus>
            </div>
          </div>

          <div className="flex items-start justify-between w-full mt-16">
            <div>
              <div className="flex items-center justify-start mb-2 space-x-3">
                <h3 className="text-sm font-semibold">Recent Invoice</h3>
                <Status value={item?.recent_invoice_status} />
              </div>
              {item?.recent_invoice_paths?.map((v, i) => (
                <div
                  className="flex min-w-[350px] items-center  justify-center rounded-[16px] bg-purple-900/70 px-10 py-8"
                  key={i}
                >
                  <Download to={v} />
                </div>
              ))}
            </div>
            <div>
              <UpdateStatus>
                <UpdateStatusButton
                  onClick={() =>
                    handleSubmit({
                      request_token: id,
                      document_type: "recent_invoice",
                      status: 1,
                      comments: "",
                    })
                  }
                  Icon={BsCheck}
                >
                  Approve
                </UpdateStatusButton>
                <UpdateStatusButton
                  onClick={() =>
                    handleSubmit({
                      request_token: id,
                      document_type: "recent_invoice",
                      status: 2,
                      comments: "",
                    })
                  }
                  Icon={BsX}
                >
                  Decline
                </UpdateStatusButton>
              </UpdateStatus>
            </div>
          </div>

          <div className="flex items-start justify-between w-full mt-16">
            <div>
              <div className="flex items-center justify-start mb-2 space-x-3">
                <h3 className="text-sm font-semibold">Mandate Letter</h3>
                <Status value={item?.mandate_letter_status} />
              </div>
              <div className="flex min-w-[350px] items-center  justify-center rounded-[16px] bg-purple-900/70 px-10 py-8">
                <Download to={item?.mandate_letter_path} />
              </div>
            </div>
            <div>
              <UpdateStatus>
                <UpdateStatusButton
                  onClick={() =>
                    handleSubmit({
                      request_token: id,
                      document_type: "mandate_letter",
                      status: 1,
                      comments: "",
                    })
                  }
                  Icon={BsCheck}
                >
                  Approve
                </UpdateStatusButton>
                <UpdateStatusButton
                  onClick={() =>
                    handleSubmit({
                      request_token: id,
                      document_type: "mandate_letter",
                      status: 2,
                      comments: "",
                    })
                  }
                  Icon={BsX}
                >
                  Decline
                </UpdateStatusButton>
              </UpdateStatus>
            </div>
          </div>

          {parseInt(item?.kyc_status) !== 1 && (
            <Form method="post">
              <div className="w-full mt-20">
                <p className="text-sm font-semibold">Additional Information</p>
                <textarea
                  rows={5}
                  className="w-full px-2 py-1 mt-2 overflow-auto text-sm font-semibold border rounded-md appearance-none resize-none"
                  onInput={autoResize}
                  name="comments"
                />
                <input
                  type="hidden"
                  name="entity_token"
                  defaultValue={entityToken}
                />
              </div>

              <div className="mt-[66px] flex items-center justify-end space-x-[10px]">
                <button
                  type="reset"
                  className="rounded-[8px] border-[1.5px] border-[#D0D5DD] px-[36px] py-[16px] text-[14px] font-[700] leading-[21px]"
                >
                  Cancel
                </button>
                <button
                  disabled={state !== "idle"}
                  className="rounded-[8px] border-[1.5px] border-[#D0D5DD] bg-[#590DA4] px-[36px] py-[16px] text-[14px] font-[700] leading-[21px] text-white"
                >
                  {state === "idle" ? (
                    <span>Send Message</span>
                  ) : (
                    <BsThreeDots className="w-6 aspect-video animate-pulse" />
                  )}
                </button>
              </div>
            </Form>
          )}
        </div>
      </Panel>

      {parseInt(item?.kyc_status) === 1 && (
        <div className="mt-[66px] flex items-center justify-end space-x-[10px]">
          <button className="rounded-[8px] border-[1.5px] border-[#D0D5DD] bg-[#590DA4] px-[36px] py-[16px] text-[14px] font-[700] leading-[21px] text-white">
            Proceed to Payment
          </button>
        </div>
      )}
    </div>
  );
};

const Status = ({ value = 0 }) => {
  value = parseInt(value);
  const status = {
    1: {
      bgColor: "bg-[#590DA4]",
      label: "Approved",
      icon: BsCheck,
    },
    2: {
      bgColor: "bg-[#FF212E]",
      label: "Declined",
      icon: BsCheck,
    },
    0: {
      bgColor: "bg-[#590DA4]",
      label: "Pending",
      icon: BsCheck,
    },
  };

  if (!value) {
    return <></>;
  }
  const Icon = status[value].icon;

  return (
    <span
      className={`flex items-center justify-start space-x-2 rounded-lg ${status[value].bgColor} px-4 py-2 font-semibold text-white`}
    >
      {Icon({ className: "h-6 w-6" })}
      <span>{status[value].label}</span>
    </span>
  );
};

const OverallStatus = ({ value = 0 }) => {
  value = parseInt(value);
  const status = {
    1: {
      bgColor: "bg-[#EBECFE]",
      label: "Approved",
      color: "text-[#610BEF]",
    },
    2: {
      bgColor: "bg-red-200",
      label: "Declined",
      color: "text-red-500",
    },
    0: {
      bgColor: "bg-[#FFFBEB]",
      label: "Pending",
      color: "text-[#F59E0B]",
    },
  };

  return (
    <span
      className={`flex items-center justify-start space-x-2 rounded-full text-sm ${status[value].bgColor} px-2 py-1 ${status[value].color}`}
    >
      <span>{status[value].label}</span>
    </span>
  );
};

const UpdateStatus = ({ children }) => {
  const item = useRef();
  const [state, setState] = useState(false);

  useClickOutside(item, () => {
    setState(false);
  });

  return (
    <div className="relative" ref={item}>
      <button
        onClick={() => {
          setState((v) => !v);
        }}
        className="flex items-center justify-start px-2 py-2 text-sm font-semibold text-gray-900 border hover:text-gray-600 "
      >
        <span>Approval Status</span>
        <TiArrowUnsorted className="w-4 h-4" />
      </button>
      <div
        className="absolute mt-2 w-full rounded-md bg-white p-1 font-semibold opacity-0 shadow-2xl transition-all duration-300 data-[state=false]:hidden data-[state=true]:opacity-100"
        data-state={state}
      >
        {children}
      </div>
    </div>
  );
};

const UpdateStatusButton = ({ children, Icon, ...props }) => {
  return (
    <button
      {...props}
      className="flex items-center justify-start w-full px-2 py-1 space-x-2 text-sm text-left text-gray-700 rounded-md hover:bg-purple-500 hover:text-white"
    >
      {Icon && <Icon className="w-6 h-6" />}
      {children}
    </button>
  );
};

const Download = ({ to }) => {
  return (
    <Link
      to={to}
      target="_blank"
      rel="noreferrer"
      className="flex items-center justify-between px-8 py-2 space-x-2 font-semibold text-purple-900 bg-white rounded-lg"
    >
      <BsEye className="w-4 h-4" />
      <span>View</span>
    </Link>
  );
};

const Messages = ({ messages = [] }) => {
  const [state, setState] = useState(false);
  return (
    <div className="flex flex-col w-full">
      <button
        className="flex items-center justify-start space-x-4 text-[24px] font-[700] leading-[24px] text-[#590DA4]"
        onClick={() => {
          setState((v) => !v);
        }}
      >
        <span>Rejection Note</span>
        <BsChevronUp className={`aspect-square w-4 ${state && "rotate-180"}`} />
      </button>
      <div className={`w-full ${!state && "hidden"}`}>
        {messages.map((msg, index) => (
          <div
            key={index}
            className="flex min-h-[56px] items-center justify-start space-x-10 border-b border-gray-400/50 px-[69px] py-[9px]"
          >
            <p className="flex-grow w-full">{msg.comments}</p>
            <div className="w-1/4">
              <span>
                {new Date(msg.created_at).toLocaleDateString()}{" "}
                {new Date(msg.created_at).toLocaleTimeString()}
              </span>
            </div>
            <div className="flex flex-col items-start justify-center w-1/4">
              <span>
                {msg?.commentator?.first_name} {msg?.commentator?.last_name}
              </span>
              <span className="text-[12px] font-[400] leading-[12px] text-[#64748B]">
                {msg?.commentator?.user_role}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransactionApproval;
