const Select = ({ children, error, ...props }) => {
  return (
    <div className="relative mb-5 flex max-h-fit w-full flex-col items-start justify-start">
      {props.label && (
        <label
          className="mb-1 w-full text-base capitalize text-gray-700"
          style={{ fontWeight: 700, fontSize: "18px" }}
        >
          {props.label}
        </label>
      )}
      <select
        className="w-full rounded-md border border-gray-400/50 py-3 outline-none"
        {...props}
      >
        {children}
      </select>
      <div
        className={`text-sm font-semibold tracking-wide text-red-500 ${
          error ? "" : "text-transparent"
        }`}
      >
        {Array.isArray(error) && error.length > 1 && (
          <ul className="ml-5 list-disc">
            {error.map((e, k) => (
              <li key={k}>{e}</li>
            ))}
          </ul>
        )}
        {Array.isArray(error) && error.length === 1 && error}
      </div>
    </div>
  );
};

export default Select;
