import { useEffect, useRef, useState } from 'react'
import useClickOutside from '../../../Hooks/useClickOutside'
import GeneralService from '../../../Services/Dashboard/GeneralService'
import InvoiceInput from '../Invoice/InvoiceInput'

const Categories = (props) => {
  return (
    <div className='w-full mt-1 mb-3'>
      <CustomSelect
        className='-full mb-5 mt-2'
        {...props}
      />
    </div>
  )
}

const CustomSelect = ({ top = false, ...props }) => {
  const [search, setSearch] = useState('')
  const [field, setField] = useState(null)
  const [data, setData] = useState([])
  const details = useRef()
  const check = useRef()
  const loadOptions = (inputValue) => {
    GeneralService.categories(inputValue).then(response => setData(response?.data?.data ?? []))
  }

  useClickOutside(details, () => {
    details.current.removeAttribute('open')
  })

  useEffect(() => {
    loadOptions(search)
  }, [search])

  const isTop = top ? 'bottom-0 mb-16' : 'mb-2 '

  return (
    <div>
      <label className='mb-1 capitalize text-gray-700 text-base w-full' style={{ fontWeight: 700, fontSize: '18px' }}>
        {props.label}
      </label>
      <details className='relative text-[12px] leading-[18px] font-[700]' ref={details}>
        <summary className='list-none px-4 form-input focus:outline-none text-gray-500 tracking-wide border bg-transparent focus:border-purple-800 py-5 text-sm placeholder:text-gray-200  font-semibold placeholder:capitalize w-full'>{field ?? 'Search for business category'}</summary>
        <ul className={`absolute  w-full  shadow-lg bg-white z-40 max-h-80 overflow-y-auto ${isTop}`}>
          <li className='px-6 sticky top-0'><InvoiceInput
            normalize placeholder='Search for Business Category' type='text' onChange={e => {
              setSearch(e.target.value)
              setField(null)
              check.current.checked = true
            }} value={search}
                                            />
            <input type='radio' ref={check} {...props} className='hidden' value='' />
          </li>
          {data.map((v, i) =>
            <li key={i} className='w-full'>
              <label onClick={() => {
                setField(v.category_name)
                details.current.removeAttribute('open')
              }}
              >
                <input type='radio' {...props} className='hidden peer' value={v.category_name} />
                <span className='text-black w-full peer-checked:bg-blue-700 peer-checked:text-white hover:bg-blue-500 hover:text-white px-6 inline-block py-2 cursor-pointer'>{v.category_name}</span>
              </label>
            </li>
          )}
          {data.length <= 0 && <li className='text-black px-6 inline-block py-2 cursor-pointer'>No Data Available yet</li>}

        </ul>
      </details>
    </div>
  )
}
export default Categories
