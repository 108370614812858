import { useLoaderData } from "react-router-dom";
////file
import usePagination from "../Table/usePagination";
import useTable from "../Table/useTable";
import Panel from "../Panel";
import { formatAsNaira } from "../../FormatMoney";
import moment from "moment";
import { Link } from "react-router-dom";

function CalculateMonths(months) {
  if (isNaN(months)) return "Invalid Duration";
  return parseInt(months) === 1 ? `1 Month` : `${months} Months`;
}
export const SubscriptionTable = () => {
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
  const { Next, Prev, Button, Pagination, setPage, PageSize, Description } =
    usePagination();
  const { subscriptionTable } = useLoaderData();

  const PAGINATION_DATA = subscriptionTable?.data;
  return (
    <Panel className={`mt-4`}>
      <Table>
        <RowHeader>
          <ColumnHeader>Amount</ColumnHeader>
          <ColumnHeader>Start Date</ColumnHeader>
          <ColumnHeader>End Date</ColumnHeader>
          <ColumnHeader>Frequency Selection</ColumnHeader>
          <ColumnHeader>Frequency Duration</ColumnHeader>
          {/* <ColumnHeader>Plan</ColumnHeader> */}
          {/* <ColumnHeader>Name</ColumnHeader> */}
          <ColumnHeader>View User</ColumnHeader>
        </RowHeader>
        <tbody>
          {subscriptionTable?.data?.data.map((subscription, index) => (
            <Row key={index}>
              <Column>{formatAsNaira(subscription.amount)}</Column>
              <Column>
                {moment(subscription.created_at).format("DD-MM-YYYY")}
              </Column>
              <Column>
                {moment(subscription.end_date).format("DD-MM-YYYY")}
              </Column>
              <Column>{CalculateMonths(subscription?.frequency_duration) ?? "N/A"}</Column>
              <Column>{subscription?.frequency_selection ?? "N/A"}</Column>
              {/* <Column>{subscription?.package_name ?? "N/A"}</Column> */}
              {/*   
                  <Column>
                    <span className="whitespace-nowrap">
                      {subscription?.customer_fullname}
                    </span>
                  </Column> */}
              <Column>
                <Link
                  to={`/dashboard/cooperative/saveandgo/subscriptions/${subscription?.token}`}
                  className="text-sm text-blue-500 focus:outline-none"
                >
                  View More
                </Link>
              </Column>
            </Row>
          ))}
        </tbody>
      </Table>
      <div className="mt-4">
        <div className="mb-4 flex items-center gap-x-4">
          <span>
            Showing {PAGINATION_DATA?.from} to {PAGINATION_DATA?.to} of{" "}
            {PAGINATION_DATA?.total} entries
          </span>
          <PageSize numbers={[5, 10, 20, 50]} />
        </div>

        <Pagination>
          <>
            <Prev
              onClick={() => {
                setPage(1);
              }}
            />
            {Array(PAGINATION_DATA?.last_page)
              .fill(5)
              .map((v, i) => {
                return (
                  <Button
                    key={i}
                    isActive={PAGINATION_DATA?.current_page === i + 1}
                    onClick={() => {
                      setPage(i + 1);
                    }}
                  >
                    {i + 1}
                  </Button>
                );
              })}
            <Next
              onClick={() => {
                setPage(PAGINATION_DATA?.last_page);
              }}
            />
          </>
        </Pagination>
      </div>
    </Panel>
  );
};
