import ResponseObject from '../../DataObjects/ResponseObject'
import http from '../../Store/baseHttp'
import BaseController from './BaseController'

class MemberService extends BaseController {
  async index ({ request, params }) {
    const url = new URL(request.url)
    url.searchParams.append('name', url.searchParams.get('search') ?? '')
    try {
      let response = await http.get(
        '/admin/staff/list' + url.search + '&sort_type=DESC'
      )
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }

  static async template ({ request, params }) {
    try {
      let response = await http.get('/invoice/template')
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }

  static async email (formData) {
    try {
      let response = await http.post(
        '/admin/staff/onboard/email/resend',
        formData
      )
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }

  async post ({ request }) {
    const formData = Object.fromEntries(await request.formData())
    console.log(formData)
    try {
      let response = await http.post('/admin/staff/onboard', formData)
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }

  async put ({ request }) {
    const formData = Object.fromEntries(await request.formData())
    try {
      let response = await http.put('/admin/staff/update', formData)
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }

  async destroy ({ request }) {
    const formData = Object.fromEntries(await request.formData())
    try {
      let response = await http.put('/admin/staff/status/change', formData)
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }

  async patch ({ request }) {
    const formData = Object.fromEntries(await request.formData())
    try {
      let response = await http.delete(`/admin/staff/delete/${formData.id}`)
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }
}

export default MemberService
