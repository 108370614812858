import { TfiBell } from 'react-icons/tfi'
import Notification from './Notification'
import useToggler from '../../../Hooks/useToggler'
import useInterval from '../../../Hooks/useInterval'
import NotificationController from '../../../Services/Dashboard/NotificationController'
import { useEffect, useState } from 'react'

const NotificationIcon = () => {
  const [isOpen, handleClick] = useToggler(false)

  const [data, setData] = useState(null)

  useInterval(async () => {
    setData()
  }, 60000)

  const call = async () => {
    setData(await NotificationController.unread())
  }

  const markAllAsRead = async () => {
    await NotificationController.markAllAsRead()
    setData(null)
  }

  useEffect(() => {
    call()
  }, [])
  return (
    <div className='relative cursor-pointer ml-[30.75px] mr-[7px] w-[40.6px] h-[40.6px] shadow shadow-gray-100 rounded-full flex items-center justify-center'>
      <button className='focus:outline-none' onClick={handleClick}>
        <TfiBell className='w-[16px] h-[18px]' />
      </button>
      {data && data?.data?.length > 0 && (<div className='absolute rounded-full top-[13px] right-[13px] border border-white bg-[#ef4444] w-[8px] h-[8px]' />)}
      <Notification isOpen={isOpen} click={handleClick} data={data} markAllAsRead={markAllAsRead} />
    </div>
  )
}

export default NotificationIcon
