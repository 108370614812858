import { useActionData, useLocation, useSubmit } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Input from '../../Components/Forms/Input'

const ResendVerification = () => {
  const [count, setCount] = useState(30)

  const data = useActionData()
  const submit = useSubmit()

  const { search } = useLocation()
  const search1 = new URLSearchParams(search)

  const resend = () => {
    submit({ email: search1.get('email') }, {
      action: '/admin/verify/resend?email=' + search1.get('email'),
      method: 'post'
    })
  }

  useEffect(() => {
    const interval = count && setInterval(() => setCount(v => v - 1), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [count])

  useEffect(() => {
    if (data?.success) {

    }
  }, [data])

  return (
    <>
      <Input
        type='hidden'
        error={data?.error?.email}
      />
      <span className='text-green-600'>{data?.success && data?.message}</span>
      <p className='pt-6 pb-10'>
        Didn't receive any code?{' '}
        <button
          type='button'
          onClick={resend}
          className={`text-purple-800 font-semibold ${count > 0 && 'opacity-50'}`}
        >
          Resend Code (in {count}s)
        </button>
      </p>
    </>
  )
}

export default ResendVerification
