/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useFetcher, useFormAction, useNavigate } from "react-router-dom";
import Input from "../../Components/Forms/Input";
import Submit from "../../Components/Forms/Submit";
import { useEffect } from "react";
import HeaderLogo from "../../Components/LandingPage/HeaderLogo";
import useTitle from "../../Hooks/useTitle";
import useForm from "../../Hooks/useForm";

const ForgetPassword = () => {
  useTitle("Forgot Password | fedacash SMEs");
  const { formAction, data, state, Form, formData } = useFetcher();
  const navigate = useNavigate();
  const initialState = {
    email: "",
  };
  const [form, setForm] = useForm(initialState);
  useEffect(() => {
    if (data?.success) {
      navigate("/admin/password-reset-verify?email=" + form?.email);
    }
    console.log(form?.email)
  }, [data]);
  return (
    <div className="h-full w-full">
      <HeaderLogo />
      <div className="mb-20 mt-6 pt-10 md:mb-60">
        <Link to="/">
          <span style={{ color: "#590DA4", fontSize: "18px", fontWeight: 800 }}>
            Go Back Home
          </span>
        </Link>
      </div>
      <div className="my-10">
        <h1
          className="mb-20 mt-5"
          style={{ fontSize: "42px", fontWeight: 800 }}
        >
          Forgot Password
        </h1>
      </div>
      <Form method="post" action={formAction} formAction={useFormAction("put")}>
        <Input
          onChange={setForm}
          label="Email address"
          name="email"
          placeholder="Enter your email"
          error={data?.errors?.email}
        />
        <Submit isLoading={state !== "idle"}>Reset Password</Submit>
      </Form>
    </div>
  );
};

export default ForgetPassword;
