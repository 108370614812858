import {
  Await,
  Link,
  useLoaderData,
  useParams,
  useFetcher,
} from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Spinner } from "flowbite-react";
import { Suspense, useState, Fragment } from "react";
///file import
import PaidStatus from "../../../Components/Dashboard/PaidStatus";
import MoneyFormat from "../../../Components/Utils/MoneyFormat";
import UserObject from "../../../DataObjects/UserObject";
import http from "../../../Store/baseHttp";
import { BsChevronLeft, BsChevronRight, BsEye } from "react-icons/bs";
import Panel from "../../../Components/Dashboard/Panel";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
const TransactionView = () => {
  const { data: fetcherData, state, submit } = useFetcher();
  const { role_token } = UserObject.getUser().data;
  const [successModal, setSuccessModal] = useState(false);
  const [failModal, setFailModal] = useState(false);
  const [failData, setFailData] = useState("");

  const [loading, setLoading] = useState(false);

  const data = useLoaderData();
  const { id } = useParams();
  //close the modal fucntion
  function closeSucessModal() {
    setSuccessModal(false);
  }
  function closeFailModal() {
    setFailModal(false);
  }
  const handleSubmit = async (item) => {
    setLoading(true);
    async function fetchData(bankname) {
      try {
        const response = await http.get("/banks");
        const { data } = response;
        const bankArray = data?.data?.data;
        // console.log(data?.data?.data);
        const matchingBank = bankArray.find((bank) => bank.name === bankname);
        const bankCode = matchingBank.code;
        return bankCode;
      } catch (error) {
        // Handle errors here
        console.error("Error fetching data:", error);
      }
    }

    const code = await fetchData(item.organization.bank_name);
    let response = await http
      .post("/admin/request/approverequest", {
        invoice_number: id,
        user_profile_id: item.user_profile_id,
        bank_code: code,
        account_number: item.organization.account_number,
        account_name: item.organization.bank_name,
        invoice_amount: item.amount,
        advanced_amount_paid: (70 / 100) * item.amount,
        request_token: item.request_token,
        admin_token: role_token,
        fund_status: "partial",
      })
      .then((res) => {
        const responseData = res.data;
        if (responseData.success) {
          setLoading(false);
          setSuccessModal(true);
        }
      })
      .catch((error) => {
        // Handle errors if needed
        setFailData(error);
        setLoading(false);
        setFailModal(true);
        console.error("Error fetching data:", error);
      });
  };
  return (
    <div>
      <div className="inline-flex w-full items-center justify-between">
        <Link
          className="rounded-full bg-white p-3"
          to="/dashboard/transactions"
        >
          <BsChevronLeft className="h-4 w-4" />
        </Link>

        <Link
          className="rounded-full bg-white p-3"
          to={`/dashboard/transactions/${id}/approval`}
        >
          <BsChevronRight className="h-4 w-4" />
        </Link>
      </div>

      <Suspense fallback={<p>Loading...</p>}>
        <Await resolve={data}>
          {(data) => {
            // console.log(data);
            const item = data?.data?.data;
            return (
              <div className="mt-5">
                <div className="mb-10 flex items-center justify-start space-x-5">
                  <div className="inline-flex items-center justify-start rounded-xl bg-white p-3 shadow-sm">
                    <div className="mr-4 h-[48px] w-[48px] rounded-full border border-purple-900" />
                    <div className="flex flex-col items-start justify-center">
                      <p className="font-semibold">
                        {item?.creator?.first_name} {item?.creator?.last_name}
                      </p>
                      <p className="text-sm font-light text-gray-500">
                        {item?.company?.company_name}
                      </p>
                    </div>
                  </div>

                  <div className="inline-flex items-center justify-start rounded-xl bg-white p-3 shadow-sm">
                    <div className="flex flex-col items-start justify-center">
                      <p className="font-semibold">Invoice Wallet Balance</p>
                      <p className="text-sm font-light text-gray-500">
                        20,0000
                      </p>
                    </div>
                  </div>
                </div>
                <Panel>
                  <div className="grid grid-cols-1 gap-5 px-2 py-5 md:grid-cols-5">
                    <div className="col-span-1">
                      <h1 className="text-[16px] font-[500] leading-[24px]">
                        Invoice Number
                      </h1>
                      <p className="text-[20px] font-bold leading-[30px]">
                        {item?.invoice_number}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <h1 className="text-[16px] font-[500] leading-[24px]">
                        Created Date
                      </h1>
                      <p className="text-[18px] font-bold leading-[30px]">
                        {new Date(item?.created_at).toDateString()}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <h1 className="text-[16px] font-[500] leading-[24px]">
                        Due Date
                      </h1>
                      <p className="text-[18px] font-bold leading-[30px]">
                        {new Date(item?.due_date).toDateString() ??
                          new Date().toString()}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <h1 className="text-[16px] font-[500] leading-[24px]">
                        Fund Status
                      </h1>
                      <p className="text-[18px] font-bold leading-[30px]">
                        <PaidStatus value={item?.fund_status ?? 0} />
                      </p>
                    </div>
                    <div className="col-span-1">
                      <h1 className="text-[16px] font-[500] leading-[24px]">
                        Amount
                      </h1>
                      <p className="text-[18px] font-bold leading-[30px]">
                        ₦{MoneyFormat(item?.amount)}
                      </p>
                    </div>
                  </div>
                </Panel>
                <div className="mt-10">
                  <Panel>
                    <div className="grid grid-cols-1 gap-10 sm:grid-cols-3">
                      <div className="col-span-1">
                        <h1 className="text-[16px] font-[500] leading-[24px]">
                          Company
                        </h1>
                        <p className="text-[18px] font-bold leading-[30px]">
                          {item?.company?.company_name}
                        </p>
                      </div>
                      <div className="col-span-1">
                        <h1 className="text-[16px] font-[500] leading-[24px]">
                          Service Category
                        </h1>
                        <p className="text-[18px] font-bold leading-[30px]">
                          {item?.service_category}
                        </p>
                      </div>
                      <div className="col-span-1">
                        <div className="flex w-full items-center  justify-center rounded-[16px] bg-purple-900/70 px-10 py-8">
                          <Link
                            to={item?.invoice_url}
                            target="_blank"
                            rel="noreferrer"
                            className="flex items-center justify-between space-x-2 rounded-lg bg-white px-8 py-2 font-semibold text-purple-900"
                          >
                            <BsEye className="h-4 w-4" />
                            <span>View</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Panel>
                </div>
                <RiskMetric />

                {parseInt(item?.kyc_status) === 1 && (
                  <div className="flex flex-col items-end">
                    <div className="mt-[66px] flex items-center justify-end space-x-[10px]">
                      <button
                        className="flex items-center justify-center gap-x-2 rounded-lg border-2 border-[#D0D5DD] px-[36px] py-[16px] text-sm font-bold leading-6"
                        // onClick={() => handleSubmit(item)}
                      >
                        {/* {loading && (
                          <div className="">
                            <Spinner color="info" aria-label="It is loading" />
                          </div>
                        )} */}
                        Cancel Invoice Request
                      </button>
                      <button
                        disabled={loading}
                        className="flex items-center justify-center gap-x-2 rounded-lg border-2 border-[#D0D5DD] bg-[#590DA4] px-[36px] py-[16px] text-sm font-bold leading-6 text-white"
                        onClick={() => handleSubmit(item)}
                      >
                        {loading && (
                          <div className="">
                            <Spinner color="info" aria-label="It is loading" />
                          </div>
                        )}
                        Approve Invoice Request
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        </Await>
      </Suspense>
      <SuccessDialog closeModal={closeSucessModal} isOpen={successModal} />
      <FailDialog
        closeModal={closeFailModal}
        isOpen={failModal}
        data={failData}
      />
    </div>
  );
};

export default TransactionView;

const RiskMetric = () => {
  const data = useLoaderData();
  function replaceUnderscoresWithSpaces(inputString) {
    // Use the replace method with a regular expression to replace underscores with spaces globally
    return inputString.replace(/_/g, " ");
  }
  return (
    <Suspense fallback={<p>Loading...</p>}>
      <Await resolve={data}>
        {(data) => {
          const item = data?.assessment?.data?.data?.risk_assessment_result;
          const parameterArray = Object.entries(
            data?.assessment?.data?.data?.parameter_scores
          );
          console.log(item);
          return (
            <div className="mt-6 w-full space-y-6 rounded-md bg-white p-4 lg:p-8">
              <h2 className="text-2xl font-bold capitalize">
                Risk Assessment Metrics
              </h2>
              <div className="grid grid-cols-2 gap-x-3 gap-y-4">
                {parameterArray.map(([name, value], index) => (
                  <div className="space-y-3" key={index}>
                    <h4>
                      <span className="text-sm font-medium capitalize leading-tight text-gray-600">
                        {replaceUnderscoresWithSpaces(name)}
                      </span>
                      <span className="text-sm font-medium leading-tight text-red-600">
                        *
                      </span>
                    </h4>
                    <div className="inline-flex w-full items-center justify-start gap-2 rounded border border-indigo-100 bg-white p-4 shadow">
                      <div className="text-xs font-medium leading-tight text-black">
                        {value}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="space-y-3">
                <h4>
                  <span className="text-sm font-medium capitalize leading-tight text-gray-600">
                    {replaceUnderscoresWithSpaces("risk_assessment_result")}
                  </span>
                  <span className="text-sm font-medium leading-tight text-red-600">
                    *
                  </span>
                </h4>
                <div className="inline-flex w-full items-center justify-start gap-2 rounded border border-indigo-100 bg-white p-4 shadow">
                  <div className="text-xs font-medium leading-tight text-black">
                    {item}
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Await>
    </Suspense>
  );
};

function SuccessDialog({ closeModal, isOpen }) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-center text-lg font-medium leading-6 text-gray-900"
                  >
                    Confirmed
                  </Dialog.Title>
                  <div className="flex flex-col items-center justify-center space-y-4 text-center">
                    <AiFillCheckCircle fill="#590DA4" color="#fff" size={80} />
                    <p>
                      Invoice request has been approved now awaiting approval
                    </p>
                  </div>
                  <div className="mt-4 flex gap-4">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-[#590DA4] px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-[#590DA4] focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

function FailDialog({ closeModal, isOpen, data }) {
  const { response } = data;
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-center text-lg font-medium leading-6 text-gray-900"
                  >
                    Failed
                  </Dialog.Title>
                  <div className="flex flex-col items-center justify-center space-y-4 text-center">
                    <AiFillCloseCircle fill="#FF0000" color="#fff" size={80} />
                    <p>{response?.data?.message}</p>
                  </div>
                  <div className="mt-4 flex gap-4">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-[#590DA4] px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-[#590DA4] focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
