import {
  useLoaderData,
  useNavigate,
  useRouteLoaderData,
  useSubmit,
} from "react-router-dom";
import { Tab, Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { Fragment, useState } from "react";
import { BiPaperPlane } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";

///
import { LoanInput } from "../../../Components/Dashboard/Loan/LoanInput";
import { UserCard } from "../../../Components/Dashboard/Loan/UserCard";
import { LoanProfile } from "../../../Components/Dashboard/Loan/LoanProfile";
import { LoanQualifications } from "../../../Components/Dashboard/Loan/LoanQualifications";
import { formatNaira } from "../../../Components/FormatMoney";
import {
  CompleteLoanRequestPayment,
  ReviewLoanRequest,
  UpdateLoanRequest,
} from "../../../Services/Dashboard/LoanServices";
import Submit from "../../../Components/Forms/Submit";
import {
  ApproveRequestModal,
  DeclineRequestModal,
} from "../../../Components/Dashboard/Loan/UpdateRequest";

const LoanRequest = () => {
  const navigate = useNavigate();
  const data = useLoaderData();
  const submit = useSubmit();
  const [loading, setLoading] = useState(false);
  const [textValue, setTextValue] = useState("");

  const handleTextChange = (e) => {
    setTextValue(e.target.value);
  };
  const loanrequest = data?.loanrequest?.data;
  // console.log(loanrequest);

  const payRequest = async (loan_token) => {
    setLoading(true);
    const paymentResponse = await CompleteLoanRequestPayment(loan_token);
    if (paymentResponse?.success) {
      setLoading(false);
      // console.log(reviewResponse);
      navigate(".");
    } else {
      setLoading(false);
      // console.log("failed");
    }
  };
  // function checkLoanType(data){
  //   if(data?.loan_type ==="Salary Advance"&&data?.company_approval_status=== "Approved") return true
  //   else if(data?.loan_type !== "Salary Advance") return true;
  //   return false
  // }
  function checkLoanType2(data){
    if(data?.loan_type ==="Salary Advance"){
      if(data?.company_approval_status=== "Approved"){
        return true
      }
    else if(data?.company_approval_status !== "Approved") return false;
    }
    else if(data?.loan_type !== "Salary Advance") return true;
  }
  return (
    <section>
      <section className="flex flex-col justify-between gap-y-4 md:flex-row">
        <section className="flex flex-col gap-y-4">
          <UserCard userdata={loanrequest?.user_data} />
          <LoanProfile data={loanrequest} userdata={loanrequest?.user_data} />
        </section>
        <section className="w-full md:w-[80%] md:px-24">
          <LoanQualifications />
          {loanrequest?.request_status === "pending" && checkLoanType2(loanrequest) && (
            <>
              <div className="my-4 flex  gap-x-4">
                <DeclineRequestModal
                  loantoken={loanrequest?.loan_token}
                  amount={loanrequest?.amount}
                />

                <ApproveRequestModal
                  loantoken={loanrequest?.loan_token}
                  amount={loanrequest?.amount}
                />
              </div>
            </>
          )}
          <div className="mt-4 space-y-5">
          <LoanInput
              name={`CLSME Status`}
              value={loanrequest?.user_data?.clsme_member_status ?"Yes":"No"}
            />
          <LoanInput
              name={`Address Verification`}
              value={parseInt(loanrequest?.user_data?.address_verification_status) === 0 ?"No":"Yes"}
            />
            <LoanInput
              name={`Requested Amount`}
              value={formatNaira(loanrequest?.amount)}
            />
            <LoanInput
              name={`Approved Amount`}
              value={formatNaira(loanrequest?.approved_amount)}
            />
            <LoanInput
              name={`Total Repayment Amount`}
              value={formatNaira(loanrequest?.repayment_amount)}
            />
            <LoanInput
              name={`Interest Allocated`}
              value={loanrequest?.interest_given ?? ""}
            />
            <LoanInput
              name={`Reason for Loan`}
              value={loanrequest?.description}
            />
            <LoanInput
              name={`Loan Created At`}
              value={moment(loanrequest?.created_at).format(
                "dddd, Do MMMM YYYY: h:mmA"
              )}
            />
            <LoanInput
              name={`Loan Duration`}
              value={`${loanrequest?.duration} Days`}
            />
            <LoanInput
              name={`Recipient Account Name`}
              value={`${loanrequest?.recipient_account_name}`}
            />
            <LoanInput
              name={`Recipient Account Number`}
              value={`${loanrequest?.recipient_account_number}`}
            />
            <LoanInput
              name={`Recipient Bank Name`}
              value={`${loanrequest?.bank_name}`}
            />
          </div>

          <div className="my-4 flex justify-end gap-x-4">
            <button
              className="inline-flex h-12 shrink-0 items-start justify-start gap-2 rounded-lg border border-gray-300 px-9 py-4"
              onClick={() => navigate(-1)} // Wrap navigate(-1) within an arrow function
            >
              <span className="text-sm font-bold leading-tight">Go back</span>
            </button>
            {loanrequest?.request_status === "Approved" && (
              <BankDetails loanrequest={loanrequest} />
            )}
            {loanrequest?.request_status === "approved" && (
              <Submit
                isLoading={loading}
                onClick={() => payRequest(loanrequest?.loan_token)}
              >
                Pay Request
              </Submit>
            )}
          </div>
        </section>
      </section>
    </section>
  );
};

export default LoanRequest;

const BankDetails = ({ loanrequest }) => {
  const navigate = useNavigate();

  const {
    request_status,
    loan_token,
    user_data,
    recipient_account_name,
    recipient_account_number,
    bank_name,
    bank_code,
  } = loanrequest;
  ////
  const [loading, setLoading] = useState(false);
  const [bankIndex, setBankIndex] = useState(1);
  const [error, setError] = useState("");
  const [isWithdrawalOpen, setIsWithdrawalOpen] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    account_number: "",
    account_name: "",
    bank_code: "",
  });

  const toggleWithdrawalModal = () => {
    setIsWithdrawalOpen((prev) => !prev);
  };
  function closeStatusModal() {
    setIsStatusOpen(false);
  }
  function openStatusModal() {
    setIsStatusOpen(true);
  }
  const updateLoanRequest = async () => {
    setLoading(true);
    const requestResponse = await UpdateLoanRequest({
      request_status,
      loan_token,
      account_name: recipient_account_name,
      account_number: recipient_account_number,
      bank_name,
      bank_code,
    }).then((res) => {
      // console.log(res)
      if (res?.loanrequest?.success) {
        // console.log(res)
        setLoading(false);
        toggleWithdrawalModal();
        // console.log(reviewResponse);
        navigate(".");
      } else {
        setLoading(false);
        // console.log("failed");
      }
    });
  };
  return (
    <div>
      <button
        onClick={toggleWithdrawalModal}
        className="inline-flex h-12 items-start justify-start gap-2 rounded-lg bg-fedacash-header px-9 py-4"
      >
        <span className="text-sm font-bold leading-tight text-white">
          Proceed to Payment
        </span>
      </button>
      <Transition appear show={isWithdrawalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={toggleWithdrawalModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex justify-evenly gap-3 text-lg font-bold capitalize leading-6 text-[#475467]"
                  >
                    Select bank account
                    <button
                      type="button"
                      className=""
                      onClick={() => toggleWithdrawalModal()}
                    >
                      <AiOutlineClose />
                    </button>
                  </Dialog.Title>
                  <div className="mt-4">
                    <div>
                      <div
                        onClick={() => setBankIndex(1)}
                        className={`flex flex-col items-start justify-start rounded-md px-4 py-3 ${
                          bankIndex === 1 ? "bg-[#DECFED]" : "bg-[#F6F8FA]"
                        }`}
                      >
                        <p className="text-lg font-medium">
                          {recipient_account_name}
                        </p>
                        <p className="">{bank_name}</p>
                        <p className="">{recipient_account_number}</p>
                      </div>
                      <p className="py-2 text-xs text-red-700 md:text-sm">
                        {error}
                      </p>
                      <div className="mt-4">
                        <Submit
                          isLoading={loading}
                          onClick={() => updateLoanRequest()}
                          className="lg:textbase w-full rounded-md bg-fedacash-header py-2 text-center text-sm text-white md:px-2 md:py-3"
                        >
                          Proceed
                        </Submit>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* <StatusDialog
        closeModal={closeStatusModal}
        isOpen={isStatusOpen}
        message={message}
      /> */}
    </div>
  );
};
