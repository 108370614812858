import { Link, matchPath, useLocation } from "react-router-dom";

const NavigatorItem = ({ to, label, value }) => {
  const location = useLocation();
  const active = matchPath(to, location.pathname) ? true : null;
  //   console.log(location.pathname === to, location.pathname, to);
  return (
    <div className="start group m-5 flex flex-auto items-center last:w-fit last:flex-1 last:self-end">
      <Link
        className="group flex items-center justify-start space-x-[20px]"
        data-active={active}
        to={to}
      >
        <div className="flex aspect-square h-[50px] items-center justify-center rounded-full bg-[#4B5768] text-white group-data-[active=true]:bg-[#590DA4]">
          <span>{value}</span>
        </div>
        <span className="whitespace-nowrap text-[18px] font-[700] leading-[21px]">
          {label}
        </span>
      </Link>
      <div className="mx-[20px] hidden w-full max-w-full flex-grow border-b border-gray-700 group-last:hidden sm:block" />
    </div>
  );
};

export default NavigatorItem;
