import { Dropdown, Popconfirm } from 'antd'
import { Suspense, useCallback, useContext, useEffect, useState } from 'react'
import { BsSearch, BsThreeDots, BsX } from 'react-icons/bs'
import { Await, useFetcher, useLoaderData, useSubmit } from 'react-router-dom'
import PageHeader from '../../../Components/Dashboard/PageHeader'
import Panel from '../../../Components/Dashboard/Panel'
import useTable from '../../../Components/Dashboard/Table/useTable'
import Input from '../../../Components/Forms/Input'
import Submit from '../../../Components/Forms/Submit'
import PaginateAll from '../../../Components/Utils/PaginateAll'
import ToastContext from '../../../Routes/ToastContext'
import InvoiceEmpty from '../../../Assets/images/InvoiceEmpty.png'
import Search from '../../../Components/Dashboard/Search'
import Portal from '../../../Components/Dashboard/Portal'
import useTitle from '../../../Hooks/useTitle'
import RolesService from '../../../Services/Dashboard/RolesService'
import UserRolesService from '../../../Services/Dashboard/UserRolesService'

const Roles = () => {
  useTitle('Roles')
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable()
  const items = useLoaderData()

  const [editId, setEditId] = useState(null)
  const [permissionId, setPermissionId] = useState(null)
  const submit = useSubmit()

  return (
    <div className='w-full'>
      <PageHeader>Role Manager</PageHeader>
      <div
        id='portal'
        className='w-full my-4 flex items-center justify-end'
      />
      <Panel>
        <Table>
          <RowHeader>
            <ColumnHeader>Name</ColumnHeader>
            <ColumnHeader>Status</ColumnHeader>
            <ColumnHeader>Created At</ColumnHeader>
            <ColumnHeader>Action</ColumnHeader>
          </RowHeader>
          <Body data={items}>
            {(data) =>
              data?.data?.data?.map((v, i) => (
                <Row key={i}>
                  <Column>
                    <span className='whitespace-nowrap'>{v?.roleName}</span>
                  </Column>
                  <Column>
                    <Status status={v.roleStatus} />
                  </Column>
                  <Column>{new Date(v?.created_at).toDateString()}</Column>
                  <Column>
                    <Dropdown
                      trigger={['click']}
                      menu={{
                        items: [
                          {
                            key: '1',
                            label: (
                              <button
                                onClick={() => setEditId(v)}
                                className='focus:outline-none text-sm text-blue-500'
                              >
                                Edit Role
                              </button>
                            )
                          },
                          {
                            key: '2',
                            label: (
                              <button
                                className='text-sm text-blue-500 focus:outline-none'
                                onClick={() => setPermissionId(v)}
                              >
                                Edit Permissions
                              </button>
                            )
                          },
                          {
                            key: '3',
                            label: (
                              <Popconfirm
                                title={`${
                                  v.roleStatus === '1'
                                    ? 'Deactivate'
                                    : 'Activate'
                                } ${v.roleName}`}
                                onConfirm={() => {
                                  submit(
                                    {
                                      token: v.token,
                                      roleStatus: v.roleStatus === '1' ? 2 : 1
                                    },
                                    {
                                      method: 'delete'
                                    }
                                  )
                                }}
                                okText='Yes'
                                className='text-black'
                              >
                                {v.roleStatus === '1'
                                  ? 'Deactivate'
                                  : 'Activate'}
                              </Popconfirm>
                            )
                          }
                        ]
                      }}
                      placement='bottomRight'
                      arrow={{ pointAtCenter: true }}
                    >
                      <div className='flex justify-center items-center'>
                        <BsThreeDots className='w-4 h-4' />
                      </div>
                    </Dropdown>
                  </Column>
                </Row>
              ))}
          </Body>
        </Table>
        <MembersEmptyState items={items} />
        <EditMemberButton view={editId} />
        <Permission view={permissionId} setView={setPermissionId} />
      </Panel>
      <PaginateAll items={items} />
    </div>
  )
}

const Status = ({ status }) => {
  const colors = [
    {
      color: 'text-yellow-700',
      key: 'Pending',
      background: 'bg-yellow-200/50'
    },
    { color: 'text-green-700', key: 'Active', background: 'bg-green-200/50' },
    { color: 'text-red-700', key: 'Disabled', background: 'bg-red-200/50' }
  ]

  return (
    <span
      className={`${colors[status]?.color} capitalize ${colors[status]?.background} rounded-full text-xs px-3 py-1 `}
    >
      {colors[status]?.key}
    </span>
  )
}

const MembersEmptyState = ({ items }) => {
  return (
    <Suspense fallback={<div />}>
      <Await resolve={items?.data}>
        {(data) => {
          if (data === undefined || data?.data?.data.length <= 0) {
            return (
              <div className='bg-white rounded-[16px] min-h-[400px] md:min-h-[600px] text-center flex justify-center flex-col items-center'>
                <img src={InvoiceEmpty} alt='Transactions' />
                <p className='mt-5 text-[#64748B]'>
                  You don’t have any transaction <br /> at the time
                </p>
                <CreateMemberButton />
              </div>
            )
          }

          return (
            <Portal selector='#portal'>
              <div className='inline-flex items-baseline justify-end w-full space-x-2'>
                <Search />
                <CreateMemberButton />
              </div>
            </Portal>
          )
        }}
      </Await>
    </Suspense>
  )
}

const CreateMemberButton = () => {
  const [state, setState] = useState(false)
  return (
    <>
      <button
        onClick={() => setState((v) => !v)}
        className='bg-black text-white px-6 py-2 rounded-[8px] flex items-center justify-center'
      >
        <span>+ Create Role</span>
      </button>
      <div
        data-active={state}
        className=' data-[active=false]:hidden top-0 left-0 z-[999] flex items-start pt-10 justify-center fixed w-full h-screen overflow-y-auto bg-purple-700/70'
      >
        <div className='relative max-w-sm w-full bg-white py-8 rounded-[16px]'>
          <button
            onClick={() => setState((v) => !v)}
            className='absolute -left-3 -top-3 rounded-full bg-white'
          >
            <BsX className='w-6 h-6' />
          </button>
          <MemberModal setState={setState} />
        </div>
      </div>
    </>
  )
}

const MemberModal = ({ setState }) => {
  const { Form, data, state } = useFetcher()
  const { flashToast } = useContext(ToastContext)

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message)
    if (data?.success) {
      setState((v) => !v)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <h1 className='px-6 text-xl text-left font-semibold'>New Member</h1>
      <div className='mt-10'>
        <div className='w-full text-left'>
          <div className='px-6 py-4'>
            <Form method='post'>
              {/* <Input normalize={true} name='first_name' label="first name" error={data?.errors?.first_name} />
                              <Input normalize={true} name='last_name' label="Last name" error={data?.errors?.last_name} /> */}
              <Input
                name='roleName'
                label='Role Name'
                type='text'
                error={data?.errors?.email}
                normalize
              />
              <div className='flex items-center justify-end w-full space-x-2 mt-2'>
                <button
                  onClick={() => {
                    setState((v) => !v)
                  }}
                  type='reset'
                  className='py-3 w-full px-4 rounded-md tracking-wider text-base min-h-[59px] font-bold border-[#590DA4] border text-[#590DA4] hover:text-white disabled:opacity-50 hover:bg-purple-900'
                >
                  Cancel
                </button>
                <Submit type='submit' isLoading={state !== 'idle'}>
                  Submit
                </Submit>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

const ViewMemberButton = ({ view }) => {
  const [state, setState] = useState(false)

  useEffect(() => {
    if (view) {
      setState(true)
    }
  }, [view])

  return (
    <>
      <div
        data-active={state}
        className=' data-[active=false]:hidden top-0 left-0 z-[999] flex items-start pt-10 justify-center fixed w-full h-screen overflow-y-auto bg-purple-700/70'
      >
        <div className='relative max-w-sm w-full bg-white py-8 rounded-[16px]'>
          <button
            onClick={() => setState((v) => !v)}
            className='absolute -left-3 -top-3 rounded-full bg-white'
          >
            <BsX className='w-6 h-6' />
          </button>
          <MemberViewModal setState={setState} view={view} />
        </div>
      </div>
    </>
  )
}

const MemberViewModal = ({ setState, view }) => {
  const { data } = useFetcher()
  const { flashToast } = useContext(ToastContext)

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message)
    if (data?.success) {
      setState((v) => !v)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <h1 className='px-6 text-xl text-left font-semibold'>New Member</h1>
      <div className='mt-5'>
        <div className='w-full text-left'>
          <div className='px-6 py-4 flex flex-col space-y-4 items-center justify-start w-full'>
            <div className='w-[72px] h-[72px] rounded-full border border-blue-500 bg-gray-500' />
            <div className='text-left w-full'>
              <h3 className='font-[700] text-[18px] leading-[27px] text-[#4B5768]'>
                Name
              </h3>
              <p className='font-[700] text-[18px] leading-[27px] text-[#0D0F11]'>
                {view?.first_name} {view?.last_name}
              </p>
            </div>
            <div className='text-left w-full'>
              <h3 className='font-[700] text-[18px] leading-[27px] text-[#4B5768]'>
                Email
              </h3>
              <p className='font-[700] text-[18px] leading-[27px] text-[#0D0F11]'>
                {view?.email}
              </p>
            </div>
            <div className='text-left w-full'>
              <h3 className='font-[700] text-[18px] leading-[27px] text-[#4B5768]'>
                Phone{' '}
              </h3>
              <p className='font-[700] text-[18px] leading-[27px] text-[#0D0F11]'>
                {view?.phone_number}
              </p>
            </div>
            <div className='text-left w-full'>
              <Status status={view?.active_status} />
            </div>
            <div className='flex items-center justify-end w-full'>
              <button
                onClick={() => {
                  setState(false)
                }}
                className='px-6 text-sm py-3 border-2 text-black border-gray-400/50 font-semibold rounded'
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const EditMemberButton = ({ view }) => {
  const [state, setState] = useState(false)

  useEffect(() => {
    if (view) {
      setState(true)
    }
  }, [view])

  return (
    <>
      <div
        data-active={state}
        className=' data-[active=false]:hidden top-0 left-0 z-[999] flex items-start pt-10 justify-center fixed w-full h-screen overflow-y-auto bg-purple-700/70'
      >
        <div className='relative max-w-sm w-full bg-white py-8 rounded-[16px]'>
          <button
            onClick={() => setState((v) => !v)}
            className='absolute -left-3 -top-3 rounded-full bg-white'
          >
            <BsX className='w-6 h-6' />
          </button>
          <MemberEditModal setState={setState} view={view} />
        </div>
      </div>
    </>
  )
}

const MemberEditModal = ({ setState, view }) => {
  const { Form, data, state } = useFetcher()
  const { flashToast } = useContext(ToastContext)

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message)
    if (data?.success) {
      setState((v) => !v)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <h1 className='px-6 text-xl text-left font-semibold'>New Member</h1>
      <div className='mt-5'>
        <div className='w-full text-left'>
          <div className='px-6 py-4'>
            <Form method='put'>
              <input type='hidden' name='token' value={view?.token} />
              <Input
                normalize
                name='roleName'
                label='Name'
                defaultValue={view?.roleName}
                error={data?.errors?.roleName}
              />
              <div className='flex items-center justify-end w-full space-x-2 mt-2'>
                <button
                  onClick={() => {
                    setState((v) => !v)
                  }}
                  type='reset'
                  className='py-3 w-full px-4 rounded-md tracking-wider text-base min-h-[59px] font-bold border-[#590DA4] border text-[#590DA4] hover:text-white disabled:opacity-50 hover:bg-purple-900'
                >
                  Cancel
                </button>
                <Submit type='submit' isLoading={state !== 'idle'}>
                  Submit
                </Submit>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

const Permission = ({ view, setView }) => {
  const [state, setState] = useState(false)
  const { flashToast } = useContext(ToastContext)

  const getPermission = (id) => {
    RolesService.permissionById(id).then((r) => {
      const data = r?.data?.rolePermissionData?.map((v) => ({
        key: v.token,
        label: v.permission
      }))
      setAssignedPermission(data)
    })
  }

  useEffect(() => {
    if (view) {
      setState(true)
      getPermission(view.token)
    }
  }, [view])

  const [permission, setPermission] = useState([])

  const [allPermission, setAllPermission] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [assignedPermission, setAssignedPermission] = useState([])
  const [rAssignedPermission, setRAssignedPermission] = useState([])

  const getAllPermission = () => {
    RolesService.permissions().then((r) => {
      const data = r?.data?.data.map((v) => ({
        key: v.token,
        label: v.permission
      }))
      setAllPermission(data)
    })
  }

  useEffect(() => {
    getAllPermission()
  }, [])

  const handleChecked = (e) => {
    setPermission((p) => {
      if (p.indexOf(e.target.value) === -1) {
        p.push(e.target.value)
      } else {
        p.splice(p.indexOf(e.target.value), 1)
      }
      return p
    })
  }

  const handleAssignedChecked = (e) => {
    setRAssignedPermission((p) => {
      if (p.indexOf(e.target.value) === -1) {
        p.push(e.target.value)
      } else {
        p.splice(p.indexOf(e.target.value), 1)
      }
      return p
    })
  }

  const assignPermission = (id) => {
    setIsLoading(true)
    UserRolesService.assignPermissionToRole({
      token: id,
      permissions: permission.concat(assignedPermission.map((v) => v.key))
    }).then((r) => {
      flashToast(r.success, r.message)
      setPermission([])
      setIsLoading(false)
      setAllPermission([])
      getAllPermission()
      getPermission(id)
    })
  }

  const removePermission = (id) => {
    setIsLoading(true)
    UserRolesService.assignPermissionToRole({
      token: id,
      permissions: assignedPermission
        .map((v) => v.key)
        .filter((v) => !rAssignedPermission.includes(v))
    }).then((r) => {
      flashToast(r.success, r.message)
      setRAssignedPermission([])

      setIsLoading(false)
      setAllPermission([])
      setAssignedPermission([])
      getAllPermission()
      getPermission(id)
    })
  }

  return (
    <div
      className={`fixed z-50 bg-purple-700/70 min-w-full min-h-screen top-0 left-0 ${
        state ? 'flex' : 'hidden'
      } items-start pt-20 justify-center h-full overflow-y-auto`}
    >
      <div className='max-w-5xl mx-10 rounded-[16px] px-[44.8px] py-[36.96px] bg-white w-full relative'>
        <button
          className='absolute -mt-12 -ml-14 rounded-full'
          onClick={() => {
            setState(false)
            // setView(null);
          }}
        >
          <BsX className='bg-white p-1 w-8 h-8' />
        </button>
        <h3>Manage permission for {view?.roleName}</h3>
        <hr className='mt-3 mb-6' />
        <div className='grid grid-cols-1 lg:grid-cols-5 gap-10'>
          <PermissionSection
            label='All Permissions'
            items={allPermission?.filter(
              (v) => !assignedPermission?.map((i) => i.key).includes(v.key)
            )}
            onChange={handleChecked}
            checked={false}
          />
          <div className='flex items-center justify-center h-full w-full flex-col space-y-10 text-sm'>
            <button
              className='bg-[#88C02C] hover:bg-green-600
               text-center px-4 py-3 w-full rounded-lg text-white font-semibold disabled:opacity-50'
              onClick={() => {
                assignPermission(view.token)
              }}
              disabled={isLoading}
            >
              Add
            </button>
            <button
              className='bg-[#FD0D1B] hover:bg-red-600 text-center px-4 py-3 w-full rounded-lg text-white font-semibold disabled:opacity-50'
              disabled={isLoading}
              onClick={() => {
                removePermission(view.token)
              }}
            >
              Remove
            </button>
          </div>
          <PermissionSection
            label='Assigned Permissions'
            items={assignedPermission}
            onChange={handleAssignedChecked}
          />
        </div>
      </div>
    </div>
  )
}

const PermissionSection = ({ children, items, label, ...props }) => {
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])

  const handleSearch = useCallback(() => {
    setData((s) =>
      items?.filter((v) => {
        if (search === '') {
          return true
        }
        return v?.label?.toLowerCase()?.indexOf(search.toLowerCase()) > -1
      })
    )
  }, [items, search])

  useEffect(() => {
    handleSearch()
  }, [handleSearch])
  return (
    <div className='col-span-2 flex flex-col w-full space-y-3'>
      <h4 className='font-semibold tracking-wide'>{label}</h4>
      <div className='font-[700] w-full text-[12px] leading-[18px] py-[11px] px-4 placeholder:text-[#b8c0cc] border-[1.5px] rounded-[8px] border-[#b8c0cc] focus:outline-none flex items-center justify-start'>
        <BsSearch className='w-4 aspect-square text-[#b8c0cc] mr-2' />
        <input
          placeholder='Search...'
          className='focus:outline-none border-none w-full h-full font-semibold tracking-wide text-gray-800'
          value={search}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
        />
      </div>
      <div className='w-full h-96 bg-[#E7EAEE] rounded-[8px] shadow-inner p-4 flex flex-col items-start justify-start space-y-2 overflow-y-auto'>
        {data?.map((v, i) => (
          <SelectPermissions {...props} value={v.key} key={i}>
            {v.label}
          </SelectPermissions>
        ))}
      </div>
    </div>
  )
}

const SelectPermissions = ({ children, value, checked, ...props }) => {
  return (
    <label className='inline-flex items-center justify-start cursor-pointer peer-hover:text-purple-700'>
      <input
        type='checkbox'
        className='appearance-none w-3 h-3 rounded-sm checked:bg-purple-600 hover:border-purple-500 peer disabled:opacity-50'
        value={value}
        {...props}
        defaultChecked={checked}
      />
      <span className='text-xs font-semibold text-gray-500 tracking-tight ml-2 peer-checked:text-purple-800 select-none'>
        {children}
      </span>
    </label>
  )
}

export default Roles
