/* eslint-disable react-hooks/exhaustive-deps */
import Submit from '../../Components/Forms/Submit'
import SingleInput from '../../Components/Forms/SingleInput'
import {
  Link,
  Outlet,
  useFetcher,
  useLocation,
  useNavigate
} from 'react-router-dom'
import { useEffect } from 'react'
import HeaderLogo from '../../Components/LandingPage/HeaderLogo'
import Input from '../../Components/Forms/Input'

const Verification = () => {
  const { Form, formAction, state, data } = useFetcher()
  const navigate = useNavigate()

  const { search } = useLocation()
  const search1 = new URLSearchParams(search)

  useEffect(() => {
    if (data?.success) {
      return navigate('/admin/login')
    }
    return navigate('/admin/verify/resend?email=' + search1.get('email'))
  }, [data])

  return (
    <div className='mb-20 h-full w-full'>
      <HeaderLogo />
      <div className='mt-10 mb-36 md:mb-52'>
        <Link to='/'>
          <span style={{ color: '#590DA4', fontSize: '18px', fontWeight: 900 }}>
            Go Back Home
          </span>
        </Link>
      </div>
      <div className='w-full'>
        <h1 className='mb-2' style={{ fontSize: '44px', fontWeight: 800 }}>
          Verification
        </h1>
        <p className='mt-5 mb-10'>
          A verification Code has been sent to{' '}
          <strong>{search1.get('email')}</strong>. Check your mail
        </p>
        <Form action={formAction} method='post'>
          <SingleInput name='verification_code' />
          <Input
            type='hidden'
            name='email'
            value={search1.get('email') ?? ''}
            error={data?.error?.email}
          />
          <Outlet />
          <input
            type='hidden'
            name='email'
            value={search1.get('email') ?? ''}
          />
          <p className='text-red-600'>{!data?.success && data?.message}</p>
          <Submit isLoading={state !== 'idle'}>Verify Account</Submit>
        </Form>
      </div>
    </div>
  )
}

export default Verification
