import ResponseObject from "../../DataObjects/ResponseObject";
import http from "../../Store/baseHttp";
import BaseController from "./BaseController";

class SupportService extends BaseController {
  async index({ request, params }) {
    try {
      let response = await http.get("/support?sort_type=DESC");
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  static async users(search) {
    try {
      let response = await http.get("/admin/staff/list?name=" + search);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  static async view({ request, params }) {
    try {
      let response = await http.get("/admin/support/" + params.id);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async post({ request, params }) {
    const formData = Object.fromEntries(await request.formData());

    const formData2 = new FormData();

    // if attachment is file, add it to array and set it back

    formData2.append("support_token", formData.support_token);
    formData2.append("message", formData.message);

    if (formData.attachments instanceof File) {
      if (formData.attachments.size > 0) {
        formData2.append("attachments[]", formData.attachments);
      } else {
        delete formData.attachments;
      }
    }

    // if attachment is FileList (multiple files), add it to array and set it back
    if (formData.attachments instanceof FileList) {
      for (let i = 0; i < formData.attachments.length; i++) {
        formData2.append("attachments[]", formData.attachments[i]);
      }
    }

    try {
      let response = await http.post("admin/support/reply", formData2, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async put({ request, params }) {
    const formData = Object.fromEntries(await request.formData());
    try {
      let response = await http.put("admin/support/assign", formData);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }
}

export default SupportService;
