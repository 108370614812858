import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import UserObject from '../DataObjects/UserObject'
import { removeFromStore } from '../Helpers/Storage'

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate()

  useEffect(() => {
    const user = UserObject.getUser()

    if (!user?.token) {
      navigate('/admin/login')
      return
    }

    if (!user.isVerified()) {
      removeFromStore('user')
      navigate('/admin/verify')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children
}

export default ProtectedRoute
