import { Suspense, useState } from "react";
import { Await, Link, useLoaderData } from "react-router-dom";
import useTable from "../../../Components/Dashboard/Table/useTable";
import InvoiceEmpty from "../../../Assets/images/InvoiceEmpty.png";
import Support from "./Support";
import { BsThreeDots, BsX } from "react-icons/bs";
import Panel from "../../../Components/Dashboard/Panel";
import { Dropdown } from "antd";

const SupportIndex = () => {
  const items = useLoaderData();

  const { Table, RowHeader, ColumnHeader, Column, Row, Body } = useTable();

  return (
    <Panel>
      <Table>
        <RowHeader>
          <ColumnHeader>Ticket Id</ColumnHeader>
          <ColumnHeader>Category</ColumnHeader>
          <ColumnHeader>Sub Category</ColumnHeader>
          <ColumnHeader>Incidents</ColumnHeader>
          <ColumnHeader>Timestamp</ColumnHeader>
          <ColumnHeader>Status</ColumnHeader>
          <ColumnHeader>Action</ColumnHeader>
        </RowHeader>
        <Body data={items}>
          {(data) =>
            data?.data?.data?.map((v, i) => (
              <Row key={i}>
                <Column>#00{v.id}</Column>
                <Column>{v.category}</Column>
                <Column>{v.sub_category}</Column>
                <Column>
                  <p className="whitespace-wrap w-24 truncate">{v.message}</p>
                </Column>
                <Column>{new Date(v.created_at).toDateString()}</Column>
                <Column>
                  <Status value={v.resolution_status} />
                </Column>
                <Column>
                  <Dropdown
                    trigger={["click"]}
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: (
                            <Link to={`/dashboard/help-and-support/${v.token}`}>
                              {" "}
                              View{" "}
                            </Link>
                          ),
                        },
                      ],
                    }}
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                  >
                    <div className="flex items-center justify-center">
                      <BsThreeDots className="h-4 w-4" />
                    </div>
                  </Dropdown>
                </Column>
              </Row>
            ))
          }
        </Body>
      </Table>
      <SupportEmptyState items={items} />
    </Panel>
  );
};

const SupportEmptyState = ({ items }) => {
  return (
    <Suspense fallback={<div />}>
      <Await resolve={items?.data}>
        {(data) => {
          if (data === undefined || data?.data?.data.length <= 0) {
            return (
              <div className="flex min-h-[400px] flex-col items-center justify-center rounded-[16px] bg-white text-center md:min-h-[600px]">
                <img src={InvoiceEmpty} alt="Transactions" />
                <p className="mb-5 mt-5 text-[#64748B]">
                  You don’t have any incident <br /> at the time
                </p>
                <CreateRequestButton />
              </div>
            );
          }
        }}
      </Await>
    </Suspense>
  );
};

const CreateRequestButton = () => {
  const [state, setState] = useState(false);
  return (
    <>
      <button
        onClick={() => setState((v) => !v)}
        className="flex items-center justify-center rounded-[8px] bg-black px-6 py-3 text-white"
      >
        <span>+ Log Support Request</span>
      </button>
      <div
        data-active={state}
        className=" fixed left-0 top-0 z-[999] flex h-screen w-full items-start justify-center overflow-y-auto bg-purple-700/70 pt-10 data-[active=false]:hidden"
      >
        <div className="relative w-full max-w-md rounded-[16px] bg-white py-8 md:max-w-4xl">
          <button
            onClick={() => setState((v) => !v)}
            className="absolute -left-3 -top-3 rounded-full bg-white"
          >
            <BsX className="h-6 w-6" />
          </button>
          <Support setState={setState} />
        </div>
      </div>
    </>
  );
};

const Status = ({ value = 0 }) => {
  const type = {
    0: {
      color: "bg-gray-300 text-gray-800",
      value: "Unassigned",
    },
    1: {
      color: "bg-purple-300 text-purple-800",
      value: "Assigned",
    },
    2: {
      color: "bg-yellow-300 text-yellow-800",
      value: "In Progress",
    },
    3: {
      color: "bg-green-300 text-green-800",
      value: "Resolved",
    },
    4: {
      color: "bg-red-300 text-red-800",
      value: "Closed",
    },
  };

  return (
    <span
      className={`whitespace-nowrap rounded-full px-3 py-1 text-xs font-semibold capitalize ${type[value].color}`}
    >
      {type[value].value}
    </span>
  );
};

export default SupportIndex;
