import { Link, useLocation } from 'react-router-dom'

const NavLink = ({ children, icon = '', to, isComingSoon, ...props }) => {
  if (isComingSoon) {
    to = '#'
  }

  const location = useLocation()

  return (
    <Link
      className={`w-full h-[40px] relative capitalize flex items-center justify-start pl-[14.96px] font-[500] text-[14px] leading-[21px] hover:text-[#590da4] hover:rounded-[8px] hover:bg-[#e5dced] transition duration-300 ease-in-out cursor-pointer ${
        location.pathname === props.to
          ? 'rounded-[8px] bg-[#e5dced] text-[#590da4] font-[700]'
          : ''
      }`}
      to={to}
      {...props}
    >
      <div className='flex items-center'>
        <img src={icon} className='w-6 h-6' alt='' />
        <p className='ml-[16px] whitespace-normal w-full'>{children}</p>
      </div>
      {isComingSoon && (
        <span className='absolute top-0 right-0 text-xs bg-yellow-100/70 text-yellow-700 rounded-full px-1.5 py-0.5'>
          Coming Soon
        </span>
      )}
    </Link>
  )
}

export default NavLink
