/* eslint-disable react-hooks/exhaustive-deps */
import Input from '../../Components/Forms/Input'
import Password from '../../Components/Forms/Password'
import Submit from '../../Components/Forms/Submit'
import { Link, useFetcher, useNavigate } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import AuthContext from '../../Routes/AuthContext'
import HeaderLogo from '../../Components/LandingPage/HeaderLogo'
import useTitle from '../../Hooks/useTitle'

const Login = () => {
  useTitle('Admin Login')
  const { formAction, Form, state, data, formData } = useFetcher()
  const { storeUser } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (data?.success) {
      storeUser(data?.data)
      navigate({
        pathname: '/dashboard',
        search: 'email=' + formData.get('email')
      })
    }
  }, [data?.data])
  // const { formAction, Form, state, data} = useFetcher();

  return (
    <div className='mt-16 flex h-full w-full flex-col items-center justify-center md:mt-20'>
      <HeaderLogo />

      {/* <p className="mb-10 mt-10 font-semibold text-gray-800">
                Don't have an account?{" "}
                <Link to="/admin/register" className="font-bold text-purple-900">
                    Get Started
                </Link>{" "}
            </p> */}
      <h1 className='mb-10 mt-10 w-full text-[44px] font-[800]'>Admin Login</h1>
      <Form action={formAction} method='post' className='w-full'>
        <Input
          label='Email Address'
          name='email'
          placeholder='Enter your email'
          error={data?.errors?.email}
        />
        <Password
          label='password'
          name='password'
          placeholder='enter your password'
          error={data?.errors?.password}
        />
        <div className='mb-8 text-right' style={{ marginTop: '-15px' }}>
          <Link to='/admin/forgot-password'>
            {' '}
            <span
              style={{ color: '#590DA4', fontSize: '18px', fontWeight: 900 }}
            >
              {' '}
              Forgot Password?{' '}
            </span>
          </Link>
        </div>

        <p className='mb-2 text-sm text-red-700'>{data?.message}</p>

        <Submit type='submit' isLoading={state !== 'idle'}>
          Login
        </Submit>
        {/* <div className="w-full mt-8 flex items-center justify-between space-x-4 overflow-hidden"><hr className="w-full" /> <p className="uppercase inline-block text-center font-light text-gray-700">OR</p><hr className="w-full" /></div>

                <SocialLogin>
                    Login With Google
                </SocialLogin> */}
      </Form>
    </div>
  )
}

export default Login
