import { Dropdown } from "antd";
import { BsThreeDots } from "react-icons/bs";
import moment from "moment/moment";

import Panel from "../Panel";
import { Body, Column, ColumnHeader, Row, RowHeader, Table } from "./Table";
import { Link, useLoaderData } from "react-router-dom";
import usePagination from "../Table/usePagination";
import { formatNaira } from "../../FormatMoney";

const LoanPayment = () => {
  const data = useLoaderData();
  const loandata = data?.repaidRequests?.data?.data;
  const { Next, Prev, Button, Pagination, setPage, PageSize, Description } =
    usePagination();
    console.log("loan payment data", loandata)
  if (loandata?.length < 1) {
    return <div>No data Available</div>;
  }
  return (
    <Panel>
      <Table>
        <RowHeader>
          <ColumnHeader>Description</ColumnHeader>
          <ColumnHeader>Loan Type</ColumnHeader>
          <ColumnHeader>Repayment Date</ColumnHeader>
          <ColumnHeader>Amount</ColumnHeader>
          <ColumnHeader>Instalment Amount</ColumnHeader>
          <ColumnHeader>Remainder</ColumnHeader>
          <ColumnHeader>Repayment Status</ColumnHeader>
          {/* <ColumnHeader>Action</ColumnHeader> */}
        </RowHeader>
        <Body data={loandata}>
          {(data) =>
            data.map((item) => (
              <Row key={item.loan_token}>
                <Column>{item.description}</Column>
                <Column>{item.loan_type}</Column>
                <Column>
                  {moment(item.repayment_date).format("dddd, Do MMMM YYYY: h:mmA")}
                </Column>
                <Column>{formatNaira(item.repayment_amount)}</Column>
                <Column>{formatNaira(item.instalment_repayment)}</Column>
                <Column>
                  {formatNaira(
                    parseFloat(item.repayment_amount) -
                      parseFloat(item.instalment_repayment)
                  )}
                </Column>

                <Column>{item.repayment_status}</Column>
                {/* <Column>
                    <Dropdown
                      trigger={["click"]}
                      menu={{
                        items: [
                          {
                            key: "1",
                            label: (
                              <Link
                                to={`/dashboard/loan/payment/${item.id}`}
                                className="flex items-center justify-start space-x-1 text-sm font-medium focus:border-none focus:outline-none"
                              >
                                <span>View More</span>
                              </Link>
                            ),
                          },
                        ],
                      }}
                      placement="bottomRight"
                      arrow={{ pointAtCenter: true }}
                    >
                      <div className="flex cursor-pointer items-center gap-x-1">
                        <BsThreeDots />
                      </div>
                    </Dropdown>
                  </Column> */}
              </Row>
            ))
          }
        </Body>
      </Table>
    </Panel>
  );
};

export default LoanPayment;
