import ResponseObject from "../../DataObjects/ResponseObject";
import http from "../../Store/baseHttp";
import BaseController from "./BaseController";

class FAQService extends BaseController {
  async index({ request, params }) {
    try {
      let response = await http.get("/faqs");
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async post({ request, params }) {
    const formData = Object.fromEntries(await request.formData());
    try {
      let response = await http.post("/faqs", formData);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async put({ request, params }) {
    const formData = Object.fromEntries(await request.formData());
    const { id, ...form } = formData;
    try {
      let response = await http.put("/faqs/" + id, form);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async destroy({ request, params }) {
    const formData = Object.fromEntries(await request.formData());
    try {
      let response = await http.delete("/faqs/" + formData?.id);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }
}

export default FAQService;
