import PageHeader from "../../../Components/Dashboard/PageHeader";
import useTitle from "../../../Hooks/useTitle";
import Panel from "../../../Components/Dashboard/Panel";
import Document from "../../../Assets/dashboard/Document.svg"
import Upload from "../../../Assets/dashboard/Upload.svg"
import {FiEdit3} from "react-icons/fi";
import {BsPlus, BsX} from "react-icons/bs";
import MoneyFormat from "../../../Components/Utils/MoneyFormat";
import {Await, useFetcher, useLoaderData} from "react-router-dom";
import Input from "../../../Components/Forms/Input";
import TextArea from "../../../Components/Forms/TextArea";
import {Suspense, useContext, useEffect, useState} from "react";
import Select from "../../../Components/Forms/Select";
import Submit from "../../../Components/Forms/Submit";
import ToastContext from "../../../Routes/ToastContext";


const CooperativeIndex = () => {
    useTitle('Cooperative');
    const initialValues = {
        logo:'',
        title: '',
        minimum_saving: '',
        total_savings_amount: '',
        amount_to_contribute: '',
        duration: '',
        size_of_savings_group: '',
        size_of_savings_group_type: '',
        loan_qualification_criteria: '',
        open: false
    }
    const [cooperate, setCooperate] = useState(initialValues);
    const items = useLoaderData();

    return(
        <div>
            <div>
                <PageHeader>Cooperative</PageHeader>
                <p className="font-[400] text-[px] leading-[24px] text-[#64748B]">{(new Date()).toDateString()}</p>
            </div>
            <div className="w-full mt-[23.5px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[26px]">
                <Suspense fallback={<div>Loading...</div>}>
                    <Await resolve={items?.data}>
                        {({data}) => {
                            return data?.data?.map((item, index) => {
                                return (
                                    <CooperativePanel setItem={setCooperate} item={item} key={index}></CooperativePanel>
                                )
                            })
                        }}
                    </Await>
                </Suspense>
                <CooperativeButton onClick={()=>{
                    setCooperate((v) => ({...v, ...initialValues, open: true}))
                }}></CooperativeButton>
            </div>
            <CooperativeModal item={cooperate} setItem={setCooperate} />
        </div>
    )
}


const CooperativePanel = ({item, setItem}) => {
    return (
        <Panel>
            <div className="h-[145px] ml-[26px] flex items-center justify-start">
                <div className="w-[56px] h-[56px] aspect-square rounded-full bg-[#590DA4] p-[12px] mr-[24px] tracking-[-2%]">
                    <img src={item?.logo_path ?? Document} className="w-full h-full" alt="document" />
                </div>
                <div>
                    <h3 className="font-[700] text-[28px] leading-[32.28px] text-[#0D0F11] capitalize">{item?.title}</h3>
                    <p className="font-[500] text-gray-600 mt-1 mb-2">₦{MoneyFormat(item?.minimum_saving)} - {parseInt(item?.total_savings_amount)===0 ? 'Infinity' : '₦'+MoneyFormat(item?.total_savings_amount)}</p>
                    <button onClick={()=>{
                        setItem(()=>({...item, open: true}))
                    }} type="button" className="focus:outline-none focus:border-none font-[400] text-[16px] leading-[27px] text-[#64748B] flex items-center justify-start space-x-1">
                        <FiEdit3 className="w-6 aspect-square" />
                        <span>Edit package</span>
                        </button>
                </div>
            </div>
        </Panel>
    )
}

const CooperativeButton = ({...props}) => {
    return (
        <button {...props} className="focus:outline-none focus:border-none hover:drop-shadow-lg transition-all ">
            <Panel>
                <div className="h-[145px] flex flex-col items-center justify-center w-full space-y-3 text-[#590DA4]">
                <span className="inline-block  border border-[#590DA4] rounded-sm">
                    <BsPlus className="w-10 h-10" />
                </span>
                    <span className=" font-[400] text-[16px] leading-[27px]">Add new package</span>
                </div>
            </Panel>
        </button>
    )
}

const CooperativeModal = ({item=null, setItem}) => {
    const {Form, data, state} = useFetcher()
    const {flashToast} = useContext(ToastContext);
    const initialValues = {
        logo:'',
        title: '',
        minimum_saving: '',
        total_savings_amount: '',
        amount_to_contribute: '',
        duration: '',
        commission_fee:'',
        size_of_savings_group: '',
        contribution_frequency:'',
        open: false
    }

    const onChange = (e) => {
        setItem((item) => ({...item, [e.target.name]: e.target.value}))
    }

    useEffect(()=>{
        flashToast(data?.success, data?.message);
        if(data?.success){
            setItem((v)=>({...v, ...initialValues}));
        }
    },[data])

    return (
        <div className="fixed w-full z-50 bg-[#24054299] top-0 left-0 flex items-start justify-center pt-10 h-screen overflow-y-auto data-[hidden=false]:hidden" data-hidden={item.open}>
            <div className="max-w-3xl w-full m-10">
                <Panel>
                    <div className="relative p-6">
                        <button className="absolute right-0 top-0" onClick={()=>{setItem((v)=>({...v,...initialValues}))}}>
                            <BsX className="w-6 h-6" />
                        </button>
                        <h3 className="font-[700] text-[18px] leading-[28px] text-[#344054]">Add Cooperative Package</h3>
                        <hr className="w-full border-[#EAECF0] my-[22px]" />
                        <Form method={!item?.token ? 'post' : 'put'} className="flex flex-col items-start justify-start w-full space-y-5" encType="multipart/form-data">
                            <div className="w-full">
                                {item?.token && <Input type="hidden" name="id" normalize={true}  defaultValue={item?.token} />}
                                <File  name="logo" />
                                <Input label="Title" placeholder="Title" name="title" normalize={true} onChange={onChange} value={item?.title} error={data?.errors?.title} />
                                <Input label="Total Savings Amount" name={"total_savings_amount"} placeholder="Total Savings Amount" error={data?.errors?.total_savings_amount}  options={{numeral: true,
                                    numeralDecimalScale: 2}} onChange={onChange} value={item?.total_savings_amount} />
                                <Input label="Amount To Contribute" placeholder="Amount To Contribute" name={"amount_to_contribute"} error={data?.errors?.amount_to_contribute}  options={{numeral: true,
                                    numeralDecimalScale: 2}} onChange={onChange} value={item?.amount_to_contribute} />
                                <Input label="duration" placeholder="duration" name={"duration"} error={data?.errors?.duration} onChange={onChange} value={item?.duration} />
                                <Input label="Size of the savings group" placeholder="Size of the savings group" normalize={true} name={"size_of_savings_group"} type="number" onChange={onChange} value={item?.size_of_savings_group} error={data?.errors?.size_of_savings_group} />
                                <Input label="Commission Fee" placeholder="Contribution Frequency" normalize={true} name={"commission_fee"} type="number" onChange={onChange} value={item?.commission_fee} error={data?.errors?.commission_fee} />

                                <Input label="Contribution Frequency" placeholder="Size of the savings group" normalize={true} name={"contribution_frequency"} type="number" onChange={onChange} value={item?.contribution_frequency} error={data?.errors?.contribution_frequency} />
                                <TextArea label="More Information" name={"more_information"} placeholder="More Information."  rows={3} onChange={onChange} value={item?.loan_qualification_criteria} />
                                <Submit isLoading={state !== 'idle'}>Submit</Submit>
                            </div>
                        </Form>
                    </div>
                </Panel>
            </div>
        </div>
    )
}

const File = ({...props}) => {
    return (<label className='px-4 focus:outline-none mb-5 text-gray-800 tracking-wide border bg-transparent focus:border-purple-800 py-4 text-base placeholder:text-gray-400 font-semibold w-full rounded-lg flex items-center flex-col '>
        <input type="file" {...props} className="hidden" accept="image/*"/>
        <span>
            <img src={Upload} alt="upload icon" />
        </span>
        <p className="font-[500] text-[14px] leading-[20px] text-[#1D2939]">Click to upload <span className="font-[600] text-[#667085]">or drag and drop</span></p>
        <p className="text-[#667085] font-[400] text-[12px] leading-[18px]">
            SVG, PNG, JPG, or GIF (max. 800x400px)
        </p>
    </label>)
}

export default CooperativeIndex;