import { Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useState } from "react";
import moment from "moment/moment";

///
import { UserCard } from "../../../Components/Dashboard/Loan/UserCard";
import { LoanProfile } from "../../../Components/Dashboard/Loan/LoanProfile";
import Submit from "../../../Components/Forms/Submit";
import { formatNaira } from "../../../Components/FormatMoney";

const LoanDibursement = () => {
  const [loading, setLoading] = useState(false);
  const data = useLoaderData();
  const loanrequest = data?.loanrequest?.data;

  const chartdata = [
    { name: "Amount Collected", amount: 500, fill: "#816999" },
    { name: "Amount Paid", amount: 300, fill: "#590DA4" },
  ];
  const navigate = useNavigate();
  return (
    <section>
      <section className="flex flex-col justify-between gap-x-4 md:flex-row">
        <section className="flex flex-col gap-y-4">
          <UserCard userdata={loanrequest?.user_data} />
          <LoanProfile data={loanrequest} userdata={loanrequest?.user_data} />
        </section>

        <section className="rounded-xl bg-white px-24 py-4 md:w-[80%]">
          <div>
            <h3 className="text-sm font-bold leading-tight  lg:px-4">
              {loanrequest?.description}
            </h3>
          </div>
          <div className="my-4 flex flex-col justify-around md:my-8 md:flex-row">
            <PieChart width={330} height={350}>
              <Pie
                data={chartdata}
                dataKey="amount"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                label
              />
            </PieChart>
          </div>
          <div className="inline-flex flex-col items-start justify-start gap-2.5 rounded border border-gray-200 bg-white px-4 py-6 text-sm font-medium leading-none text-gray-500">
            <div className="inline-flex items-center justify-center gap-2.5">
              <div className="flex items-center justify-start gap-4">
                <div className="inline-flex flex-col items-start justify-start">
                  <div className="text-violet-900 text-lg font-bold leading-tight">
                    Loan Repayment
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">Approved Amount</div>
              <div className="w-36 ">
                {formatNaira(loanrequest?.approved_amount)}
              </div>
            </div>
            <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">Total Repayment Amount</div>
              <div className="w-36 ">
                {formatNaira(loanrequest?.repayment_amount)}
              </div>
            </div>
            <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">Amount Paid</div>
              <div className="w-36 ">-</div>
            </div>
            {/* <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">
                Amount Owed
              </div>
              <div className="w-36 ">
                15,000
              </div>
            </div> */}
            <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">Interest Rate</div>
              <div className="w-36 ">{loanrequest?.interest_given ?? ""}%</div>
            </div>
            <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">Repayment Date</div>
              <div className="w-36 ">
                {moment(loanrequest?.repayment_date).format(
                  "dddd, Do MMMM YYYY:h:mmA"
                )}
              </div>
            </div>
            {loanrequest?.loan_type === "Salary Advance" && (
              <div className="inline-flex items-start justify-start gap-2.5">
                <div className="w-56  md:w-80">Interest Rate</div>
                <div className="w-36 ">
                  {formatNaira(loanrequest?.monthly_repayment)}
                </div>
              </div>
            )}
            {/* <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">
                Payment method
              </div>
              <div className="w-36 ">
                Credit Card
              </div>
            </div> */}
            <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">Late payment fees</div>
              <div className="w-36 ">-</div>
            </div>
            {/* <div className="inline-flex items-start justify-start gap-2.5">
              <div className="w-56  md:w-80">
                Grace period
              </div>
              <div className="w-36 ">
                2 weeks
              </div>
            </div> */}
          </div>
          <div className="my-4 flex items-end justify-end gap-x-4 md:w-1/2">
            <button
              onClick={navigate(-1)}
              className="w-full rounded-md border border-gray-300 py-2 text-center text-sm disabled:opacity-50 md:px-2 md:py-3 lg:text-base"
            >
              <span>back</span>
            </button>
            <Submit type="submit" isLoading={loading}>
              Send Reminder
            </Submit>
          </div>
        </section>
      </section>
    </section>
  );
};

export default LoanDibursement;
