const TextArea = ({ label, error, ...props }) => {
  const handleResize = (event) => {
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };

  return (
    <div className="relative mb-5 flex max-h-fit w-full flex-col items-start justify-start">
      <label
        className="mb-1 w-full text-base capitalize text-gray-700"
        style={{ fontWeight: 700, fontSize: "18px" }}
      >
        {label}
      </label>
      <div className="mt-1 flex w-full items-center justify-start border-none">
        <textarea
          className="form-input w-full rounded-[16px] border bg-transparent px-4 py-4 text-base font-semibold tracking-wide text-gray-800 placeholder:capitalize placeholder:text-gray-400 focus:border-purple-800 focus:outline-none"
          {...props}
          onInput={handleResize}
        />
      </div>
      <div
        className={`text-sm font-semibold tracking-wide text-red-500 ${
          error ? "" : "text-transparent"
        }`}
      >
        {Array.isArray(error) && error.length > 1 && (
          <ul className="ml-5 list-disc">
            {error.map((e, k) => (
              <li key={k}>{e}</li>
            ))}
          </ul>
        )}
        {Array.isArray(error) && error.length === 1 && error}
      </div>
    </div>
  );
};

export default TextArea;
