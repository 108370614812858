import moment from "moment/moment";
import { formatNaira } from "../../FormatMoney";

export const LoanProfile = ({ data, userdata }) => {
  const { first_name, last_name, phone_number,loan_eligibility_amount } = userdata;
  return (
    <div className="relative inline-flex w-96 flex-col items-start justify-start rounded-3xl bg-white px-4 py-8">
      <div className="inline-flex items-center justify-start gap-7">
        <div className="text-xl font-normal leading-loose">
          User&apos;s Info
        </div>
        <RequestStatusBadge request_status={data.request_status} />
      </div>
      <div className="flex w-full flex-col gap-6">
        <div className="flex justify-between">
          <div className="text-sm font-normal leading-tight">Name:</div>
          <div className="text-slate-600 text-sm font-normal leading-tight">
            {first_name}{" "}
            {last_name}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm font-normal leading-tight">Phone:</div>
          <div className="text-slate-600 text-sm font-normal leading-tight">
            {phone_number}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm font-normal leading-tight">Timestamp:</div>
          <div className="text-slate-600 text-sm font-normal leading-tight">
            {moment(data?.created_at).format("dddd, Do MMMM YYYY: h:mmA")}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm font-normal leading-tight">Loantype:</div>
          <div className="text-slate-600 text-sm font-normal leading-tight">
            {data?.loan_type}
          </div>
        </div>
        {
          data?.loan_type === "Salary Advance"?(
            <div className="flex justify-between">
            <div className="text-sm font-normal leading-tight">Company Name:</div>
            <div className="text-slate-600 text-sm font-normal leading-tight">
              {data?.company_name}
            </div>
          </div>
          ):""
        }
        {/* <div className="flex justify-between">
            <div className="text-sm font-normal leading-tight">Wallet Id:</div>
            <div className="text-slate-600 text-sm font-normal leading-tight">
              0263
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-sm font-normal leading-tight">Wallet Id:</div>
            <div className="text-slate-600 text-sm font-normal leading-tight">
              2nd Feb 2023
            </div>
          </div> */}
        <div className="flex justify-between">
          <div className="text-sm font-normal leading-tight">Loan Eligibility Amount:</div>
          <div className="text-slate-600 text-sm font-normal leading-tight">
            {formatNaira(loan_eligibility_amount)}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm font-normal leading-tight">Recipient Account Name:</div>
          <div className="text-slate-600 text-sm font-normal leading-tight">
            {data?.recipient_account_name}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm font-normal leading-tight">Recipient Account Number:</div>
          <div className="text-slate-600 text-sm font-normal leading-tight">
            {data?.recipient_account_number}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm font-normal leading-tight">Bank Name:</div>
          <div className="text-slate-600 text-sm font-normal leading-tight">
            {data?.bank_name}
          </div>
        </div>
      </div>
      <div />
    </div>
  );
};

const RequestStatusBadge = ({ request_status }) => {
  let badgeColor = "";
  switch (request_status) {
    case "Approved":
      badgeColor = "text-green-600";
      break;
    case "pending":
      badgeColor = "text-yellow-600";
      break;
    case "Declined":
      badgeColor = "text-red-700";
      break;
      case "Paid":
        badgeColor = "text-green-700";
        break;
    default:
      badgeColor = "text-gray-500";
      break;
  }

  return (
    <div
      className={`flex items-start justify-start gap-2 rounded-lg bg-gray-200 px-2 py-0.5`}
    >
      <div className={`text-sm font-bold leading-tight ${badgeColor}`}>
        {request_status}
      </div>
    </div>
  );
};
