import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { ReviewLoanRequest } from "../../../Services/Dashboard/LoanServices";
import { useNavigate } from "react-router-dom";
import Submit from "../../Forms/Submit";
import { AiOutlineClose } from "react-icons/ai";

///I avioded adding the both reuest modal as one beacuse of abscnece of static typing and time
export const ApproveRequestModal = ({ loantoken, amount }) => {
    const navigate = useNavigate();

  const [isOpen, setIsopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [textValue, setTextValue] = useState({
    approval_amount: amount,
    reason: "",
  });

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setTextValue({
      ...textValue,
      [name]: value,
    });
  };
  function toggleIsOpen() {
    setIsopen((prev) => !prev);
  }
  const reviewLoanRequest = async (loanstatus, token) => {
    setLoading(true);
    const reviewResponse = await ReviewLoanRequest({
      status: "Approved",
      token: loantoken,
      reason: textValue.reason,
      set_approval_amount: textValue.approval_amount,
    });
    if (reviewResponse?.success) {
      setLoading(false);
      // console.log(reviewResponse);
      navigate(".");
    } else {
      setLoading(false);
      // console.log("failed");
    }
  };
  return (
    <div>
      <button
        onClick={toggleIsOpen}
        className="w-full inline-flex h-12 items-start justify-start gap-2 rounded-lg bg-fedacash-header px-9 py-4"
      >
        <span className="text-sm font-bold leading-tight text-white">
          Approve
        </span>
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={toggleIsOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex justify-evenly gap-3 text-lg font-bold capitalize leading-6 text-[#475467]"
                  >
                    Approve Loan Request
                    <button
                      type="button"
                      className=""
                      onClick={() => toggleIsOpen()}
                    >
                      <AiOutlineClose />
                    </button>
                  </Dialog.Title>
                  <div className="mt-4 flex flex-col gap-y-4 px-4">
                    <div className="flex flex-col gap-y-2">
                      <label htmlFor="reason" className="text-lg font-semibold">
                        Reason
                      </label>{" "}
                      <TextBox
                        value={textValue.reason}
                        onChange={handleTextChange}
                        placeholder="Reason"
                        name="reason"
                      />
                    </div>
                    <div className="flex flex-col gap-y-2">
                      <label htmlFor="reason" className="text-lg font-semibold">
                        Approval Amount
                      </label>
                      <TextBox
                        value={textValue.approval_amount}
                        onChange={handleTextChange}
                        placeholder="Amount"
                        name="approval_amount"
                        type="number"
                      />
                    </div>
                    <Submit
                      isLoading={loading}
                      onClick={() => reviewLoanRequest()}
                    >
                      Proceed
                    </Submit>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* <StatusDialog
          closeModal={closeStatusModal}
          isOpen={isStatusOpen}
          message={message}
        /> */}
    </div>
  );
};
export const DeclineRequestModal = ({ loantoken, amount }) => {
    const navigate = useNavigate();

  const [isOpen, setIsopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [textValue, setTextValue] = useState({
    approval_amount: amount,
    reason: "",
  });

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setTextValue({
      ...textValue,
      [name]: value,
    });
  };
  function toggleIsOpen() {
    setIsopen((prev) => !prev);
  }
  const reviewLoanRequest = async () => {
    setLoading(true);
    const reviewResponse = await ReviewLoanRequest({
      status: "Declined",
      token: loantoken,
      reason: textValue.reason,
      set_approval_amount: textValue.approval_amount,
    });
    if (reviewResponse?.success) {
      setLoading(false);
      // console.log(reviewResponse);
      navigate(".");
    } else {
      setLoading(false);
      // console.log("failed");
    }
  };
  return (
    <div>
      <button
        className="inline-flex h-12 items-center justify-center gap-2 rounded-lg border border-red-700 px-9 py-4 text-center"
        onClick={toggleIsOpen}
      >
        <span className="text-sm font-bold leading-tight">Decline</span>
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={toggleIsOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-fedacash-header bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex justify-evenly gap-3 text-lg font-bold capitalize leading-6 text-[#475467]"
                  >
                    Approve Loan Request
                    <button
                      type="button"
                      className=""
                      onClick={() => toggleIsOpen()}
                    >
                      <AiOutlineClose />
                    </button>
                  </Dialog.Title>
                  <div className="mt-4 flex flex-col gap-y-4 px-4">
                    <div className="flex flex-col gap-y-2">
                      <label htmlFor="reason" className="text-lg font-semibold">
                        Reason
                      </label>{" "}
                      <TextBox
                        value={textValue.reason}
                        onChange={handleTextChange}
                        placeholder="Reason"
                        name="reason"
                      />
                    </div>
                    <div className="flex flex-col gap-y-2">
                      <label htmlFor="reason" className="text-lg font-semibold">
                        Approval Amount
                      </label>
                      <TextBox
                        value={textValue.approval_amount}
                        placeholder="Amount"
                        name="approval_amount"
                        type="number"
                      />
                    </div>
                    <Submit
                      isLoading={loading}
                      onClick={() => reviewLoanRequest()}
                    >
                      Proceed
                    </Submit>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* <StatusDialog
            closeModal={closeStatusModal}
            isOpen={isStatusOpen}
            message={message}
          /> */}
    </div>
  );
};
const TextBox = ({ value, onChange, placeholder, ...props }) => {
  return (
    <input
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="h-12 rounded-md border border-gray-300 px-3 py-2"
      {...props}
    />
  );
};
