import ResponseObject from '../../DataObjects/ResponseObject'
import http from '../../Store/baseHttp'
import BaseController from './BaseController'

class ProfileService extends BaseController {
  async post ({ request }) {
    const formData = Object.fromEntries(await request.formData())
    try {
      let response = await http.post(
        '/user/profile/update',
        formData,
        {
          headers: {
            'Content-type': 'multipart/form-data'
          }
        }
      )
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }

  async put ({ request }) {
    const formData = Object.fromEntries(await request.formData())
    try {
      let response = await http.put(
        '/user/password/change',
        formData
      )
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }
}

export default ProfileService
