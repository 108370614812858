import { Suspense, useContext, useEffect, useState } from "react";
import { BsChevronDown, BsThreeDots, BsX } from "react-icons/bs";
import {
  Await,
  useFetcher,
  useLoaderData,
  Link,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import useTitle from "../../../Hooks/useTitle";
import InvoiceEmpty from "../../../Assets/images/InvoiceEmpty.png";
import useTable from "../../../Components/Dashboard/Table/useTable";
import PaginateAll from "../../../Components/Utils/PaginateAll";
import Search from "../../../Components/Dashboard/Search";
import Portal from "../../../Components/Dashboard/Portal";
import ToastContext from "../../../Routes/ToastContext";
import Submit from "../../../Components/Forms/Submit";
import Input from "../../../Components/Forms/Input";
import Switch from "../../../Components/Forms/Switch";
import { Dropdown, Popconfirm, Space, Typography } from "antd";
import UserObject from "../../../DataObjects/UserObject";
import moment from "moment";

const Users = () => {
  useTitle("Customers");
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
  const items = useLoaderData();
  const { submit, data } = useFetcher();
  const { flashToast } = useContext(ToastContext);
  // eslint-disable-next-line no-unused-vars
  const [viewId, setViewId] = useState(null);
  const [editId, setEditId] = useState(null);

  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    flashToast(data?.success, data?.message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Table>
        <RowHeader>
          <ColumnHeader>Customer Name</ColumnHeader>
          {/* <ColumnHeader>Category</ColumnHeader> */}
          <ColumnHeader>Email Address</ColumnHeader>
          {/* <ColumnHeader>KYC</ColumnHeader> */}
          <ColumnHeader>Phone Number</ColumnHeader>
          <ColumnHeader>BVN Status</ColumnHeader>
          <ColumnHeader>NIN Status</ColumnHeader>
          <ColumnHeader>Paystack Balance</ColumnHeader>
          <ColumnHeader>Created At</ColumnHeader>
          <ColumnHeader>Status</ColumnHeader>
          <ColumnHeader>Action</ColumnHeader>
        </RowHeader>
        <Body data={items}>
          {(data) =>
            data?.data?.data?.map((v, i) => (
              <Row key={i}>
                <Column>
                  <span className="">{v?.name}</span>
                </Column>
                {/* <Column>{v.category}</Column> */}
                <Column>{v?.creator?.email}</Column>
                {/* <Column>
                  <KYCStatus status={v.kyc_verification_status} />
                </Column> */}
                <Column>
                  <span className="capitalize">{v?.creator?.phone_number}</span>
                </Column>
                <Column>
                  <span className="capitalize">
                    {v?.creator?.bvn_verification_status === "VERIFIED" ? (
                      <span className="text-green-500">Verified.</span>
                    ) : (
                      <span className="text-red-500">Unverified.</span>
                    )}
                  </span>
                </Column>
                <Column>
                  <span className="capitalize">
                    {v?.creator?.nin_verification_status === "VERIFIED" ? (
                      <span className="text-green-500">Verified.</span>
                    ) : (
                      <span className="text-red-500">Unverified.</span>
                    )}
                  </span>
                </Column>
                <Column>
                  <span className="capitalize">{v?.creator?.paystack_wallet_total}</span>
                </Column>
                <Column>
                  {moment(v?.created_at).format("dddd, MMM Do YYYY h:mmA")}
                </Column>
                <Column>
                  <Status status={v.status} />
                </Column>

                <Column>
                  {!UserObject.getUser().isAdmin() && (
                    <Dropdown
                      trigger={["click"]}
                      menu={{
                        items: [
                          {
                            key: "1",
                            label: (
                              <Link
                                to={`/dashboard/customers/${v.token}`}
                                className="text-sm text-blue-500 focus:outline-none"
                              >
                                View More
                              </Link>
                            ),
                          },
                          {
                            key: "2",
                            label: (
                              <Popconfirm
                                title={`${
                                  parseInt(v?.status) === 1
                                    ? "Deactivate"
                                    : "Activate"
                                } ${v.creator?.first_name}`}
                                onConfirm={() => {
                                  submit(
                                    {
                                      token: v.token,
                                      status:
                                        parseInt(v?.status) === 0
                                          ? "deactivate"
                                          : "activate",
                                    },
                                    {
                                      method: "delete",
                                    }
                                  );
                                }}
                                okText={<span className="text-black">Yes</span>}
                                className="text-black"
                              >
                                {parseInt(v?.status) === 0
                                  ? "Deactivate user"
                                  : "Activate User"}
                              </Popconfirm>
                            ),
                          },
                        ],
                      }}
                      placement="bottomRight"
                      arrow={{ pointAtCenter: true }}
                    >
                      <div className="flex items-center justify-center">
                        <BsThreeDots className="h-4 w-4" />
                      </div>
                    </Dropdown>
                  )}
                </Column>
              </Row>
            ))
          }
        </Body>
      </Table>
      <MembersEmptyState items={items} />
      <ViewMemberButton view={viewId} />
      <EditMemberButton view={editId} />
      <PaginateAll items={items} />
    </>
  );
};

const MembersEmptyState = ({ items }) => {
  const [, setSearchParams] = useSearchParams({});
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const sortByFunStatus = (value) => {
    const s = Object.fromEntries(search);
    setSearchParams({ ...s, fund_status: value });
  };

  const menus = [
    {
      key: 1,
      label: (
        <button
          className="flex items-center space-x-2 py-2"
          onClick={() => {
            sortByFunStatus(0);
          }}
        >
          <span>Status</span>
          <Status status={0} />
        </button>
      ),
    },
    {
      key: 2,
      label: (
        <button
          className="flex items-center space-x-2 py-2"
          onClick={() => {
            sortByFunStatus(1);
          }}
        >
          <span>Status</span>
          <Status status={1} />
        </button>
      ),
    },
  ];
  return (
    <Suspense fallback={<div />}>
      <Await resolve={items?.data}>
        {(data) => {
          if (data === undefined || data?.data?.data.length <= 0) {
            return (
              <div className="flex min-h-[400px] flex-col items-center justify-center rounded-[16px] bg-white text-center md:min-h-[600px]">
                <img src={InvoiceEmpty} alt="Transactions" />
                <p className="mt-5 text-[#64748B]">
                  You do not have any <strong>customer</strong> under <br />{" "}
                  this category
                </p>
                {/* <CreateMemberButton /> ƒ*/}
              </div>
            );
          }

          return (
            <Portal selector="#portal">
              <div className="inline-flex w-full items-baseline justify-end space-x-2">
                <Search />
                <div className="max-w-[200px]">
                  <Dropdown
                    menu={{
                      items: menus,
                      selectable: true,
                      defaultSelectedKeys: ["3"],
                    }}
                    className="w-full"
                  >
                    <div className="rounded-[8px] border border-gray-300/50 bg-white px-4 py-2">
                      <Typography.Link>
                        <Space>
                          <div className="whitespace-nowrap text-xs text-gray-500">
                            Status
                          </div>
                          <BsChevronDown className="text-[#323A46]" />
                        </Space>
                      </Typography.Link>
                    </div>
                  </Dropdown>
                </div>
                {/* <DateRange /> */}
                {/* <CreateMemberButton /> */}
              </div>
            </Portal>
          );
        }}
      </Await>
    </Suspense>
  );
};

const ViewMemberButton = ({ view }) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    if (view) {
      setState(true);
    }
  }, [view]);

  return (
    <>
      <div
        data-active={state}
        className=" fixed left-0 top-0 z-[999] flex h-screen w-full items-start justify-center overflow-y-auto bg-purple-700/70 pt-10 data-[active=false]:hidden"
      >
        <div className="relative w-full max-w-sm rounded-[16px] bg-white py-8">
          <button
            onClick={() => setState((v) => !v)}
            className="absolute -left-3 -top-3 rounded-full bg-white"
          >
            <BsX className="h-6 w-6" />
          </button>
          <MemberViewModal setState={setState} view={view} />
        </div>
      </div>
    </>
  );
};

const MemberViewModal = ({ setState, view }) => {
  const { data } = useFetcher();
  const { flashToast } = useContext(ToastContext);

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message);
    if (data?.success) {
      setState((v) => !v);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <h1 className="px-6 text-left text-xl font-semibold">New Member</h1>
      <div className="mt-5">
        <div className="w-full text-left">
          <div className="flex w-full flex-col items-center justify-start space-y-4 px-6 py-4">
            <div className="h-[72px] w-[72px] rounded-full border border-blue-500 bg-gray-500" />
            <div className="w-full text-left">
              <h3 className="text-[18px] font-[700] leading-[27px] text-[#4B5768]">
                Name
              </h3>
              <p className="text-[18px] font-[700] leading-[27px] text-[#0D0F11]">
                {view?.first_name} {view?.last_name}
              </p>
            </div>
            <div className="w-full text-left">
              <h3 className="text-[18px] font-[700] leading-[27px] text-[#4B5768]">
                Email
              </h3>
              <p className="text-[18px] font-[700] leading-[27px] text-[#0D0F11]">
                {view?.email}
              </p>
            </div>
            <div className="w-full text-left">
              <h3 className="text-[18px] font-[700] leading-[27px] text-[#4B5768]">
                Phone{" "}
              </h3>
              <p className="text-[18px] font-[700] leading-[27px] text-[#0D0F11]">
                {view?.phone_number}
              </p>
            </div>
            <div className="w-full text-left">
              <Status status={view?.active_status} />
            </div>
            <div className="flex w-full items-center justify-end">
              <button
                onClick={() => {
                  setState(false);
                }}
                className="rounded border-2 border-gray-400/50 px-6 py-3 text-sm font-semibold text-black"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const EditMemberButton = ({ view }) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    if (view) {
      setState(true);
    }
  }, [view]);

  return (
    <>
      <div
        data-active={state}
        className=" fixed left-0 top-0 z-[999] flex h-screen w-full items-start justify-center overflow-y-auto bg-purple-700/70 pt-10 data-[active=false]:hidden"
      >
        <div className="relative w-full max-w-sm rounded-[16px] bg-white py-8">
          <button
            onClick={() => setState((v) => !v)}
            className="absolute -left-3 -top-3 rounded-full bg-white"
          >
            <BsX className="h-6 w-6" />
          </button>
          <MemberEditModal setState={setState} view={view} />
        </div>
      </div>
    </>
  );
};

const MemberEditModal = ({ setState, view }) => {
  const { Form, data, state } = useFetcher();
  const { flashToast } = useContext(ToastContext);

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message);
    if (data?.success) {
      setState((v) => !v);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <h1 className="px-6 text-left text-xl font-semibold">New Member</h1>
      <div className="mt-5">
        <div className="w-full text-left">
          <div className="px-6 py-4">
            <Form method="put">
              <input type="hidden" name="profile_id" value={view?.profile_id} />
              <Input
                normalize
                name="first_name"
                label="first name"
                defaultValue={view?.first_name}
                error={data?.errors?.first_name}
              />
              <Input
                normalize
                name="last_name"
                label="Last name"
                defaultValue={view?.last_name}
                error={data?.errors?.last_name}
              />
              <Input
                name="email"
                label="email"
                type="email"
                value={view?.email}
                error={data?.errors?.email}
              />
              <Input
                name="phone_number"
                label="phone number"
                value={view?.phone_number}
                error={data?.errors?.phone_number}
              />
              {view?.status && (
                <Switch
                  yes="activate"
                  no="deactivate"
                  isChecked={view?.active_status === "1"}
                  label="Activate"
                  name="status"
                />
              )}
              <div className="mt-2 flex w-full items-center justify-end space-x-2">
                <button
                  onClick={() => {
                    setState((v) => !v);
                  }}
                  type="reset"
                  className="min-h-[59px] w-full rounded-md border border-[#590DA4] px-4 py-3 text-base font-bold tracking-wider text-[#590DA4] hover:bg-purple-900 hover:text-white disabled:opacity-50"
                >
                  Cancel
                </button>
                <Submit type="submit" isLoading={state !== "idle"}>
                  Submit
                </Submit>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

const Status = ({ status }) => {
  status = parseInt(status);
  const colors = [
    { color: "text-green-700", key: "Active", background: "bg-green-200/50" },
    { color: "text-red-700", key: "Disabled", background: "bg-red-200/50" },
  ];

  return (
    <span
      className={`${colors[status]?.color} capitalize ${colors[status]?.background} rounded-full px-3 py-1 text-xs `}
    >
      {colors[status]?.key}
    </span>
  );
};

const KYCStatus = ({ status }) => {
  const colors = [
    {
      color: "text-gray-700",
      key: "Not Submitted",
      background: "bg-gray-200/50",
    },
    { color: "text-green-700", key: "Approved", background: "bg-green-200/50" },
    {
      color: "text-yellow-700",
      key: "Pending",
      background: "bg-yellow-200/50",
    },
    { color: "text-red-700", key: "Rejected", background: "bg-red-200/50" },
  ];

  return (
    <span
      className={`${colors[status]?.color} capitalize ${colors[status]?.background} whitespace-nowrap rounded-full px-3 py-1 text-xs`}
    >
      {colors[status]?.key}
    </span>
  );
};

export default Users;
