const loanEligibilityFactors = [
  {
    category: "Based on timely loan repayment",
    details: [
      {
        criteria: "Repayment on time for 3 consecutive loans",
        loanIncrease: "+10% of the previously approved amount",
        maxIncrease: "20,000 Naira"
      },
      {
        criteria: "Repayment on time for 6 consecutive loans",
        loanIncrease: "+15% of the previously approved amount",
        maxIncrease: "50,000 Naira"
      },
      {
        criteria: "Repayment on time for 12 consecutive loans",
        loanIncrease: "+25% of the previously approved amount",
        maxIncrease: "100,000 Naira"
      }
    ]
  },
  {
    category: "Based on frequent FedaCash service usage",
    details: [
      {
        criteria: "Maintain an active FedaCash account with regular transactions",
        pointsEarned: "Points earned per month based on transaction volume",
        redemptionRate: "Redemption rate: 10 points = +1% increase in loan amount (capped at 10%)"
      }
    ]
  },
  {
    category: "Based on good balance maintenance",
    details: [
      {
        criteria: "Maintain a minimum balance of 10% of your loan amount in your FedaCash account",
        loanIncrease: "+5% increase in loan eligibility amount"
      }
    ]
  },
  {
    category: "Additional factors",
    details: [
      {
        criteria: "Completing your profile information",
        loanIncrease: "One-time +5% increase in loan eligibility amount"
      },
      {
        criteria: "Avoiding loan defaults",
        impact: "Negative impact on credit score and loan eligibility (may lead to decreased loan amount or temporary suspension of loan access)"
      }
    ]
  }
];


export const LoanQualifications = () => {
  return (
    <div className="flex flex-col items-start justify-start gap-2.5 rounded border border-gray-200 bg-white px-3 py-5 max-h-[250px] overflow-y-auto">
      <div className="inline-flex items-center justify-center gap-2.5">
        <div className="text-sm font-bold leading-tight  text-fedacash-header">
          Loan Qualifications
        </div>
      </div>
      <div className="space-y-4 text-sm leading-none text-gray-500">
        <div></div>
        {loanEligibilityFactors.map((factor, index) => (
        <div key={index}>
          <h3 className="text-base font-semibold">{factor.category}</h3>
          <ul>
            {factor.details.map((detail, detailIndex) => (
              <li key={detailIndex} className="space-y-2 px-2">
                <h4>{detail.criteria}</h4>
                <p>{detail.loanIncrease}</p>
                {detail.maxIncrease && <p>Max Increase: {detail.maxIncrease}</p>}
                {detail.pointsEarned && <p>Points Earned: {detail.pointsEarned}</p>}
                {detail.redemptionRate && <p>{detail.redemptionRate}</p>}
                {detail.impact && <p>{detail.impact}</p>}
              </li>
            ))}
          </ul>
        </div>
      ))}
      </div>
    </div>
  );
};
