
  export const LoanInput = ({ name, value }) => {
    return (
      <div className="flex h-24 flex-col items-start justify-start gap-2">
        <div className="text-lg font-bold leading-relaxed text-gray-600">
          {name}
        </div>
        <div className="flex h-16 items-center justify-start gap-2 self-stretch rounded-lg border border-gray-200 bg-white px-4 py-2">
          <div className="text-lg font-bold leading-relaxed">
            {value}
          </div>
        </div>
      </div>
    );
  };
  