import { Outlet, useNavigate, useNavigation } from "react-router-dom";
import useToggler from "../Hooks/useToggler";
import NavBar from "../Components/Dashboard/NavBar";
import TopBar from "../Components/Dashboard/TopBar";
import ProtectedRoute from "../Routes/ProtectedRoute";
import { useEffect, useState } from "react";
import useIdle from "../Hooks/useIdle";
import UserObject from "../DataObjects/UserObject";
import Toast from "../Components/Dashboard/Notification/Toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [value, click] = useToggler(false);
  const isIdle = useIdle({ timeToIdle: 1.8e6, inactivityEvents: [] });
  const navigate = useNavigate();
  const navigation = useNavigation();

  useEffect(() => {
    if (isIdle) {
      UserObject.logOutUser();
      navigate("/admin/login");
    }
  }, [isIdle, navigate]);
  useEffect(() => {
    // Is this just a normal load?
    let isNormalLoad =
      navigation.state === "loading" && navigation.formData == null;

    // Are we reloading after an action?
    let isReloading =
      navigation.state === "loading" &&
      navigation.formData != null &&
      navigation.formAction === navigation.location.pathname;

    // Are we redirecting after an action?
    let isRedirecting =
      navigation.state === "loading" &&
      navigation.formData != null &&
      navigation.formAction !== navigation.location.pathname;
    if (isNormalLoad || isReloading || isRedirecting) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [navigation.state]);

  return (
    <ProtectedRoute>
      <Toast />
      <div className="relative flex h-screen w-full items-start justify-start bg-[#F7F8F9] font-montserrat">
        <Loader />
        <NavBar value={value} click={click} />
        <div className="flex h-screen w-full flex-1 flex-col items-start justify-start">
          <TopBar value={value} click={click} />
          <div className="py-10 md:px-20 xl:px-28 px-4 overflow-y-auto w-full h-full">
            {isLoading && (
              <div className="absolute inset-0 bg-black bg-opacity-10 flex items-center justify-center">
                <AiOutlineLoading3Quarters
                  className="text-fedacash-dark animate-spin"
                  size={30}
                />
              </div>
            )}
            <Outlet />
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
};

const Loader = () => {
  useEffect(() => {
    const handle = (e) => {
      // console.log("start", e);
    };

    window.addEventListener("start-request", handle);

    return () => {
      window.addEventListener("start-request", handle);
    };
  });

  return (
    <div className="fixed left-0 top-0 z-[9999999] hidden h-screen w-full items-center justify-center bg-purple-900/10">
      <span className="inline-flex aspect-square h-24 animate-perspective bg-indigo-500"></span>
    </div>
  );
};

export default Dashboard;
