import PageHeader from "../../../Components/Dashboard/PageHeader";
import Panel from "../../../Components/Dashboard/Panel";
import { Link, Outlet, useLocation, useSearchParams } from "react-router-dom";
import Search from "../../../Components/Dashboard/Search";
import { Dropdown, Space, Typography } from "antd";
import { BsChevronDown } from "react-icons/bs";
import { CreateMemberButton, Status } from "./CompanyIndex";

const Company = () => {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams({});
  const search = new URLSearchParams(location.search);
  const sortByFunStatus = (value) => {
    const s = Object.fromEntries(search);
    setSearchParams({ ...s, status: value });
  };
  const menus = [
    {
      key: 1,
      label: (
        <button
          className="flex items-center py-2 space-x-2"
          onClick={() => {
            sortByFunStatus(0);
          }}
        >
          <span>Status</span>
          <Status status={0} />
        </button>
      ),
    },
    {
      key: 2,
      label: (
        <button
          className="flex items-center py-2 space-x-2"
          onClick={() => {
            sortByFunStatus(1);
          }}
        >
          <span>Status</span>
          <Status status={1} />
        </button>
      ),
    },
  ];

  return (
    <div className="flex flex-col justify-start w-full">
      <PageHeader>Company</PageHeader>
      <div id="portal" className="flex items-center justify-end w-full">
        <div className="inline-flex items-center justify-end w-full space-x-4">
          <Search />
          <div className="flex max-w-[200px] items-center justify-end">
            <Dropdown
              menu={{
                items: menus,
                selectable: true,
                defaultSelectedKeys: ["3"],
              }}
              className="w-full"
            >
              <div className="rounded-[8px] border border-gray-300/50 bg-white px-4 py-2">
                <Typography.Link>
                  <Space>
                    <div className="text-xs text-gray-500 whitespace-nowrap">
                      Status
                    </div>
                    <BsChevronDown className="text-[#323A46]" />
                  </Space>
                </Typography.Link>
              </div>
            </Dropdown>
          </div>
          {/* <DateRange /> */}
          {location.pathname.toLowerCase() !==
          "/dashboard/company/suggested" ? (
            <CreateMemberButton />
          ) : null}
        </div>
      </div>
      <div className="mb-[16px] mt-[32px] flex w-full items-center justify-start space-x-[18.06px] overflow-x-auto rounded-[16px] bg-white px-[18.06px]">
        <Tab to="/dashboard/company?approval_status=1">Approved Anchor</Tab>
        <Tab to="/dashboard/company/suggested?approval_status=0&added_by=customer">
          SMEs Suggested Anchor
        </Tab>
      </div>
      <div className="flex max-w-full">
        <Panel>
          <Outlet />
        </Panel>
      </div>
    </div>
  );
};

const Tab = ({ to, children }) => {
  const location = useLocation();
  // const active = matchPath(to, location.pathname) ? true : false;
  const href = new URL(to, "http://api.docs.com");

  return (
    <Link
      to={to}
      className="border-box whitespace-nowrap border-b-[2px] border-[#590DA4] px-[48.5px] py-[20px] text-center text-[16px] font-[700] leading-[24px] focus:outline-none data-[active=false]:border-transparent data-[active=false]:text-[#64748B]"
      data-active={href.pathname === location.pathname}
    >
      {children}
    </Link>
  );
};

export default Company;
