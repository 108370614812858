import { useFetcher, useLoaderData } from "react-router-dom";
import Panel from "../../../Components/Dashboard/Panel";
import { BsPlus, BsX } from "react-icons/bs";
import { useContext, useEffect, useRef, useState } from "react";
import Submit from "../../../Components/Forms/Submit";
import SupportService from "../../../Services/Dashboard/SupportService";
import ToastContext from "../../../Routes/ToastContext";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import InvoiceInput from "../Invoice/InvoiceInput";
import useClickOutside from "../../../Hooks/useClickOutside";

const SupportView = () => {
  const { data } = useLoaderData();

  return (
    <div className="grid grid-cols-6 gap-10">
      <div className="col-span-full h-full overflow-y-auto md:col-span-4">
        {data?.message && (
          <Panel>
            <h1 className="mb-5 text-xl font-semibold">Message:</h1>
            <p>{data?.message}</p>
          </Panel>
        )}
        <div className="my-10"></div>
        {data?.replies?.map((v, k) => (
          <div className="mb-5" key={k}>
            <Panel>
              <h1 className="mb-5 text-xl font-semibold">Reply:</h1>
              <p>{v.message}</p>

              <div className="mt-5 grid grid-cols-3 gap-5">
                {JSON.parse(v?.attached_files)?.map((i, k) => (
                  <Link
                    to={i}
                    className="block max-h-40 w-full overflow-hidden"
                    target="_blank"
                  >
                    <img src={i} alt={i} className="w-full" />
                  </Link>
                ))}
              </div>
            </Panel>
            <p className="mt-2 text-right text-sm">
              {new Date(v.created_at).toLocaleDateString()}{" "}
              {new Date(v.created_at).toLocaleTimeString()}
            </p>
          </div>
        ))}
        {Number(data?.resolution_status) === 0 && <Assign data={data} />}
      </div>
      <div className="col-span-full flex w-full flex-col items-start justify-start space-y-10 md:col-span-2">
        <Panel>
          <div className="flex items-center justify-start">
            <div className="mr-5 aspect-square w-[48px] rounded-full bg-purple-500"></div>
            <div>
              <p className="text-[18px] font-[700] leading-[27px]">John Doe</p>
              <p className="text-[14px] font-[400] leading-[21px]">Calidium</p>
            </div>
          </div>
          <h1 className="font-{400] my-6 text-[20px] leading-[30px]">
            User’s Info
          </h1>
          <div className="grid grid-cols-1 gap-2">
            <div className="flex items-center justify-between text-[14px] font-[400] leading-[21px]">
              <p className="text-[#4B5768]">Name :</p>
              <p className="">J{data?.organization?.name}</p>
            </div>
            <div className="flex items-center justify-between text-[14px] font-[400] leading-[21px]">
              <p className="text-[#4B5768]">Email Address :</p>
              <p className="">{data?.organization?.email}</p>
            </div>
            <div className="flex items-center justify-between text-[14px] font-[400] leading-[21px]">
              <p className="text-[#4B5768]">Phone Number :</p>
              <p className="">{data?.organization?.phone_number}</p>
            </div>
            <div className="flex items-center justify-between text-[14px] font-[400] leading-[21px]">
              <p className="text-[#4B5768]">Category :</p>
              <p className="">{data?.category}</p>
            </div>
            <div className="flex items-center justify-between text-[14px] font-[400] leading-[21px]">
              <p className="text-[#4B5768]">Sub Category :</p>
              <p className="">{data?.sub_category}</p>
            </div>
          </div>
        </Panel>
        <Panel>
          <h1 className="font-{400] my-6 text-[20px] leading-[30px]">
            Message Assigned to:
          </h1>
          <Status value={data?.resolution_status} />

          {Number(data?.resolution_status) !== 0 && (
            <div className="mt-6 grid grid-cols-1 gap-2">
              <div className="flex items-center justify-between text-[14px] font-[400] leading-[21px]">
                <p className="text-[#4B5768]">Name :</p>
                <p className="">
                  {data?.agent?.first_name} {data?.agent?.last_name}
                </p>
              </div>
              <div className="flex items-center justify-between text-[14px] font-[400] leading-[21px]">
                <p className="text-[#4B5768]">Email Address :</p>
                <p className="">{data?.agent?.email}</p>
              </div>
              <div className="flex items-center justify-between text-[14px] font-[400] leading-[21px]">
                <p className="text-[#4B5768]">Role :</p>
                <p className="">{data?.agent?.role ?? "Unknown"}</p>
              </div>
            </div>
          )}
        </Panel>
      </div>
      {Number(data?.resolution_status) !== 0 && <Message data={data} />}
    </div>
  );
};

const Status = ({ value = 0 }) => {
  value = Number(value);
  const type = {
    0: {
      color: "bg-gray-300 text-gray-800",
      value: "Unassigned",
    },
    1: {
      color: "bg-purple-300 text-purple-800",
      value: "Assigned",
    },
    2: {
      color: "bg-yellow-300 text-yellow-800",
      value: "In Progress",
    },
    3: {
      color: "bg-green-300 text-green-800",
      value: "Resolved",
    },
    4: {
      color: "bg-red-300 text-red-800",
      value: "Closed",
    },
  };

  return (
    <span
      className={`whitespace-nowrap rounded-full px-3 py-1 text-xs font-semibold capitalize ${type[value].color}`}
    >
      {type[value].value}
    </span>
  );
};

const Assign = ({ data }) => {
  const [open, setOpen] = useState(false);
  const { Form, data: dt, state } = useFetcher();
  const { flashToast } = useContext(ToastContext);

  useEffect(() => {
    flashToast(dt?.success ?? false, dt?.message);
    if (data?.success) {
      setOpen((v) => false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dt]);

  return (
    <div className="col-span-full flex items-center justify-end">
      <div
        className={`fixed left-0 top-0 z-50 h-screen w-full items-center  justify-center bg-purple-700/50 ${
          open ? "flex" : "hidden"
        }`}
      >
        <div className="w-full max-w-lg">
          <div className="w-full max-w-full rounded-[16px] bg-white p-[15px]">
            <Form
              method="put"
              className="relative flex w-full flex-col items-start justify-start space-y-3 px-5 py-10"
            >
              <button
                className="absolute -left-8 -top-8 rounded-full bg-white"
                type="button"
                onClick={() => setOpen((v) => !v)}
              >
                <BsX className="h-6 w-6 text-[#590DA4]" />
              </button>
              <label className="block w-full">
                <span className="mb-5">Assign Message to: </span>
                <Users placeholder="Names" name="staff_profile_id" />
              </label>
              <input type="hidden" value={data?.token} name="support_token" />
              <Submit isLoading={state !== "idle"}>Submit</Submit>
            </Form>
          </div>
        </div>
      </div>

      <button className="mr-5 rounded-[8px] border border-[#590DA4] px-5 py-2 text-[#590DA4] hover:bg-[#590DA4] hover:text-white">
        Cancel
      </button>
      <button
        className="rounded-[8px] bg-[#590DA4] px-5 py-2 text-white hover:bg-purple-900"
        onClick={() => {
          setOpen((v) => true);
        }}
      >
        Assign
      </button>
    </div>
  );
};

const Message = ({ data }) => {
  const { Form, data: dt, state } = useFetcher();
  const { flashToast } = useContext(ToastContext);
  const formRef = useRef();

  const handleResize = (event) => {
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };

  const [files, setFiles] = useState([]);

  const changeHandler = (e) => {
    const files = e.target.files;
    setFiles((v) => [...v, ...files]);
  };

  useEffect(() => {
    flashToast(dt?.success ?? false, dt?.message);
    if (dt?.success) {
      formRef.current.reset();
      setFiles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dt]);

  return (
    <div className="col-span-full">
      <Panel>
        <Form method="post" encType="multipart/form-data" ref={formRef}>
          <div className="flex w-full items-start justify-start space-x-3">
            <div className="aspect-square w-[48px] flex-none rounded-full bg-gray-500"></div>
            <textarea
              className="flex-auto resize-none border-b border-transparent border-b-gray-200 focus:border-transparent focus:border-b-blue-500 focus:outline-none focus:ring-0"
              placeholder="Enter your text here"
              rows="5"
              onInput={handleResize}
              name="message"
            ></textarea>
          </div>
          <div className="flex justify-between py-5">
            <div className="ml-16 flex w-full items-center justify-start space-x-2">
              {files.map((v, i) => {
                return (
                  <Tooltip title={v.name} key={i}>
                    <File />
                  </Tooltip>
                );
              })}
            </div>
            <div className="flex flex-none divide-x divide-gray-300">
              <div className="flex items-center justify-center px-2">
                <div className="aspect-square w-[22px] rounded-full border-2 border-gray-500/50"></div>
              </div>
              <div className="flex items-center justify-between space-x-3 px-2">
                <label className="rounded-full border border-[#590DA4] p-2 text-[#590DA4] ">
                  <input
                    type="file"
                    className="hidden"
                    name="attachments"
                    multiple
                    accept="image/*"
                    onChange={changeHandler}
                  />
                  <BsPlus className="h-[22px] w-[22px]" />
                </label>
                <input type="hidden" name="support_token" value={data?.token} />
                <div className="inline-block overflow-hidden rounded-full">
                  <Submit isLoading={state !== "idle"}>
                    <span className="px-2 text-sm">Send</span>
                  </Submit>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Panel>
    </div>
  );
};

const File = (props) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.888 1H3.111C1.94513 1 1 1.94513 1 3.111V17.888C1 19.0538 1.94513 19.999 3.111 19.999H17.888C19.0538 19.999 19.999 19.0538 19.999 17.888V3.111C19.999 1.94513 19.0538 1 17.888 1Z"
        stroke="#590DA4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.8059 8.3872C7.68031 8.3872 8.38915 7.67835 8.38915 6.80395C8.38915 5.92955 7.68031 5.2207 6.8059 5.2207C5.9315 5.2207 5.22266 5.92955 5.22266 6.80395C5.22266 7.67835 5.9315 8.3872 6.8059 8.3872Z"
        stroke="#590DA4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.9983 13.6662L14.7208 8.38867L3.11035 19.9992"
        stroke="#590DA4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const Users = (props) => {
  return (
    <div className="mb-3 mt-1 w-full">
      <CustomSelect className="-full mb-5 mt-2" {...props} />
    </div>
  );
};

const CustomSelect = ({ top = false, ...props }) => {
  const [search, setSearch] = useState("");
  const [field, setField] = useState(null);
  const [data, setData] = useState([]);
  const details = useRef();
  const check = useRef();
  const loadOptions = (inputValue) => {
    SupportService.users(inputValue).then((res) => {
      setData(res?.data?.data);
    });
  };

  useClickOutside(details, () => {
    details.current.removeAttribute("open");
  });

  useEffect(() => {
    loadOptions(search);
  }, [search]);

  const isTop = top ? "bottom-0 mb-16" : "mb-2 ";

  return (
    <div>
      <label
        className="mb-1 w-full text-base capitalize text-gray-700"
        style={{ fontWeight: 700, fontSize: "18px" }}
      >
        {props.label}
      </label>
      <details
        className="relative text-[12px] font-[700] leading-[18px]"
        ref={details}
      >
        <summary className="form-input w-full list-none border bg-transparent px-4 py-5 text-sm font-semibold tracking-wide text-gray-500 placeholder:capitalize placeholder:text-gray-200 focus:border-purple-800 focus:outline-none">
          {field ?? "Search for Users"}
        </summary>
        <ul
          className={`absolute  z-40  max-h-40 w-full overflow-y-auto bg-white shadow-lg ${isTop}`}
        >
          <li className="sticky top-0 px-6">
            <InvoiceInput
              normalize
              placeholder="Search for  Users"
              type="text"
              onChange={(e) => {
                setSearch(e.target.value);
                setField(null);
                check.current.checked = true;
              }}
              value={search}
            />
            <input
              type="radio"
              ref={check}
              {...props}
              className="hidden"
              value=""
            />
          </li>
          {data.map((v, i) => (
            <li key={i} className="w-full">
              <label
                onClick={() => {
                  setField(v.first_name + " " + v.last_name);
                  details.current.removeAttribute("open");
                }}
              >
                <input
                  type="radio"
                  {...props}
                  className="peer hidden"
                  value={v.profile_id}
                />
                <span className="inline-block w-full cursor-pointer px-6 py-2 text-black hover:bg-blue-500 hover:text-white peer-checked:bg-blue-700 peer-checked:text-white">
                  {v.first_name} {v.last_name}
                </span>
              </label>
            </li>
          ))}
          {data.length <= 0 && (
            <li className="inline-block cursor-pointer px-6 py-2 text-black">
              No Data Available yet
            </li>
          )}
        </ul>
      </details>
    </div>
  );
};

export default SupportView;
