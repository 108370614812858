import { useNavigate } from "react-router-dom";
import { LoanInput } from "../../../Components/Dashboard/Loan/LoanInput";
import { UserCard } from "../../../Components/Dashboard/Loan/UserCard";
import { LoanProfile } from "../../../Components/Dashboard/Loan/LoanProfile";
import { LoanQualifications } from "../../../Components/Dashboard/Loan/LoanQualifications";

const LoanPayments = () => {
  const navigate = useNavigate();
  return (
    <section>
      <section className="flex flex-col justify-between md:flex-row">
        <section className="flex flex-col gap-y-4">
          <UserCard/>
          <LoanProfile/>
        </section>

        <section className="px-24 md:w-[80%]">
          <div className="mt-4 space-y-5">
            <LoanInput name={`Amount`} value={`50000`} />
            <LoanInput name={`Total Repayment Amount`} value={`700000`} />
            <LoanInput name={`Interest Allocated`} value={`8%`} />
            <LoanInput name={`Reason for Loan`} value={`Clothes & Food`} />
            <LoanInput name={`Loan Expected by`} value={`05/10/2024`} />
            <LoanInput name={`Loan Duration`} value={`4 months`} />
          </div>
          <div className="my-4 flex justify-end gap-x-4">
            <button
              className="inline-flex h-12 items-start justify-start gap-2 rounded-lg border border-gray-300 px-9 py-4"
              onClick={()=> navigate(-1)}
              >
              <span className="text-sm font-bold leading-tight">Go back</span>
            </button>
            <button className="inline-flex h-12 items-start justify-start gap-2 rounded-lg bg-fedacash-header px-9 py-4">
              <span className="text-sm font-bold leading-tight text-white">
                Proceed to Payment
              </span>
            </button>
          </div>
        </section>
      </section>
    </section>
  );
};

export default LoanPayments;

