import { useEffect } from "react";

const useTitle = (name = "") => {
  useEffect(() => {
    const spliter = name !== "" ? " |" : "";
    document.title = name + spliter + " fedacash SMEs";
  }, [name]);
};

export default useTitle;
