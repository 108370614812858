import './App.css'
import Index from './Routes/Index'
import AuthContext from './Routes/AuthContext'
import { useState } from 'react'
import ToastContext from './Routes/ToastContext'

function App () {
  const [user, setUser] = useState(null)

  const storeUser = (user) => {
    setUser(user)
    localStorage.setItem('user', JSON.stringify(user))
  }

  const getUser = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    // setUser(user);
    return user
  }
  const store = { user, storeUser, getUser }

  const initialToast = { isSuccess: false, message: null }
  const [toast, setToast] = useState(initialToast)
  const flashToast = (isSuccess, message) => {
    setToast({ isSuccess, message })

    setTimeout(() => {
      setToast(initialToast)
    }, 5000)
  }

  const clearToast = () => {
    setToast(initialToast)
  }

  const toastStore = { flashToast, clearToast, toast }
  return (
    <AuthContext.Provider value={store}>
      <ToastContext.Provider value={toastStore}>
        <Index />
      </ToastContext.Provider>
    </AuthContext.Provider>
  )
}

export default App
