import http from "../../Store/baseHttp";
import ResponseObject from "../../DataObjects/ResponseObject";
import BaseController from "./BaseController";

class AuditLogService extends BaseController {
  async index({ request, params }) {
    const url = new URL(request.url);
    try {
      let response = await http.get(
        `/admin/customers/audit/${params.id}${url.search}`
      );
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }
}

export default AuditLogService;
