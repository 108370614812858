import BaseController from "./BaseController";
import http from "../../Store/baseHttp";
import ResponseObject from "../../DataObjects/ResponseObject";
import { defer } from "react-router-dom";

class InvoiceService extends BaseController {
  async index({ request, params }) {
    const url = new URL(request.url);
    try {
      let response = await http.get(
        "admin/invoice/list" + url.search + "&sort_type=DESC"
      );
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  static async scopeIndex({ request, params }) {
    const url = new URL(request.url);
    try {
      let response = await http.get(
        "admin/invoice/list" +
          url.search +
          "&organization_token=" +
          params.id +
          "&sort_type=DESC"
      );
      response = ResponseObject.from(response);
      return defer({ data: response });
      // return;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  static async template({ request, params }) {
    try {
      let response = await http.get("/invoice/template");
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  static async email({ request, params }) {
    const formData = Object.fromEntries(await request.formData());
    formData.company_emails = formData.company_email
      .split(",")
      .map((v) => v.trim());
    try {
      let response = await http.put("/invoice/send-as-mail", formData);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  static async downlod(id) {
    try {
      let response = await http.get("/invoice/download/" + id);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async post({ request }) {
    const formData = Object.fromEntries(await request.formData());
    formData.invoice_number = formData.invoice_number.replace("₦", "");
    formData.company_emails = formData.company_email
      .split(",")
      .map((v) => v.trim());
    formData.items = JSON.parse(formData.items).map((v) => {
      if (v.unit_price) {
        v.unit_price = v.unit_price.replace("₦", "").replaceAll(",", "");
      }
      return v;
    });
    try {
      let response = await http.post("/invoice/create", formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }
}

export default InvoiceService;
