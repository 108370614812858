import BaseController from './BaseController'
import http from '../../Store/baseHttp'
import ResponseObject from '../../DataObjects/ResponseObject'

class NotificationController extends BaseController {
  async index ({ request, params }) {
    const url = new URL(request.url)
    try {
      let response = await http.get('/notification/unread' + url.search)
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }

  static async unread () {
    try {
      let response = await http.get('/notifications/unread')
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      // return []
      return ResponseObject.from(e?.response)
    }
  }

  static async markAllAsRead () {
    try {
      let response = await http.get('/notifications/mark-as-read')
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      // return []
      return ResponseObject.from(e?.response)
    }
  }

  async post ({ request }) {
    try {
      let response = await http.post('/request/create',
        Object.fromEntries(await request.formData()),
        {
          headers: {
            'Content-type': 'multipart/form-data'
          }
        })
      response = ResponseObject.from(response)
      return response
    } catch (e) {
      return ResponseObject.from(e?.response)
    }
  }
}

export default NotificationController
